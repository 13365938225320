import React from "react";
import { Grid } from "@mui/material";
import "./team.scss";
import Adriano from "../../../assets/images/contact/adriano.png";
import linda from "../../../assets/images/contact/linda2.png";
import clement from "../../../assets/images/contact/clement2.png";
import Iliria from "../../../assets/images/homepage/optimised/team4.png"


const Team = () => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={11} lg={8} sm={11} className="py-12 about-home-main !mb-16">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} lg={12} sm={12}>
            
            <Grid container spacing={2} className="pt-[40px]">
              <Grid lg={3} item xs={12} sm={6} md={3}>
                <img
                  src={Adriano}
                  alt=""
                  className="h-72 m-auto"
                />
                <div className="pt-4 text-center ">
                  <h1 className="font-[OpenSans] font-bold">Adriano RICCI</h1>
                  <p className="text-[#222222] text-[12px] lg:text-[14px] md:text-[14px] sm:text-[14px] font-[OpenSans] ">adriano.ricci@riccirealestate.be </p>
                </div>
              </Grid>
              <Grid lg={3} item xs={12} sm={6} md={3}>
                <img
                  src={linda}
                  alt=""
                  className="h-72 m-auto"
                />
                <div className="pt-4 text-center  ">
                  <h1 className="font-[OpenSans] font-bold">Linda KESLER</h1>
                  <p className="text-[#222222] font-[OpenSans] text-[12px] lg:text-[14px] md:text-[14px] sm:text-[14px]">linda.kesler@riccirealestate.be</p>
                </div>
              </Grid>
              <Grid lg={3} item xs={12} sm={6} md={3}>
                <img
                  src={clement}
                  alt=""
                  className="h-72 m-auto"
                />
                <div className="pt-4 text-center  ">
                  <h1 className="font-[OpenSans] font-bold">Clément VIEGAS</h1>
                  <p className="text-[#222222] font-[OpenSans] text-[12px] lg:text-[14px] md:text-[14px] sm:text-[14px]">clement.viegas@riccirealestate.be</p>
                </div>
              </Grid>
              <Grid lg={3} item xs={12} sm={6} md={3}>
                <img
                  src={Iliria}
                  alt=""
                  className="h-72 m-auto"
                />
                <div className="pt-4 text-center  ">
                  <h1 className="font-[OpenSans] font-bold">Ilaria RINALLO</h1>
                  <p className="text-[#222222] font-[OpenSans] text-[12px] lg:text-[14px] md:text-[14px] sm:text-[14px]">ilaria.rinallo@riccirealestate.be</p>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Team;
