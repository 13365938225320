import React, { useState, useEffect } from "react";
import { Container, Grid } from "@mui/material";
import InputComponent from "../input/input";
import { Link } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Input from "../input/input";
import { sendContactData } from "../../service/contact";
import { Formik } from "formik";
import * as Yup from "yup";

import "./detail_modal.scss";

const DetailPageModal = (props) => {
  const { show, setShow, refState } = props;
  const [message, setMessage] = useState(
    `Bonjour, \n je suis très intéressé par ce bien (Ref ${refState}). Est-il toujours disponible ? \n Pouvons-nous prévoir une visite ? \n Bien à vous, `
  );

  useEffect(() => {
    if (refState) {
      setMessage(
        `Bonjour, \n je suis très intéressé par ce bien (Ref ${refState}). Est-il toujours disponible ? \n Pouvons-nous prévoir une visite ? \n Bien à vous, `
      );
    }
  }, [refState]);

  const validationSchema = Yup.object().shape({
    nom: Yup.string().required("*Ce champ est obligatoire"),
    prenom: Yup.string().required("*Ce champ est obligatoire"),
    email: Yup.string()
      .email("*Must be a valid email address")
      .required("*Ce champ est obligatoire"),
  });

  let refNumber;
  refNumber = refState;
  const handleSubmit = (values, resetForm) => {
    const payload = {
      nom: values.nom,
      prenom: values.prenom,
      email: values.email,
      telephone: values.telephone,
      message: values.message,
    };
    console.log('payload',payload)
    sendContactData(payload)
      .then((response) => {
        console.log(response);
        resetForm();
        setShow(false);
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      {show && (
        <div
          id="large-modal"
          tabindex="-1"
          class="modal-main flex justify-center  bg-[#00000085] fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full"
        >
          <div class="relative  bg-white rounded-lg m-4 w-full max-w-3xl h-full md:h-auto">
            <div class="relative bg-white rounded-lg  p-[20px] pb-[30px]">
              <div class="flex justify-between items-center rounded-t ">
                <button
                  type="button"
                  class="text-gray-900 border-black bg-transparent rounded-full border text-sm p-1.5 ml-auto inline-flex items-center"
                  data-modal-toggle="large-modal"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <svg
                    class="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>

              <div
                class=""
                style={{
                  maxHeight: "calc(100vh - 110px)",
                  overflow: "auto",
                }}
              >
                <h1 className=" text-center font-[Poppins] text-[26px] font-semibold">
                  Vous souhaitez visiter le bien
                </h1>
                <Formik
                  initialValues={{
                    nom: "",
                    prenom: "",
                    email: "",
                    message: message,
                    telephone: "",
                  }}
                  validateOnChange={false}
                validateOnBlur={false}
                  onSubmit={(values, { setSubmitting, resetForm},validateForm) => {
                    // sendContactData(values)
                    //   .then((response) => {
                    //     resetForm();
                    //   })
                    //   .catch((err) => {
                    //     console.log("err", err);
                    //   });
                  }}
                  // Hooks up our validationSchema to Formik
                  validationSchema={validationSchema}
                >
                  {(props) => {
                    const {
                      values,
                      touched,
                      errors,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      resetForm,
                      validateForm
                    } = props;
                    return (
                      <form onSubmit={(e) => {
                        console.log('e',e)
                        e.preventDefault();
                        validateForm().then((errors) => {
                          console.log('errors',errors)
                        if(Object.keys(errors).length === 0){
                        handleSubmit(values, resetForm)
                        }
                        }
                        )}}>
                        <Grid container justifyContent="center ">
                          <Grid item lg={10} className="">
                            <h1 className="heading-linear text-center font-[Poppins] text-[26px] font-semibold">
                              Ref {refState}
                            </h1>
                            <p className="font-[OpenSans] text-[16px] text-[#666666] text-center">
                              Complétez le formulaire ci-dessous ou appelez-nous
                            </p>

                            <h1 className=" text-center font-[Poppins] text-[26px] font-semibold">
                              02 210 00 40
                            </h1>
                            <Grid container marginTop={0} spacing={2}>
                              <Grid item lg={6} xs={12} spacing={2}>
                              <Input
                              bg="#F1F4F6"
                              placeholder="Nom"
                              name="nom"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.nom}
                            />
                                <div className="font-[OpenSans] text-[11px] lg:text-[14px] md:text-[14px] sm:text-[14px] font-bold text-[#ff0000b0] pt-[5px]">
                                  {errors.nom && errors.nom}
                                </div>
                              </Grid>
                              <Grid item lg={6} xs={12} spacing={1}>
                              <Input
                              bg="#F1F4F6"
                              placeholder="Prénom"
                              name="prenom"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.prenom}
                            />
                                <div className="font-[OpenSans] text-[11px] lg:text-[14px] md:text-[14px] sm:text-[14px] font-bold text-[#ff0000b0] pt-[5px]">
                                  {errors.prenom && errors.prenom}
                                </div>
                              </Grid>
                              <Grid item lg={6} xs={12} spacing={1}>
                              <Input
                              bg="#F1F4F6"
                              type="email"
                              name="email"
                              placeholder="Email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                                <div className="font-[OpenSans] text-[11px] lg:text-[14px] md:text-[14px] sm:text-[14px] font-bold text-[#ff0000b0] pt-[5px]">
                                  {errors.email && errors.email}
                                </div>
                              </Grid>
                              <Grid item lg={6} xs={12} spacing={1}>
                              <Input
                              bg="#F1F4F6"
                              text="number"
                              name="telephone"
                              placeholder="Téléphone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.telephone}
                            />
                              </Grid>
                              <Grid item lg={12} xs={12} spacing={1}>
                              <textarea
                              className="outline-0 mt-1 block resize-none w-full rounded w-full py-3 px-3 bg-[#F1F4F6] font-bold text-[#222222] font-[OpenSans] text-[14px]"
                              id="exampleFormControlTextarea1"
                              rows="8"
                              name="message"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.message}
                            />
                                
                                <div className="font-[OpenSans] text-[11px] lg:text-[14px] md:text-[14px] sm:text-[14px] font-bold text-[#ff0000b0] pt-[5px]">
                                  {errors.message && errors.message}
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container marginTop={0} spacing={1}>
                              <Grid item lg={12} spacing={2}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      size="small"
                                      checked
                                      style={{
                                        color: "#003030",
                                        padding: "0px 9px",
                                      }}
                                    />
                                  }
                                  label="J’accepte de recevoir des biens selon les critères prédéfinis ci-dessus"
                                  className="checkbox-modal"
                                />
                              </Grid>
                              <Grid item lg={12} spacing={1}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      size="small"
                                      style={{
                                        color: "#003030",
                                        padding: "0px 9px",
                                      }}
                                    />
                                  }
                                  label={
                                    <div>
                                      Je déclare avoir pris connaissance des{" "}
                                      <Link
                                        to="/condition-dutilisations"
                                        className="text-[#003030]"
                                      >
                                        Termes et Conditions d’utilisation
                                      </Link>
                                      de RICCI Real Estate
                                    </div>
                                  }
                                  className="checkbox-modal"
                                />
                              </Grid>
                              <Grid item lg={12} spacing={1}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      size="small"
                                      style={{
                                        color: "#003030",
                                        padding: "0px 9px",
                                      }}
                                    />
                                  }
                                  label={
                                    <div>
                                      J’accepte la{" "}
                                      <Link
                                        to="/privacy-page"
                                        className="text-[#003030]"
                                      >
                                        politique de confidentialité{" "}
                                      </Link>
                                      de RICCI Real Estate
                                    </div>
                                  }
                                  className="checkbox-modal"
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              justifyContent="center"
                              className="mt-[20px] text-center"
                            >
                              <Grid item lg={6} xs={10}>
                                <button
                                  class="w-full font-[OpenSans] text-[14px] font-bold bg-[#003030] button-color-next py-3 px-8  rounded-[5px]"
                                  type="submit"
                                >
                                  Envoyer
                                </button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DetailPageModal;
