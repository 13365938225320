import React from "react";
import ContactTopSection from "../../components/contact/topSection/TopSection.js";
import Team from "../../components/contact/team/team.js"
import "./contact.scss"

const Contact = () => {
  return (
    <div className="image-background-contact bg-contain lg:bg-[length:50%] lg:bg-[length:50%] lg:bg-[length:50%]">
     
      <ContactTopSection />
      <Team />
      
    </div>
  );
};

export default Contact;
