import React, { useState } from "react";
import { Grid } from "@mui/material";

import { Formik } from "formik";
import { sendContactData } from "../../service/contact";

import * as Yup from "yup";

// Include Env File

const Contact = (props) => {
  const [successMessage, setSuccessMessage] = useState(false);
  // Schema for yup
  const validationSchema = Yup.object().shape({
    nom: Yup.string().required("*Ce champ est obligatoire"),
    prenom: Yup.string().required("*Ce champ est obligatoire"),
    email: Yup.string()
      .email("*Must be a valid email address")
      .required("*Ce champ est obligatoire")
  });

  return (
    <Formik
      initialValues={{
        nom: "",
        prenom: "",
        email: "",
        message: "",
        telephone: "",
      }}
      validateOnChange={false} // this one
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        sendContactData(values)
          .then((response) => {
            setSuccessMessage(true);
            resetForm();
          })
          .catch((err) => {
            console.log("err", err);
          });
      }}
      // Hooks up our validationSchema to Formik
      validationSchema={validationSchema}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
        } = props;
        return (
          <form
            action=""
            className="form_div font-brandonMedium"
            onSubmit={handleSubmit}
          >
            {/* your_name_div */}
            <div className="w-full flex justify-between">
              <div className="mr-[20px]  ">
                <input
                  className="font-[OpenSans] rounded-[5px]  bg-white/[0.4] p-[12px] font-regular text-[14px] text-white w-full bg-transparent outline-none footer-input"
                  placeholder="Prénom *"
                  name="prenom"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.prenom}
                />
                <div className="font-[OpenSans] text-[11px] lg:text-[14px] md:text-[14px] sm:text-[14px] font-bold text-[#ff0000b0] pt-[5px]">
                  {errors.prenom && errors.prenom}
                </div>
              </div>
              <div className="">
                <input
                  className="font-[OpenSans] rounded-[5px]  bg-white/[0.4] p-[12px] font-regular text-[14px] text-white w-full bg-transparent outline-none footer-input"
                  placeholder="Nom *"
                  name="nom"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nom}
                />
                <div className="font-[OpenSans] text-[11px] lg:text-[14px] md:text-[14px] sm:text-[14px] font-bold text-[#ff0000b0] pt-[5px]">
                  {errors.nom && errors.nom}
                </div>
              </div>
            </div>

            <div className="w-full flex justify-between mt-[15px]">
              <div className="mr-[20px] ">
                <input
                  className="font-[OpenSans] rounded-[5px] bg-white/[0.4] p-[12px] font-regular text-[14px] text-white w-full bg-transparent outline-none footer-input"
                  placeholder="Email *"
                  name="email"
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <div className="font-[OpenSans] text-[11px] lg:text-[14px] md:text-[14px] sm:text-[14px] font-bold text-[#ff0000b0] pt-[5px]">
                  {errors.email && errors.email}
                </div>
              </div>
              <div className="">
                <input
                  className="font-[OpenSans] rounded-[5px] bg-white/[0.4] p-[12px] font-regular text-[14px] text-white w-full bg-transparent outline-none footer-input"
                  placeholder="Téléphone"
                  name="telephone"
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.telephone}
                />
              </div>
            </div>

            <div className="w-full flex justify-between mt-[15px]">
              <div className="w-full">
                <textarea
                  className="font-[OpenSans] rounded-[5px] p-[12px] bg-white/[0.4] font-regular text-[14px] text-white w-full bg-transparent outline-none  footer-input"
                  placeholder="Message"
                  name="message"
                  id="message"
                  rows={6}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                />
                <div className="font-[OpenSans] text-[11px] lg:text-[14px] md:text-[14px] sm:text-[14px] font-bold text-[#ff0000b0] pt-[5px]">
                  {errors.message && errors.message}
                </div>
              </div>
            </div>

            {/* button_div */}
            <div className="w-full  mt-[19px]">
              <button
                type="submit"
                className=" rounded-[5px] py-[12px] w-full bg-white cursor-pointer flex justify-center items-center"
              >
                <p
                  className="font-[OpenSans] text-[14px] font-bold text-[#003030]"
                  typeof="submit"
                >
                  Envoyer
                </p>
              </button>
              <Grid>
                {successMessage && (
                  <p className="text-[14px] font-[OpenSans] pt-[10px] text-white text-left">
                    Votre demande a bien été envoyée, nous
                    prendrons contact avec vous dans les plus brefs délais
                  </p>
                )}
              </Grid>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default Contact;
