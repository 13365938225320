import { Grid } from "@mui/material";
import "./manage_property.scss";
import { buttonData, typeData } from "./constant";
import React from "react";
import RadioButton from "../../radio_button/radio_button";
import Input from "../input/input";

const FirstForm = (props) => {
  const {
    selectedButton,
    setSelectedButton,
    selectedType,
    setSelectedType,
    handleClick,
    state,
    setState,
  } = props;
  const onSubmit = (e) => {
    e.preventDefault();
    handleClick();
  };

  const handleCheckBox = (name) => {
    setState((st) => ({
      ...st,
      [name]: "yes",
    }));
  };

  const handleCheckBoxNo = (name) => {
    setState((st) => ({
      ...st,
      [name]: "no",
    }));
  };

  const handleInputChange = (e) => {
    setState((st) => ({
      ...st,
      [e.target.name]: e.target.value,
    }));
  };

  const handleAddress = (e) => {
    setState((st) => ({
      ...st,
      [e.target.name]: e.target.value,
    }));
  };
  return (
    <div class=" w-full bg-white rounded-[15px] card-shadow  mt-[30px]">
      <div class="flex items-center bg-gray-50 rounded-[15px] ">
        <div class="flex-1 h-full bg-white rounded-[15px] ">
          <div class="flex flex-col md:flex-row">
            <div class="h-32 md:h-auto md:w-1/2">
              <img
                class="object-cover min-h-full h-[900px] w-full  rounded-tl-[15px] rounded-bl-[15px]"
                src={require("../../assets/images/general/optimised/manage1.jpg")}
                alt="img"
              />
            </div>
            <form
              class="px-[30px] py-[40px] md:w-1/2"
              onSubmit={(e) => {
                onSubmit(e);
              }}
              id="form_test"
            >
              <div class="w-full">
                <h1 className="font-[OpenSans] font-bold text-[16px] text-[#222222]">
                  Type de bien
                </h1>
                <Grid container spacing={2} marginTop={0}>
                  {typeData?.map((value) => (
                    <Grid
                      item
                      lg={4}
                      sm={4}
                      className="rounded"
                      key={value?.id}
                    >
                      <div
                        className={
                          selectedType === value.id
                            ? "bg-[#F1F4F6] p-[10px] cursor-pointer border border-[#003030] font-bold rounded"
                            : "bg-[#F1F4F6] p-[10px] cursor-pointer rounded border border-[#F1F4F6]"
                        }
                        onClick={() => {
                          setSelectedType(value.id);
                          setState((st) => ({
                            ...st,
                            type: value?.text,
                          }));
                        }}
                      >
                        <img
                          className="h-[26px] m-auto"
                          src={value?.image}
                          alt=""
                        />
                        <p className="text-center pt-[8px] text-[14px] text-[#949AA4] font-[OpenSans]">
                          {value?.text}
                        </p>
                      </div>
                    </Grid>
                  ))}
                </Grid>
                <Grid className="mt-[30px]">
                  <h1 className="font-[OpenSans] font-bold text-[16px] text-[#222222]">
                    Chambres
                  </h1>
                </Grid>
                <Grid container spacing={2} marginTop={0}>
                  {buttonData?.map((single) => (
                    <Grid
                      item
                      className="rounded-[5px] w-[102px] "
                      key={single?.id}
                    >
                      <div
                        className={
                          selectedButton === single?.id
                            ? "bg-[#F1F4F6] p-[10px] cursor-pointer border border-[#003030] rounded font-bold"
                            : "bg-[#F1F4F6] p-[10px] cursor-pointer rounded border border-[#F1F4F6]"
                        }
                        onClick={() => {
                          setSelectedButton(single?.id);
                          setState((st) => ({
                            ...st,
                            chambres: single?.text,
                          }));
                        }}
                      >
                        <p className="text-center text-[14px] text-[#949AA4] font-[OpenSans]">
                          {single?.text}
                        </p>
                      </div>
                    </Grid>
                  ))}
                </Grid>
                <Grid className="mt-[30px]">
                  <h1 className="font-[OpenSans] font-bold text-[16px] text-[#222222]">
                    Superficie
                  </h1>
                  <Grid container spacing={2}>
                    <Grid item lg={6}>
                      <div className="mt-[10px] flex items-center rounded bg-[#F1F4F6] pr-[10px]">
                        <Input
                          placeholder="ex : 123"
                          name="superficie"
                          bg="#F1F4F6"
                          onChange={handleInputChange}
                        />
                        <span className=" text-[#949AA4] font-bold ">
                          m<sup>2</sup>
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container marginTop={0} spacing={2} className="pt-[20px]">
                  <Grid item lg={6}>
                    <div className="">
                      <div class="">
                        <label className="font-[OpenSans] font-bold text-[16px] text-[#222222]">
                          Année de construction
                          <Input
                            placeholder="ex : 1983"
                            bg="#F1F4F6"
                            name="construction"
                            onChange={handleInputChange}
                          />
                        </label>
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={6}>
                    <div className=" ">
                      <div class="">
                        <label className="font-[OpenSans] font-bold text-[16px] text-[#222222]">
                          Année de rénovation
                          <Input
                            placeholder="ex : 1983"
                            bg="#F1F4F6"
                            name="renovation"
                            onChange={handleInputChange}
                          />
                        </label>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container marginTop={0} spacing={2} className="pt-[20px]">
                  <Grid item lg={6} spacing={2}>
                    <RadioButton
                      label="Garage"
                      name="garage"
                      value={state.garage}
                      labelOne="Oui"
                      labelTwo="Non"
                      handleCheckBox={handleCheckBox}
                      handleCheckBoxNo={handleCheckBoxNo}
                    />
                  </Grid>

                  <Grid item lg={6} spacing={1}>
                    <RadioButton
                      label="Parking"
                      name="parking"
                      value={state.parking}
                      labelOne="Oui"
                      labelTwo="Non"
                      handleCheckBox={handleCheckBox}
                      handleCheckBoxNo={handleCheckBoxNo}
                    />
                  </Grid>
                  <Grid item lg={6} spacing={1}>
                    <RadioButton
                      label="Jardin"
                      name="jardin"
                      value={state.jardin}
                      labelOne="Oui"
                      labelTwo="Non"
                      handleCheckBox={handleCheckBox}
                      handleCheckBoxNo={handleCheckBoxNo}
                    />
                  </Grid>
                  <Grid item lg={6} spacing={1}>
                    <RadioButton
                      label="Terasse"
                      name="terasse"
                      value={state.terasse}
                      labelOne="Oui"
                      labelTwo="Non"
                      handleCheckBox={handleCheckBox}
                      handleCheckBoxNo={handleCheckBoxNo}
                    />
                  </Grid>
                </Grid>
                <Grid className="mt-[20px]">
                  <h1 className="font-[OpenSans] font-bold text-[16px] text-[#222222]">
                    Adresse
                  </h1>
                  <Grid container marginTop={0} spacing={2}>
                    <Grid item lg={8}>
                      <Input
                        bg="#F1F4F6"
                         placeholder="Rue"
                        name="rue"
                        onChange={handleAddress}
                      />
                    </Grid>
                    <Grid item lg={4}>
                      <Input
                        bg="#F1F4F6"
                        placeholder="Nr"
                        name="nr"
                        onChange={handleAddress}
                      />
                    </Grid>
                    <Grid item lg={5}>
                      <Input
                        bg="#F1F4F6"
                        placeholder="Code postal"
                        name="codepostal"
                         onChange={handleAddress}
                      />
                    </Grid>
                    <Grid item lg={7}>
                      <Input
                        bg="#F1F4F6"
                        placeholder="Ville"
                        name="ville"
                         onChange={handleAddress}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="mt-[40px] text-right">
                  <button
                    class="font-[OpenSans] text-[14px] font-bold bg-[#003030] button-color-next py-3 px-8  rounded-[5px]"
                    type="submit"
                  >
                    Suivant
                  </button>
                </Grid>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FirstForm;
