import * as React from "react";
import Step1 from "../../../assets/icons/1.png";
import dashIcon from "../../../assets/icons/V.svg";
import "./stepper.scss";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import HouseImage from "../../../assets/icons/houseIcon.png";
import DocsIcon from "../../../assets/icons/docsIcon.png";
import ChatIcon from "../../../assets/icons/chatIcon.png";
import houseImg from "../../../assets/icons/houseimg.png";
import chatImg from "../../../assets/icons/chatImg.png";
import groupImg from "../../../assets/icons/groupImg.png";

export default function HorizontalLabelPositionBelowStepper() {
  return (
    <>
      <div class="w-full py-6 hidden lg:block xl:block md:block sm:block">
        <div class="flex justify-center lg:pl-16">
          <div className="w-4/12">
            <div className="flex items-center w-full">
              <div className="border-[7px] border-[#f1f4f6] p-6 rounded-full text-lg text-white flex items-center">
                <img className="h-[45px]" src={HouseImage} alt="" />
              </div>
              <div className="bg-[#f1f4f6] h-[8px] flex-1"></div>
            </div>

            <div className="pt-[10px] pr-[10px]">
              <div class="text-left font-[OpenSans] font-bold sm:text-[14px] text-[16px] text-[#222222] px-[10px]">
                Avant l'occupation
              </div>
              <div>
                <div className="grid grid-cols-12">
                  <div className="col-span-1 my-auto">
                    <img src={dashIcon} className="w-3.5 ml-3" />
                  </div>
                  <div className="col-span-11">
                    <p className="text-left pt-[5px] px-[10px] text-[14px] text-[#666666] font-[OpenSans]">
                      {`Estimation & conseil immobilier`}
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-12">
                  <div className="col-span-1 my-auto">
                    <img src={dashIcon} className="w-3.5 ml-3" />
                  </div>
                  <div className="col-span-11">
                    <p className="text-left pt-[5px] px-[10px] text-[14px] text-[#666666] font-[OpenSans]">
                      {`Mise en location & analyse des candidatures`}
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-12">
                  <div className="col-span-1 my-auto ml-3">
                    <img src={dashIcon} className="w-3.5" />
                  </div>
                  <div className="col-span-11">
                    <p className="text-left pt-[5px] px-[10px] text-[14px] text-[#666666] font-[OpenSans]">
                      {`Gestion de l’entrée des locataires,`}
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-12">
                  <div className="col-span-1 my-auto ml-3">
                    <img src={dashIcon} className="w-3.5" />
                  </div>
                  <div className="col-span-11">
                    <p className="text-left pt-[5px] px-[10px] text-[14px] text-[#666666] font-[OpenSans]">
                      {`...`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-4/12">
            <div className="flex items-center w-full">
              <div className="border-[7px] border-[#f1f4f6] px-[20px] py-6 rounded-full text-lg text-white flex items-center">
                <img className="h-[45px]" src={ChatIcon} alt="" />
              </div>
              <div className="bg-[#f1f4f6] h-[8px] flex-1"></div>
            </div>
            <div className="pt-[10px] pr-[10px]">
              <div class="text-left font-[OpenSans] font-bold sm:text-[14px] text-[16px] text-[#222222] px-[10px]">
                En cours d'occupation
              </div>
              <div>
                <div className="grid grid-cols-12">
                  <div className="col-span-1 my-auto">
                    <img src={dashIcon} className="w-3.5 ml-3" />
                  </div>
                  <div className="col-span-11">
                    <p className="text-left pt-[5px] px-[10px] text-[14px] text-[#666666] font-[OpenSans]">
                      {`Relation avec le locataire`}
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-12">
                  <div className="col-span-1 my-auto">
                    <img src={dashIcon} className="w-3.5 ml-3" />
                  </div>
                  <div className="col-span-11">
                    <p className="text-left pt-[5px] px-[10px] text-[14px] text-[#666666] font-[OpenSans]">
                      {`Suivi du contrat de bail`}
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-12">
                  <div className="col-span-1 my-auto ml-3">
                    <img src={dashIcon} className="w-3.5" />
                  </div>
                  <div className="col-span-11">
                    <p className="text-left pt-[5px] px-[10px] text-[14px] text-[#666666] font-[OpenSans]">
                      {`Feedback complet et régulier au propriétaire`}
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-12">
                  <div className="col-span-1 my-auto ml-3">
                    <img src={dashIcon} className="w-3.5" />
                  </div>
                  <div className="col-span-11">
                    <p className="text-left pt-[5px] px-[10px] text-[14px] text-[#666666] font-[OpenSans]">
                      {`...`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-3/12">
            <div className="flex items-center w-full">
              <div className="border-[7px] border-[#f1f4f6] px-[28px] py-6 rounded-full text-lg text-white flex items-center">
                <img className="h-[45px]" src={DocsIcon} alt="" />
              </div>
            </div>
            <div className="pt-[10px] pr-[10px]">
              <div class="text-left font-[OpenSans] font-bold sm:text-[14px] text-[16px] text-[#222222] px-[10px]">
                Sortie locative
              </div>
              <div>
                <div className="grid grid-cols-12">
                  <div className="col-span-1 my-auto">
                    <img src={dashIcon} className="w-3.5 ml-3" />
                  </div>
                  <div className="col-span-11">
                    <p className="text-left pt-[5px] px-[10px] text-[14px] text-[#666666] font-[OpenSans]">
                      {`Gérer l'état des lieux de sortie`}
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-12">
                  <div className="col-span-1 my-auto">
                    <img src={dashIcon} className="w-3.5 ml-3" />
                  </div>
                  <div className="col-span-11">
                    <p className="text-left pt-[5px] px-[10px] text-[14px] text-[#666666] font-[OpenSans]">
                      {`Réévaluer la valeur locative`}
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-12">
                  <div className="col-span-1 my-auto ml-3">
                    <img src={dashIcon} className="w-3.5" />
                  </div>
                  <div className="col-span-11">
                    <p className="text-left pt-[5px] px-[10px] text-[14px] text-[#666666] font-[OpenSans]">
                      {`Remise en location du bien`}
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-12">
                  <div className="col-span-1 my-auto ml-3">
                    <img src={dashIcon} className="w-3.5" />
                  </div>
                  <div className="col-span-11">
                    <p className="text-left pt-[5px] px-[10px] text-[14px] text-[#666666] font-[OpenSans]">
                      {`...`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
      <div className="vertical-stepper-for-mobile-main block xl:hidden lg:hidden sm:hidden md:hidden ">
        <Stepper orientation="vertical">
          <Step>
            <StepLabel icon={<img className="h-[69px]" src={houseImg} alt="" />}>
              <div class=" font-[OpenSans] font-bold text-[14px] text-[#222222] px-[10px]">
                Avant l'occupation
              </div>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel
              icon={
                <img
                  className="h-[69px]"
                  src={chatImg}
                  alt=""
                />
              }
            >
              <div class=" font-[OpenSans] font-bold text-[14px] text-[#222222] px-[10px]">
                En cours d'occupation
              </div>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel
              icon={
                <img
                  className="h-[69px]"
                  src={groupImg}
                  alt=""
                />
              }
            >
              <div class=" font-[OpenSans] font-bold text-[14px] text-[#222222] px-[10px]">
                Sortie locative
              </div>
            </StepLabel>
          </Step>
          {/* <Step>
            <StepLabel
              icon={
                <img
                  className="h-[80px]"
                  src={require("../../../assets/icons/step4.png")}
                  alt=""
                />
              }
            >
              <div class=" font-[OpenSans] font-bold text-[14px] text-[#222222] px-[10px]">
                Lorem ipsum{" "}
              </div>
            </StepLabel>
          </Step> */}
        </Stepper>
      </div>
    </>
  );
}
