import React, { useState, useEffect } from "react";
import { Container, Grid } from "@mui/material";
import { useSearchParams,useLocation } from "react-router-dom";
import { WithContext as ReactTags } from "react-tag-input";
import { postalCodes } from "../../utils/constants";
import BreakpointController from "../breakpoint_controller/breakpoint_controller";
import PropTypes from "prop-types";

import { CircularProgress } from '@material-ui/core';

// image imports
import icon from "../../assets/icons/dropdown.svg";
import Dropdown from "../../assets/icons/dropdown.svg";

import "./filters.scss";

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const Filter = (props) => {
  const [dropdownState, setDropdownState] = useState(false);
  const [purposeDropdownState, setPurposeDropdownState] = useState(false);
  const [selectedTypeState, setSelectedTypeState] = useState(0);
  const [purposeState, setPurposeState] = useState(0);
  const [dropdownStateChambres, setDropdownStateChambres] = useState(false);
  const [roomsState, setRoomsState] = useState(0);
  const [areaMinState, setAreaMinState] = useState("");
  const [priceMaxState, setPriceMaxState] = useState("");
  const [cityState, setCityState] = useState("");
  const [referenceState, setReferenceState] = useState("");

  // react tags state variables
  const [ tags, setTags ] = useState([]);
  const location=useLocation()
 
  const [searchParams, setSearchParams] = useSearchParams();
  const { onFilterChange, onFilterLoad, originalEstateList, sortEstateList, setLoading,loading } = props;

  useEffect(() => {
    if(originalEstateList) {
      const category = searchParams.get("category");
      const type = searchParams.get("type");
      const postalCode = searchParams.get('zip');
      let categoryValid, typeValid, postalCodeValid;

      if (category) {
        
        setSelectedTypeState(parseInt(category));
        categoryValid = parseInt(category)
      }
      if(type) {
        setPurposeState(parseInt(type))
        typeValid = parseInt(type)
      }
      if(postalCode) {
        const zipExists = postalCodes.find(code => code?.text == postalCode)
        if(zipExists) {
          const tagsCopy = [...tags];
          let tagExists = false;
          tagExists = tagsCopy.find(tag => tag?.text == postalCode)
          if(!tagExists) {
            setTags([...tags, {id: postalCode, text: postalCode}])
          }
          postalCodeValid = postalCode;
        }
      }
      onFilterChange(categoryValid || null, typeValid | null, postalCode || null, cityState, roomsState, areaMinState, priceMaxState, referenceState)
      // onFilterLoad(typeValid || null, categoryValid || null, postalCodeValid || null);
    }
  }, [originalEstateList])

  const handleButtonSubmit = () => {
    setLoading(true);
    onFilterChange(
      selectedTypeState,
      purposeState,
      tags,
      cityState,
      roomsState,
      areaMinState,
      priceMaxState,
      referenceState,
    );
  };

  const getType = () => {
    switch (selectedTypeState) {
      case 0:
        return "Tout";
      case 1:
        return "Maison";
      case 2:
        return "Appartement";
      case 3:
        return "Terrain";
      case 4:
        return "Bureau";
      case 5:
        return "Commerce";
      case 6: 
        return "Immeuble de rapport";
      case 7:
        return "Garage";
      default:
        return "Tout";
    }
  };
  const handleDropdownClick = () => {
    setDropdownState(!dropdownState);
    setPurposeDropdownState(false);
    setDropdownStateChambres(false)
    
  };

  const handleDropdownItemClick = (id) => {
    setSelectedTypeState(id);
    setDropdownState(false);

  };


   const getTypeChambres = () => {
    switch (roomsState) {
      case 0: 
        return "Chambres";
      case 1:
        return "0";
      case 2:
        return "1";
      case 3:
        return "2";
      case 4:
        return "3";
      case 5:
        return "4";
      case 6:
        return "5+";
      default:
        return "Chambres";
    }
  };

  const handleDropdownClickChambres = () => {
    setDropdownStateChambres(!dropdownStateChambres);
    setDropdownState(false);
    setPurposeDropdownState(false);
  };

  const handleDropdownItemClickChambres = (id) => {
    setRoomsState(id);
    setDropdownStateChambres(false);
  };

  const handleTagAddition = tag => {
    setTags([...tags, tag])
  }

  const handleTagRemove = tagIndex => {
    setTags(tags.filter((_, index) => index !== tagIndex));
  }

  const handlePurposeDropdownClick = () => {
    setPurposeDropdownState(!purposeDropdownState);
    setDropdownStateChambres(false);
    setDropdownState(false);
  }

  const handlePurposeDropdownItemClick = id => {
    setPurposeState(id);
    setPurposeDropdownState(false)
  }

  const getPurpose = () => {
    switch(purposeState) {
      case 0: 
        return "Tout";
      case 1: 
        return "À vendre";
      case 2: 
        return "À louer";
      default: 
        return "Tout";
    }
  }

  return (
    <Grid className="bg-[#F1F4F6] py-[40px]">
      <Container>
        <Grid container justifyContent="center" className="">
          {location?.search==='?type=2' ? 
          <h1 className="text-center font-[Poppins] font-bold text-[40px] ">
            Découvrez nos biens 
            <span className="text-center font-[Poppins] font-light text-[40px] ">
              &nbsp;en location
            </span>      
          </h1>
          :
          <h1 className="text-center font-[Poppins] font-bold text-[40px] ">
          Découvrez nos biens
          <span className="text-center font-[Poppins] font-light text-[40px] ">
            &nbsp;en vente
          </span>
        </h1>
  }
          
        </Grid>
        <Grid
          container
          spacing={2}
          className="pt-[20px]"
          justifyContent="space-between"
        >
          <Grid container item lg={1.6} sm={6} md={2} xs={6} className = "z-[5]">         
            <div 
              className="bg-white w-full h-[45px] rounded-[5px] box-border px-[16px] py-[12px] flex justify-between items-center cursor-pointer relative"
              onClick={handleDropdownClick}
              tabIndex = "1"
              onBlur = {() => setDropdownState(false)}
            >
              <p className="font-[OpenSans] font-regular text-[14px] text-[#949AA4]/[0.9]">
                {getType()}
              </p>
              <img
                src={Dropdown}
                alt="dropdown arrow"
                className="h-[10px] w-[10px] object-contain animated"
                style={{
                  transform: dropdownState ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
              <div
                className="w-full rounded-b-[5px] bg-white flex flex-col absolute top-[100%] left-0 animated origin-top"
                style={{
                  zIndex:1,
                  boxShadow: '0 0 14px rgb(0 48 48 / 8%)',
                  opacity: dropdownState ? 1 : 0,
                  transform: dropdownState ? "scaleY(1)" : "scaleY(0)",
                }}
              >
                <div 
                  className="hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer"
                  onClick={() => handleDropdownItemClick(0)}
                >
                  <p className="font-[OpenSans] font-regular text-[14px] ">
                    Tout
                  </p>
                </div>
                <div 
                  className="hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer"
                  onClick={() => handleDropdownItemClick(1)}
                >
                  <p className="font-[OpenSans] font-regular text-[14px] ">
                    Maison
                  </p>
                </div>
                <div
                  className="hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer"
                  onClick={() => handleDropdownItemClick(2)}
                >
                  <p className="font-[OpenSans] font-regular text-[14px] ">
                    Appartement
                  </p>
                </div>
                <div
                  className="hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer"
                  onClick={() => handleDropdownItemClick(5)}
                >
                  <p className="font-[OpenSans] font-regular text-[14px] ">
                    Commerce
                  </p>
                </div>
                <div
                  className="hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer"
                  onClick={() => handleDropdownItemClick(6)}
                >
                  <p className="font-[OpenSans] font-regular text-[14px] ">
                    Immeuble de rapport
                  </p>
                </div>
                <div
                  className="hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer"
                  onClick={() => handleDropdownItemClick(4)}
                >
                  <p className="font-[OpenSans] font-regular text-[14px]">
                    Bureau
                  </p>
                </div>
                <div
                  className="hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer"
                  onClick={() => handleDropdownItemClick(3)}
                >
                  <p className="font-[OpenSans] font-regular text-[14px] ">
                    Terrain
                  </p>
                </div>
                <div
                  className="hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer"
                  onClick={() => handleDropdownItemClick(7)}
                >
                  <p className="font-[OpenSans] font-regular text-[14px] ">
                    Garage
                  </p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid container item lg={1.3} md={2} sm={6} xs={6}>
            <div
              className="bg-white w-full h-[45px] rounded-[5px] box-border px-[16px] py-[12px] flex justify-between items-center cursor-pointer relative"
              onClick={handlePurposeDropdownClick}
              tabIndex = "2"
              onBlur = {() => setPurposeDropdownState(false)}
            >
              <p className="font-[OpenSans] font-regular text-[14px] text-[#949AA4]/[0.9]">
                {getPurpose()}
              </p>
              <img
                src={Dropdown}
                alt="dropdown arrow"
                className="h-[10px] w-[10px] object-contain animated"
                style={{
                  transform: purposeDropdownState ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
              <div
                className="w-full rounded-b-[5px] bg-white flex flex-col absolute top-[100%] left-0 animated origin-top"
                style={{
                  zIndex:1,
                  boxShadow: '0 0 14px rgb(0 48 48 / 8%)',
                  opacity: purposeDropdownState ? 1 : 0,
                  transform: purposeDropdownState ? "scaleY(1)" : "scaleY(0)",
                }}
              >
                <div
                  className=" hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer"
                  onClick={() => handlePurposeDropdownItemClick(0)}
                >
                  <p className="font-[OpenSans] font-regular text-[14px] ">
                    Tout
                  </p>
                </div>
                <div
                  className=" hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer"
                  onClick={() => handlePurposeDropdownItemClick(1)}
                >
                  <p className="font-[OpenSans] font-regular text-[14px] ">
                    À vendre
                  </p>
                </div>
                <div
                  className=" hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer"
                  onClick={() => handlePurposeDropdownItemClick(2)}
                >
                  <p className="font-[OpenSans] font-regular text-[14px] ">
                    À louer
                  </p>
                </div>
              </div>
            </div>
          </Grid>
          <BreakpointController breakpoint = "sm" direction = "down">
            <Grid container item lg={1.3} md={2} sm={6} xs={6} className = "z-[3]">
              <ReactTags 
                tags = {tags}
                suggestions = {postalCodes}
                delimiters = {delimiters} 
                autofocus = {false}
                handleAddition = {handleTagAddition}
                handleDelete = {handleTagRemove}
                inputFieldPosition = "top"
                placeholder = "Code postal"
                allowDragDrop = {false}
                autocomplete = {false}
                classNames = {{
                  tagInput: 'postal-code-field-container',
                  tagInputField: 'postal-code-field',
                  tags: 'postal-code-wrapper',
                  tag: 'postal-code-tag',
                  remove: 'postal-code-remove',
                  suggestions: 'postal-code-suggestions',
                  activeSuggestion: 'postal-code-active-suggestion'
                }}
              />
            </Grid>
          </BreakpointController>
          {/* <Grid item lg={1.3} md={1.3} xs={4}>
            <div className="flex items-center rounded bg-[#ffffff] pr-[10px]">
              <input
                className="outline-0 block font-normal bg-white w-full rounded w-full py-3 px-3 text-[#222222] font-[OpenSans] text-[14px]"
                placeholder="City"
                value={cityState}
                onChange={(event) => setCityState(event.target.value)}
              />
            </div>
          </Grid> */}
          <Grid item lg={1.3} md={1.3} sm={6} xs={6} className = "z-[4]">
            <div
              className="bg-white w-full h-[45px] rounded-[5px] box-border px-[16px] py-[12px] flex justify-between items-center cursor-pointer relative"
              onClick={handleDropdownClickChambres}
              tabIndex = "3"
              onBlur = {() => setDropdownStateChambres(false)}
            >
              <p className="font-[OpenSans] font-regular text-[14px] text-[#949AA4]/[0.9]">
                {getTypeChambres()
                  }
              </p>
              <img
                src={Dropdown}
                alt="dropdown arrow"
                className="h-[10px] w-[10px] object-contain animated"
                style={{
                  transform: dropdownStateChambres ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
              <div
                className="w-full rounded-b-[5px] bg-white flex flex-col absolute top-[100%] left-0 animated origin-top"
                style={{
                  zIndex:1,
                  boxShadow: '0 0 14px rgb(0 48 48 / 8%)',
                  opacity: dropdownStateChambres ? 1 : 0,
                  transform: dropdownStateChambres ? "scaleY(1)" : "scaleY(0)",
                }}
              >
                <div
                  className="hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer"
                  onClick={() => handleDropdownItemClickChambres(0)}
                >
                  <p className=" font-[OpenSans] font-regular text-[14px] ">
                  Chambres
                  </p>
                </div>
                <div
                  className="hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer"
                  onClick={() => handleDropdownItemClickChambres(1)}
                >
                  <p className=" font-[OpenSans] font-regular text-[14px] ">
                    0
                  </p>
                </div>
                <div
                  className="hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer"
                  onClick={() => handleDropdownItemClickChambres(2)}
                >
                  <p className="font-[OpenSans] font-regular text-[14px] ">
                    1
                  </p>
                </div>
                <div
                  className="hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer"
                  onClick={() => handleDropdownItemClickChambres(3)}
                >
                  <p className="font-[OpenSans] font-regular text-[14px] ">
                    2
                  </p>
                </div>
                <div
                  className="hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer"
                  onClick={() => handleDropdownItemClickChambres(4)}
                >
                  <p className="font-[OpenSans] font-regular text-[14px] ">
                    3
                  </p>
                </div>
                <div
                  className="hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer"
                  onClick={() => handleDropdownItemClickChambres(5)}
                >
                  <p className="font-[OpenSans] font-regular text-[14px] ">
                    4
                  </p>
                </div>
                <div
                  className="hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer"
                  onClick={() => handleDropdownItemClickChambres(6)}
                >
                  <p className="font-[OpenSans] font-regular text-[14px] ">
                    5+
                  </p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item lg={1.6} md={2} sm={6} xs={6}>
            <div className=" flex items-center rounded bg-[#ffffff] pr-[10px]">
              <input
                className="outline-0 block font-normal bg-white w-full rounded w-full py-3 px-3 text-[#222222] font-[OpenSans] text-[14px]"
                placeholder="Superfie min"
                type="number"
                value={areaMinState}
                onChange={(event) => setAreaMinState(event.target.value)}
              />
              <span className=" text-[#949AA4] ">
                m<sup>2</sup>
              </span>
            </div>
          </Grid>
          <Grid item lg={1.3} md={2} sm={6} xs={6}>
            <div className=" flex items-center rounded bg-[#ffffff] pr-[10px]">
              <input
                className="outline-0 block font-normal bg-white w-full rounded w-full py-3 px-3 text-[#222222] font-[OpenSans] text-[14px]"
                placeholder="Prix max "
                type="number"
                value={priceMaxState}
                onChange={(event) => setPriceMaxState(event.target.value)}
              />
              <span className=" text-[#949AA4] ">€</span>
            </div>
          </Grid>
          <Grid item lg={1.3} md={2} sm={6} xs={6}>
            <input
              className="outline-0 block font-normal bg-white w-full rounded w-full py-3 px-3 text-[#222222] font-[OpenSans] text-[14px]"
              placeholder="Réf. du bien"
              type="number"
              value = {referenceState}
              onChange = {event => setReferenceState(event.target.value)}
            />
          </Grid>
          <BreakpointController breakpoint = "sm" direction = "up">
            <Grid container item lg={1.3} md={2} sm={6} xs={6} className = "z-[3]">
              <ReactTags 
                tags = {tags}
                autofocus = {false}
                suggestions = {postalCodes}
                delimiters = {delimiters}
                handleAddition = {handleTagAddition}
                handleDelete = {handleTagRemove}
                inputFieldPosition = "top"
                placeholder = "Code postal"
                allowDragDrop = {false}
                autocomplete = {false}
                classNames = {{
                  tagInputField: 'postal-code-field',
                  // selected: 'postal-code-selected',
                  tag: 'postal-code-tag',
                  remove: 'postal-code-remove',
                  suggestions: 'postal-code-suggestions',
                  activeSuggestion: 'postal-code-active-suggestion'
                }}
              />
            </Grid>
          </BreakpointController>
          <Grid item lg={1} md={2} sm={6}  xs={6}>
            <button
              class="w-full font-[OpenSans] text-[14px] font-bold bg-[#003030] button-color-next py-3 rounded-[5px] hoverEffect"
              onClick={handleButtonSubmit}
            >
            {loading && <CircularProgress className="ml-4 absolute" size={22} />}
              Chercher
            </button>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

Filter.propTypes = {
  onFilterChange: PropTypes.func,
};

Filter.defaultProps = {
  onFilterChange: () => {},
};

export default Filter;
