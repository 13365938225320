import React from "react";
import { Grid, Container } from "@mui/material";
import "./team.scss";

const Team = () => {
  return (
    <Container>
      <Grid className="flex justify-center">
        <Grid item xs={12} lg={12} sm={12} className="py-12 about-home-main">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} lg={12} sm={12}>
              <div>
                <h1 className="font-[Poppins] font-bold text-[30px] text-center">
                  <span className="text-[#222222]">Faites</span>
                  <span className=" heading-linear"> notre connaissance</span>
                </h1>
                <p className="pt-[30px] text-[#666666] font-[OpenSans] text-[16px] text-center italic">
                  "Les meilleures choses qui arrivent dans le monde de
                  l’entreprise ne sont pas le résultat du travail d’un seul
                  homme. C’est le travail de toute une équipe."
                </p>
                <p className="text-right text-[#666666] font-[OpenSans] text-[12px]">
                  Citation Steve Job
                </p>
              </div>
              <Grid container spacing={2} className="pt-[40px]">
                <Grid
                  lg={3}
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  className="image-wrapper"
                >
                  <div class="card relative cursor-pointer xl:block lg:block hidden">
                    <img
                      className="image h-[250px] m-auto relative animated"
                      src={require("../../../assets/images/homepage/team/optimised/Adriano2.png")}
                      alt="Card Back"
                    />
                    <div className="absolute top-0 left-0 image-two animated">
                      <img
                        src={require("../../../assets/images/homepage/team/optimised/Adriano.png")}
                        alt=""
                        className="h-[250px] m-auto relative animated"
                      />
                    </div>
                    <div className="pt-4 text-center">
                      <h1 className="font-[OpenSans] font-bold">
                        Adriano RICCI
                      </h1>
                      <p className="text-[#666666] text-[14px] font-[OpenSans] ">
                        Directeur
                      </p>
                    </div>
                  </div>
                  <div class="cursor-pointer lg:hidden xl:hidden block my-[10px]">
                    <img
                      className="image h-[250px] m-auto relative"
                      src={require("../../../assets/images/homepage/team/optimised/Adriano.png")}
                      alt="Card Back"
                    />
                    <div className="pt-4 text-center">
                      <h1 className="font-[OpenSans] font-bold">
                        Adriano RICCI
                      </h1>
                      <p className="text-[#666666] text-[14px] font-[OpenSans] ">
                        Directeur
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid
                  lg={3}
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  className="image-wrapper"
                >
                  <div class="card relative cursor-pointer xl:block lg:block hidden">
                    <img
                      className="image h-[250px] m-auto relative animated"
                      src={require("../../../assets/images/homepage/team/optimised/Linda2.png")}
                      alt="Card Back"
                    />
                    <div className="h-full w-full absolute left-0 top-0 image-two animated">
                      <img
                        src={require("../../../assets/images/homepage/team/optimised/Linda1.png")}
                        alt=""
                        className=" h-[250px] m-auto relative"
                      />
                    </div>
                    <div className="pt-4 text-center">
                      <h1 className="font-[OpenSans] font-bold">
                        Linda KESLER
                      </h1>
                      <p className="text-[#666666] text-[14px] font-[OpenSans] ">
                        Equipe commerciale & gestion locative
                      </p>
                    </div>
                  </div>
                  <div class="cursor-pointer lg:hidden xl:hidden block my-[10px]">
                    <img
                      className="image h-[250px] m-auto "
                      src={require("../../../assets/images/homepage/team/optimised/Linda1.png")}
                      alt="Card Back"
                    />
                    <div className="pt-4 text-center">
                      <h1 className="font-[OpenSans] font-bold">
                        Linda KESLER
                      </h1>
                      <p className="text-[#666666] text-[14px] font-[OpenSans] ">
                        Equipe commerciale & gestion locative
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid
                  lg={3}
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  className="image-wrapper"
                >
                  <div class="card cursor-pointer relative xl:block lg:block hidden">
                    <img
                      className="image h-[250px] m-auto relative animated"
                      src={require("../../../assets/images/homepage/team/optimised/clement.png")}
                      alt="Card Back"
                    />
                    <div className="h-full w-full absolute top-0 left-0 image-two animated">
                      <img
                        src={require("../../../assets/images/homepage/team/optimised/clement2.png")}
                        alt=""
                        className=" h-[250px] m-auto relative"
                      />
                    </div>
                    <div className="pt-4 text-center">
                      <h1 className="font-[OpenSans] font-bold">
                        Clément VIEGAS
                      </h1>
                      <p className="text-[#666666] text-[14px] font-[OpenSans] ">
                        Equipe commerciale
                      </p>
                    </div>
                  </div>
                  <div class="cursor-pointer lg:hidden xl:hidden block my-[10px]">
                    <img
                      className="image h-[250px] m-auto relative"
                      src={require("../../../assets/images/homepage/team/optimised/clement2.png")}
                      alt="Card Back"
                    />
                    <div className="pt-4 text-center">
                      <h1 className="font-[OpenSans] font-bold">
                        Clément VIEGAS
                      </h1>
                      <p className="text-[#666666] text-[14px] font-[OpenSans] ">
                        Equipe commerciale
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid
                  lg={3}
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  className="image-wrapper"
                >
                  <div class="card cursor-pointer relative xl:block lg:block hidden">
                    <img
                      className="image h-[250px] m-auto relative animated"
                      src={require("../../../assets/images/homepage/team/optimised/1.png")}
                      alt="Card Back"
                    />
                    <div className="h-full w-full absolute top-0 left-0 image-two animated">
                      <img
                        src={require("../../../assets/images/homepage/team/optimised/team4color.png")}
                        alt=""
                        className=" h-[250px] m-auto relative"
                      />
                    </div>
                    <div className="pt-4 text-center">
                      <h1 className="font-[OpenSans] font-bold">
                        Ilaria RINALLO
                      </h1>
                      <p className="text-[#666666] text-[14px] font-[OpenSans] ">
                        Equipe Administrative & Commerciale
                      </p>
                    </div>
                  </div>
                  <div class="cursor-pointer lg:hidden xl:hidden block my-[10px]">
                    <img
                      className="image h-[250px] m-auto relative"
                      src={require("../../../assets/images/homepage/team/optimised/team4color.png")}
                      alt="Card Back"
                    />
                    <div className="pt-4 text-center">
                      <h1 className="font-[OpenSans] font-bold">
                        Ilaria RINALLO
                      </h1>
                      <p className="text-[#666666] text-[14px] font-[OpenSans] ">
                        Equipe Administrative & Commerciale
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Team;
