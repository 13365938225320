import React from "react";
import { Container, Grid } from "@mui/material";
// import "./access.scss";

const PrioritySection = () => {
  return (
    <Container>
      <Grid container justifyContent="center" className="pt-[40px]">
        <Grid container xs={12} lg={10} sm={12}>
          <h1 className="font-[Poppins] font-semibold text-[18px] lg:text-[26px] xs:text-[26px] pb-[10px]">
            Propriété intellectuelle & industrielle
          </h1>
          <Grid className="text-[#666666] text-[14px] font-[OpenSans] space-y-4">
            <p>
              Le concept, le contenu, le lay-out, la structure, les codes
              sources, la programmation, les images, les photos, les
              informations, éléments d’information, les logos, les dessins, les
              marques, les modèles, les slogans, les logiciels, les animations,
              les œuvres audiovisuelles, les textes, les données, base de
              données, la musique et tous les autres éléments du Site Web et,
              d’une manière générale, le contenu ainsi que la structure du Site
              Web, appartiennent, sont et restent la propriété exclusive du
              prestataire, et sont protégés par divers droits de la propriété
              intellectuelle et/ou industrielle (parmi lesquels le droit
              d’auteur, le droit des marques, le droit sui generis du producteur
              de base de données, etc.), ce que l’utilisateur reconnaît et
              accepte.
            </p>
            <p>
              En surfant sur le Site Web ou en le consultant, en s’inscrivant,
              en devenant membre, en téléchargeant les fichiers, ou en utilisant
              le contenu du site web, de quelque manière que ce soit,
              l’utilisateur ne devient en aucun cas titulaire d’un quelconque
              des droits visés ci-avant ou des droits assimilés.
            </p>
            <p>
              Le prestataire garantit que les éléments présents sur le Site Web
              et mis à disposition de l’utilisateur du seul chef du prestataire
              respectent les droits des tiers, et de manière générale ne sont
              pas illicites.
            </p>
            <p>
              Le stockage d’une quelconque information et/ou élément du Site Web
              dans une base de données (électronique) n’est pas autorisé, à
              l’exception de la prise automatique d’information par le
              navigateur.
            </p>
            <p>
              En plaçant certaines données, textes, images et/ou tout autre
              élément en ligne, l’utilisateur donne automatiquement et
              gratuitement au prestataire l’autorisation exclusive de reproduire
              ces éléments, de les communiquer et/ou de les utiliser autrement,
              tant sur le Site Web que dans un ou plusieurs magazines ou
              produits édités par le prestataire.
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PrioritySection;
