import React, { useState } from "react";
import Main from "../../components/home_page/main/main";
import AboutHome from "../../components/home_page/about/about";
import Testmonial from "../../components/home_page/testmonial/testmonial";
import Service from "../../components/home_page/service/service";
import Banner from "../../components/home_page/banner/banner";
import Team from "../../components/home_page/team/team";
import SliderSection from "../../components/home_page/slider/slider";
import Carousel from "react-multi-carousel";
import { carusalData, responsive } from "../../utils/constants";
import "react-multi-carousel/lib/styles.css";
import { Grid } from "@mui/material";
import "./home_page.scss";

const HomePage = (props) => {
  const { show, setShow } = props;
  return (
    <div>
      <Main />
      <AboutHome />
      <Team />
      <Service />
      <SliderSection />
      <Testmonial setShow={setShow} />
      <Grid>
        <Carousel
          responsive={responsive}
          swipeable={true}
          draggable={true}
          arrows={false}
          infinite={true}
          autoPlay
          keyBoardControl={true}
          transitionDuration={5000}
          itemClass="carousel-item-"
        >
          {carusalData?.map((value) => (
            <Banner data={value} />
          ))}
        </Carousel>
      </Grid>
    </div>
  );
};

export default HomePage;
