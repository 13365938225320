import { Grid } from "@mui/material";
import "../estimate_page.scss";
import { buttonData, typeData } from "../constant";
import React from "react";
import RadioButton from "../../../radio_button/radio_button";
import Select from "../../select/select";
import Input from "../../input/input";

const FirstFormMobile = (props) => {
  const {
    selectedButton,
    setSelectedButton,
    selectedType,
    setSelectedType,
    state,
    setState,
  } = props;
  const handleCheckBox = (name) => {
    setState((st) => ({
      ...st,
      [name]: "yes",
    }));
  };

  const handleCheckBoxNo = (name) => {
    setState((st) => ({
      ...st,
      [name]: "no",
    }));
  };

  const handleInputChange = (event) => {
    setState((st) => ({
      ...st,
      [event.target.name]: event.target.value,
    }));
  };

  const handleAddress = (event) => {
    setState((st) => ({
      ...st,
      [event.target.name]: event.target.value,
    }));
  };
  return (
    <div class="w-full bg-white rounded-[15px] card-shadow mt-[10px]">
      <div class="flex items-center bg-gray-50 rounded-[15px] ">
        <div class="flex-1 h-full bg-white rounded-[15px] ">
          <div class="flex flex-col md:flex-row">
            <div class="h-32 md:h-auto md:w-1/2 hidden md:block lg:block xl:block">
              <img
                class="object-cover w-full h-full rounded-tl-[10px] rounded-bl-[10px]"
                src={require("../../../assets/images/general/optimised/form.jpg")}
                alt="img"
              />
            </div>
            <div class="px-[10px] sm:px-[20px] lg:px-[20px] md:px-[20px] xl:px-[20px] py-[20px] md:w-1/2">
              <div class="w-full">
                <h1 className="font-[OpenSans] font-bold text-[16px] text-[#222222]">
                  Type de bien
                </h1>
                <Grid container spacing={2} marginTop={0}>
                  {typeData?.map((value) => (
                    <Grid
                      item
                      xs={4}
                      lg={4}
                      className="rounded"
                      key={value?.id}
                    >
                      <div
                        className={
                          selectedType === value.id
                            ? "h-full bg-[#F1F4F6] py-[10px] px-[2px] cursor-pointer border border-[#003030] font-bold rounded"
                            : "h-full bg-[#F1F4F6] py-[10px] px-[2px]  cursor-pointer rounded border border-[#F1F4F6]"
                        }
                        onClick={() => {
                          setSelectedType(value.id);
                          setState((st) => ({
                            ...st,
                            type: value?.text,
                          }));
                        }}
                      >
                        <img
                          className="h-[26px] m-auto"
                          src={value?.image}
                          alt=""
                        />
                        <p className="text-center pt-[8px] text-[9px] sm:text-[10px] md:text-[10px] lg:text-[10px] xl:text-[10px] text-[#949AA4] font-[OpenSans]">
                          {value?.text}
                        </p>
                      </div>
                    </Grid>
                  ))}
                </Grid>
                <Grid className="mt-[20px]">
                  <h1 className="font-[OpenSans] font-bold text-[16px] text-[#222222]">
                    Chambres
                  </h1>
                </Grid>
                <Grid container spacing={2} marginTop={0}>
                  {buttonData?.map((single) => (
                    <Grid
                      item
                      className="rounded-[5px] w-[102px] "
                      key={single?.id}
                    >
                      <div
                        className={
                          selectedButton === single?.id
                            ? "bg-[#F1F4F6] p-[10px] cursor-pointer border border-[#003030] rounded font-bold"
                            : "bg-[#F1F4F6] p-[10px] cursor-pointer rounded border border-[#F1F4F6]"
                        }
                        onClick={() => {
                          setSelectedButton(single?.id);
                          setState((st) => ({
                            ...st,
                            chambres: single?.text,
                          }));
                        }}
                      >
                        <p className="text-center text-[14px] text-[#949AA4] font-[OpenSans]">
                          {single?.text}
                        </p>
                      </div>
                    </Grid>
                  ))}
                </Grid>
                <Grid className="mt-[20px]">
                  <h1 className="font-[OpenSans] font-bold text-[16px] text-[#222222]">
                    Superficie
                  </h1>
                  <Grid item xs={12} lg={6}>
                    <div className="mt-[10px] flex items-center rounded bg-[#F1F4F6] pr-[10px]">
                      <Input
                        placeholder="ex : 123"
                        name="superficie"
                        bg="#F1F4F6"
                        onChange={handleInputChange}
                      />
                      <span className=" text-[#949AA4] font-bold ">
                        m<sup>2</sup>
                      </span>
                    </div>
                  </Grid>
                </Grid>
                <Grid container marginTop={0} spacing={2} className="pt-[20px]">
                  <Grid item lg={6} xs={12}>
                    <div className="">
                      <div class="">
                        <label className="font-[OpenSans] font-bold text-[16px] text-[#222222]">
                          Année de construction
                          <Input
                            placeholder="ex : 1983"
                            bg="#F1F4F6"
                            name="construction"
                            onChange={handleInputChange}
                          />{" "}
                        </label>
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <div className=" ">
                      <div class="">
                        <label className="font-[OpenSans] font-bold text-[16px] text-[#222222]">
                          Année de rénovation
                          <Input
                            placeholder="ex : 1983"
                            bg="#F1F4F6"
                            name="renovation"
                            onChange={handleInputChange}
                          />{" "}
                        </label>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container marginTop={0} spacing={2} className="pt-[20px]">
                  <Grid item xs={6} lg={6} spacing={2}>
                    <RadioButton
                      label="Garage"
                      name="garage"
                      value={state.garage}
                      labelOne="Oui"
                      labelTwo="Non"
                      handleCheckBox={handleCheckBox}
                      handleCheckBoxNo={handleCheckBoxNo}
                    />
                  </Grid>
                  <Grid item xs={6} lg={6} spacing={1}>
                    <RadioButton
                      label="Parking"
                      name="parking"
                      value={state.parking}
                      labelOne="Oui"
                      labelTwo="Non"
                      handleCheckBox={handleCheckBox}
                      handleCheckBoxNo={handleCheckBoxNo}
                    />
                  </Grid>
                  <Grid item xs={6} lg={6} spacing={1}>
                    <RadioButton
                      label="Jardin"
                      name="jardin"
                      value={state.jardin}
                      labelOne="Oui"
                      labelTwo="Non"
                      handleCheckBox={handleCheckBox}
                      handleCheckBoxNo={handleCheckBoxNo}
                    />
                  </Grid>
                  <Grid item xs={6} lg={6} spacing={1}>
                    <RadioButton
                      label="Terasse"
                      name="terasse"
                      value={state.terasse}
                      labelOne="Oui"
                      labelTwo="Non"
                      handleCheckBox={handleCheckBox}
                      handleCheckBoxNo={handleCheckBoxNo}
                    />
                  </Grid>
                </Grid>
                <Grid className="mt-[20px]">
                  <h1 className="font-[OpenSans] font-bold text-[16px] text-[#222222]">
                    Adresse
                  </h1>
                  <Grid container marginTop={0} spacing={2}>
                    <Grid item lg={8} xs={8}>
                      <Input
                        bg="#F1F4F6"
                         placeholder="Rue"
                        name="rue"
                        onChange={handleAddress}
                      />{" "}
                    </Grid>
                    <Grid item lg={4} xs={4}>
                      <Input
                        bg="#F1F4F6"
                        placeholder="Nr"
                        name="nr"
                        onChange={handleAddress}
                      />{" "}
                    </Grid>
                    <Grid item lg={5} xs={5}>
                      <Input
                        bg="#F1F4F6"
                        placeholder="Code postal"
                        name="codepostal"
                         onChange={handleAddress}
                      />
                    </Grid>
                    <Grid item lg={7} xs={7}>
                      <Input
                        bg="#F1F4F6"
                        placeholder="Ville"
                        name="ville"
                         onChange={handleAddress}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FirstFormMobile;
