import * as React from "react";
import Step1 from "../../assets/icons/1.png";
import "./stepper.scss";
import { Stepper, Step, StepLabel } from "@material-ui/core";

export default function HorizontalLabelPositionBelowStepper() {
  return (
    <>
      <div class="w-full py-6 hidden lg:block xl:block md:block sm:block">
        <div class="flex">
          <div class="w-1/4">
            <div class="relative mb-2">
              <div class="w-[102px] h-[102px] mx-auto  rounded-full text-lg text-white flex items-center">
                <span class="text-center text-white w-full">
                  <img src={Step1} alt="" />
                </span>
              </div>
            </div>
            <div class="text-center font-[OpenSans] font-bold sm:text-[14px] text-[16px] text-[#222222] px-[10px]">
              Prise de rendez-vous
            </div>
          </div>
          <div class="w-1/4">
            <div class="relative mb-2">
              <div
                class="absolute flex align-center items-center align-middle content-center"
                style={{
                  width: "calc(100% - 84px - 1rem)",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <div class="w-full bg-[#F1F4F6] items-center align-middle align-center flex-1">
                  <div class="w-0 py-1 " style={{ width: "0%" }}></div>
                </div>
              </div>

              <div class="w-[102px] h-[102px] mx-auto rounded-full text-lg text-white flex items-center">
                <span class="text-center text-white w-full">
                  <img src={require("../../assets/icons/step2.png")} alt="" />
                </span>
              </div>
            </div>

            <div class="text-center font-[OpenSans] font-bold sm:text-[14px] text-[16px] text-[#222222] px-[10px]">
              Visite des lieux
            </div>
          </div>

          <div class="w-1/4">
            <div class="relative mb-2">
              <div
                class="absolute flex align-center items-center align-middle content-center"
                style={{
                  width: "calc(100% - 84px - 1rem)",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <div class="w-full bg-[#F1F4F6] items-center align-middle align-center flex-1">
                  <div class="w-0 bg-gray py-1 " style={{ width: "0%" }}></div>
                </div>
              </div>

              <div class="w-[102px] h-[102px] mx-auto bg-white rounded-full text-lg text-white flex items-center">
                <span class="text-center text-gray-600 w-full">
                  <img src={require("../../assets/icons/step3.png")} alt="" />
                </span>
              </div>
            </div>

            <div class="text-center font-[OpenSans] font-bold sm:text-[14px] text-[16px] text-[#222222] px-[10px]">
              Analyse approfondie de votre bien et du marché
            </div>
          </div>

          <div class="w-1/4">
            <div class="relative mb-2">
              <div
                class="absolute flex align-center items-center align-middle content-center"
                style={{
                  width: "calc(100% - 84px - 1rem)",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <div class="w-full bg-[#F1F4F6] items-center align-middle align-center flex-1">
                  <div class="w-0 -300 py-1 " style={{ width: "0%" }}></div>
                </div>
              </div>

              <div class="w-[102px] h-[102px] mx-auto bg-white rounded-full text-lg text-white flex items-center">
                <span class="text-center text-gray-600 w-full">
                  <img src={require("../../assets/icons/step4.png")} alt="" />
                </span>
              </div>
            </div>

            <div class="text-center font-[OpenSans] font-bold sm:text-[14px] text-[16px] text-[#222222] px-[10px]">
              Présentation de notre rapport d’estimation
            </div>
          </div>
        </div>
      </div>
      <div className="vertical-stepper-for-mobile-main block xl:hidden lg:hidden sm:hidden md:hidden ">
        <Stepper orientation="vertical">
          <Step>
            <StepLabel icon={<img className="h-[80px]" src={Step1} alt="" />}>
              <div class=" font-[OpenSans] font-bold text-[14px] text-[#222222] px-[10px]">
                Prise de rendez-vous
              </div>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel
              icon={
                <img
                  className="h-[80px]"
                  src={require("../../assets/icons/step2.png")}
                  alt=""
                />
              }
            >
              <div class=" font-[OpenSans] font-bold text-[14px] text-[#222222] px-[10px]">
                Visite des lieux
              </div>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel
              icon={
                <img
                  className="h-[80px]"
                  src={require("../../assets/icons/step3.png")}
                  alt=""
                />
              }
            >
              <div class=" font-[OpenSans] font-bold text-[14px] text-[#222222] px-[10px]">
                Analyse approfondie de votre bien et du marché
              </div>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel
              icon={
                <img
                  className="h-[80px]"
                  src={require("../../assets/icons/step4.png")}
                  alt=""
                />
              }
            >
              <div class=" font-[OpenSans] font-bold text-[14px] text-[#222222] px-[10px]">
                Présentation de notre rapport d’estimation
              </div>
            </StepLabel>
          </Step>
        </Stepper>
      </div>
    </>
  );
}
