import * as React from "react";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import "./multiple_tag.scss";
import { postalCodes } from "../../utils/constants";

export default function Tags() {
  return (
    <div className="multiple-tag-modal">
      <Autocomplete
        multiple
        id="tags-filled"
        options={postalCodes.map((option) => option.text)}
        // defaultValue={[top100Films[13].title]}
        freeSolo
        clearIcon={false}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              sx={{
                padding: 0,
                fontSize: "14px",
                fontFamily: "OpenSans",
                fontWeight: 700,
              }}
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            placeholder="Code postal"
            sx={{
              padding: 0,
              fontSize: "14px",
              fontFamily: "OpenSans",
              fontWeight: 700,
            }}
          />
        )}
      />
    </div>
  );
}

 