import React, { useState } from "react";
import { Grid } from "@mui/material";
 import InputComponent from "../input/input";
import { Link } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MultipleSelectChip from "../multiple_tag/multiple_tag";
import MultipleSelect from "../multiple_select/multiple_select";
import SingleSelect from "../select/select";

import "./modal.scss";

const RegisterModal = (props) => {
  const { show, setShow } = props;
  const [state, setState] = useState({
    value: "vendre",
  });
  return (
    <>
      {show && (
        <div
          id="large-modal"
          tabindex="-1"
          class="modal-main flex justify-center  bg-[#00000085] fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full"
        >
          <div class="relative bg-white rounded-lg m-4 w-full max-w-4xl h-full md:h-auto">
            <div class="relative bg-white rounded-lg  p-[20px] pb-[30px]">
              <div class="flex justify-between items-center rounded-t ">
                <button
                  type="button"
                  class="text-gray-900 border-black bg-transparent rounded-full border text-sm p-1.5 ml-auto inline-flex items-center"
                  data-modal-toggle="large-modal"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <svg
                    class="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>

              <div
                class=""
                style={{
                  maxHeight: "calc(100vh - 110px)",
                  overflow: "auto",
                }}
              >
                <h1 className=" text-center font-[Poppins] text-[26px] font-semibold">
                  S’inscrire dans la liste
                </h1>
                <Grid container justifyContent="center ">
                  <Grid item lg={10} className="pt-[10px]">
                    <p className="font-[OpenSans] text-[16px] text-[#666666] text-center">
                      Nous utilisons des cookies pour vous offrir la meilleure
                      expérience sur notre site. Vous pouvez les désactiver ou
                      en savoir plus dans les paramètres.
                    </p>
                    <h1 className="pt-[10px] font-[OpenSans] text-[16px] font-bold">
                      Votre sélection
                    </h1>
                    <Grid container marginTop={0}>
                      <Grid item lg={6} spacing={1}>
                        <div className="">
                          <div class="">
                            <label className="font-[OpenSans] font-bold text-[16px] text-[#222222]">
                              <div class="mt-[10px]">
                                <label class="inline-flex items-center">
                                  <input
                                    type="radio"
                                    defaultChecked={true}
                                    checked={state?.value === "vendre"}
                                    onChange={() => {
                                      setState((st) => ({
                                        ...st,
                                        value: "vendre",
                                      }));
                                    }}
                                    class="form-radio accent-[#003030] h-[18px] w-[18px]"
                                  />
                                  <span class="font-[OpenSans] font-bold text-[14px] text-[#949AA4] ml-2">
                                    A vendre
                                  </span>
                                </label>
                                <label class="inline-flex items-center ml-6">
                                  <input
                                    type="radio"
                                    checked={state?.value === "louer"}
                                    class="form-radio accent-[#003030] h-[18px] w-[18px]"
                                    onChange={() => {
                                      setState((st) => ({
                                        ...st,
                                        value: "louer",
                                      }));
                                    }}
                                  />
                                  <span class="font-[OpenSans] font-bold text-[14px] text-[#949AA4] ml-2">
                                    A louer
                                  </span>
                                </label>
                              </div>
                            </label>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container marginTop={0} spacing={2}>
                      <Grid item lg={6} xs={12} spacing={2}>
                        <MultipleSelect />
                      </Grid>
                      <Grid item lg={6} xs={12} spacing={1}>
                        <MultipleSelectChip />
                        {/* <InputComponent
                          placeholder="Code postal"
                          border="border border-[#DDDDDD]"
                        /> */}
                      </Grid>
                      <Grid item lg={6} xs={12} spacing={1}>
                        <SingleSelect />
                      </Grid>
                      <Grid item lg={6} xs={12} spacing={1}>
                        <div className="flex items-center rounded pr-[10px] border border-[#DDDDDD]">
                          <input
                            className={`outline-0 block   w-full  rounded w-full py-3 px-3  font-bold text-[#222222] font-[OpenSans] text-[14px]`}
                            placeholder="Price max"
                            type="number"
                          />
                          <span className=" text-[#949AA4] font-bold ">€</span>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid className="pt-[10px]">
                      <h1 className="font-[OpenSans] text-[16px] font-bold">
                        Informations personnelles
                      </h1>
                    </Grid>
                    <Grid container marginTop={0} spacing={2}>
                      <Grid item lg={6} xs={12} spacing={2}>
                        <InputComponent bg="#F1F4F6" placeholder="Nom" />
                      </Grid>
                      <Grid item lg={6} xs={12} spacing={1}>
                        <InputComponent bg="#F1F4F6" placeholder="Prénom" />
                      </Grid>
                      <Grid item lg={6} xs={12} spacing={1}>
                        <InputComponent
                          bg="#F1F4F6"
                          placeholder="Email"
                          type="email"
                        />
                      </Grid>
                      <Grid item lg={6} xs={12} spacing={1}>
                        <InputComponent
                          bg="#F1F4F6"
                          placeholder="Telephone"
                          type="number"
                        />
                      </Grid>
                    </Grid>
                    <Grid container marginTop={0} spacing={1}>
                      <Grid item lg={12} spacing={2}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              style={{ color: "#003030", padding: "0px 9px" }}
                            />
                          }
                          label="J’accepte de recevoir des biens selon les critères prédéfinis ci-dessus"
                          className="checkbox-modal"
                        />
                      </Grid>
                      <Grid item lg={12} spacing={1}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              style={{ color: "#003030", padding: "0px 9px" }}
                            />
                          }
                          label={
                            <div>
                              Je déclare avoir pris connaissance des{" "}
                              <Link
                                to="/condition-dutilisations"
                                className="text-[#003030]"
                              >
                                Termes et Conditions d’utilisation
                              </Link>
                              de RICCI Real Estate
                            </div>
                          }
                          className="checkbox-modal"
                        />
                      </Grid>
                      <Grid item lg={12} spacing={2}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              style={{ color: "#003030", padding: "0px 9px" }}
                            />
                          }
                          label={
                            <div>
                              J’accepte la{" "}
                              <Link
                                to="/privacy-page"
                                className="text-[#003030]"
                              >
                                politique de confidentialité{" "}
                              </Link>
                              de RICCI Real Estate
                            </div>
                          }
                          className="checkbox-modal"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent="center"
                      className="mt-[20px] text-center"
                    >
                      <Grid item lg={6} xs={10}>
                        <button
                          class="w-full font-[OpenSans] text-[14px] font-bold bg-[#003030] button-color-next py-3 px-8  rounded-[5px]"
                          onClick={() => {
                            setShow(false);
                          }}
                        >
                          Envoyer
                        </button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RegisterModal;
