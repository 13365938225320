import React from "react";
import { Grid, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import BreakpointController from "../../components/breakpoint_controller/breakpoint_controller";
import FormComponent from "./form";

// image imports here
import Logo from "../../assets/images/logos/full_grey.svg";
import Facebook from "../../assets/icons/social/facebook.svg";
import Instagram from "../../assets/icons/social/instagram.svg";
import Linkedin from "../../assets/icons/social/Linkedin.svg";

import "./footer.scss";

export default function Footer(props) {
  const { hasFields } = props;

  const navigate = useNavigate();

  return (
    <>
      <Grid container>
        {hasFields ? (
          <div className="w-full py-[40px] bg-[#003030] flex justify-center">
            <Container maxWidth="lg" className="">
              <Grid className="flex justify-center ">
                <Grid item xs={12} lg={12} sm={12} className="about-home-main">
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={12}
                      lg={12}
                      sm={12}
                      className="border-b-[1px] border-b-white/[.4] pb-[20px]"
                    >
                      <Grid container spacing={2} className="">
                        <Grid
                          lg={2.5}
                          item
                          xs={12}
                          sm={6}
                          md={2.5}
                          marginTop={1}
                          className=" border-r-[0px] lg:border-r-[1px] xl:border-r-[1px] md:border-r-[1px] sm:border-r-[1px] border-r-white/[.4]"
                        >
                          <img
                            src={Logo}
                            alt="logo"
                            className="m-auto  lg:m-[0px] xl:m-[0px] md:m-[0px] sm:m-[0px] h-[187px] object-contain mt-[15px]"
                          />
                        </Grid>
                        <Grid
                          lg={2.5}
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          marginTop={1}
                          className=" border-r-[0px] lg:border-r-[1px] xl:border-r-[1px] md:border-r-[1px] sm:border-r-[1px] border-r-white/[.4] "
                        >
                          <div className="w-full  box-border h-full justify-between">
                            <div className="">
                              <p className="font-[OpenSans] font-bold text-[16px] text-[#C49676]">
                                Contact
                              </p>
                              <p className="font-[OpenSans] font-regular text-[16px] text-white ">
                              Tomberg 212
                                <br /> 1200 Woluwe-Saint-Lambert{" "}
                              </p>
                            </div>
                            <div className="mt-[15px]">
                              <p className="font-[OpenSans] font-bold text-[16px] text-[#C49676]">
                                Téléphone
                              </p>
                              <p className="font-[OpenSans] font-regular text-[16px] text-white ">
                                02/210.00.40
                              </p>
                            </div>
                            <div className="mt-[15px]">
                              <p className="font-[OpenSans] font-bold text-[16px] text-[#C49676]">
                                Email
                              </p>
                              <p className="font-[OpenSans] font-regular text-[16px] text-white break-all">
                                <a href="mailto:hello@riccirealestate.be">
                                  hello@riccirealestate.be
                                </a>
                              </p>
                            </div>
                            <div className="w-full mt-[15px]">
                              <p className="font-[OpenSans] font-bold text-[16px] text-[#C49676]">
                                Suivez-nous sur
                              </p>
                              <div className="flex mt-[15px] w-full justify-start lg:justify-center xl:justify-center md:justify-center sm:justify-center">
                                <a
                                  href="https://www.facebook.com/Ricci-Real-Estate-103613902359682"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img
                                    src={Facebook}
                                    alt="facebook"
                                    className="h-[34px] w-[34px] object-contain cursor-pointer mr-[13px]"
                                  />
                                </a>
                                <a
                                  href="https://www.instagram.com/ricci.realestate/"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img
                                    src={Instagram}
                                    alt="Instagram"
                                    className="h-[34px] w-[34px] object-contain cursor-pointer"
                                  />
                                </a>
                                <a
                                  href="https://www.linkedin.com/company/ricci-real-estate-belgium/?viewAsMember=true"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img
                                    src={Linkedin}
                                    alt="facebook"
                                    className="h-[34px] w-[34px] object-contain cursor-pointer ml-[13px]"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          lg={2.5}
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          marginTop={1}
                          className=" border-r-[0px] lg:border-r-[1px] xl:border-r-[1px] md:border-r-[1px] sm:border-r-[1px] border-r-white/[.4] "
                        >
                          <div className="w-full  box-border h-full ">
                            <p className="font-[OpenSans] font-bold text-[16px] text-[#C49676]">
                              Rubriques
                            </p>
                            <p
                              className="font-[OpenSans] font-regular text-[16px] text-white mt-[15px] cursor-pointer"
                              onClick={() => navigate("/result/?type=1")}
                            >
                              A vendre
                            </p>
                            <p
                              className="font-[OpenSans] font-regular text-[16px] text-white mt-[15px] cursor-pointer"
                              onClick={() => navigate("/result/?type=2")}
                            >
                              A louer
                            </p>
                            <p
                              className="font-[OpenSans] font-regular text-[16px] text-white mt-[15px] cursor-pointer"
                              onClick={() => navigate("/estimer-mon-bien")}
                            >
                              Estimer mon bien
                            </p>
                            <p
                              className="font-[OpenSans] font-regular text-[16px] text-white mt-[15px] cursor-pointer"
                              onClick={() => navigate("/gerer-mon-bien")}
                            >
                              Gérer mon bien
                            </p>
                            <p
                              className="font-[OpenSans] font-regular text-[16px] text-white mt-[15px] cursor-pointer"
                              onClick={() => navigate("/contact")}
                            >
                              Contactez-nous
                            </p>
                            <p
                              className="font-[OpenSans] font-regular text-[16px] text-white mt-[15px] cursor-pointer"
                              onClick={() =>
                                navigate("/condition-dutilisations")
                              }
                            >
                              Condition d’utilisations
                            </p>
                            <p
                              className="font-[OpenSans] font-regular text-[16px] text-white mt-[15px] cursor-pointer"
                              onClick={() => navigate("/privacy-page")}
                            >
                              Cookies
                            </p>
                          </div>
                        </Grid>
                        <Grid
                          lg={4.5}
                          item
                          xs={12}
                          sm={6}
                          md={3.5}
                          marginTop={1}
                          className="image-wrapper  "
                        >
                          <Grid container justifyContent="end">
                            <Grid item lg={11} xs={12}>
                              <FormComponent />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent="center"
                      className="pt-[20px]"
                    >
                      <Grid item lg={10} md={10}>
                        <p className="text-[12px] font-[OpenSans] text-white text-center">
                          Ricci Real Estate sous la dénomination juridique MGB
                          groupe srl situé Rue Kelle 123/4 à 1150
                          Woluwe-Saint-Pierre. TVA : 0839.995.848. BCE :
                          0839.995.848. Agent immobilier agréé IPI sous le
                          numéro 507 934 - Numéro d'entreprise : BE0839. 995.848
                          — Assuré auprès d'AXA RC professionnelle et
                          cautionnement, police n° 730.390.160 - Instance de
                          contrôle : IPI, rue du Luxemburg 16B, 1000 Bruxelles,
                          Belgique Soumis au &nbsp;
                          <a
                            href="https://www.ipi.be/lagent-immobilier/la-deontologie-de-lagent-immobilier"
                            target="_blank"
                            rel="noreferrer"
                            className="underline"
                           >
                            code déontologique de l'IPI.
                          </a>
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </div>
        ) : (
          <>
            <BreakpointController direction="down" breakpoint="sm">
              <div className="w-full h-[377px] bg-[#003030] flex justify-center">
                <Container maxWidth="lg" className="h-full">
                  <Grid container alignItems="center" className="h-full">
                    <Grid container md={2} className="h-[278px]">
                      <Grid
                        container
                        justifyContent="center"
                        className="h-full border-r-[1px] border-r-white/[.4]"
                      >
                        <img
                          src={Logo}
                          alt="logo"
                          className="h-[187px] object-contain mt-[15px]"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      sm={10}
                      className="h-[278px]"
                      direction="column"
                      justifyContent="space-between"
                    >
                      <Grid container className="h-[120px]">
                        <div className="box-border px-[40px] border-r-[1px] border-r-white/[.4]">
                          <div className="mt-[20px] flex flex-col">
                            <p className="font-[OpenSans] font-bold text-[16px] text-[#C49676]">
                              Contact
                            </p>
                            <p className="font-[OpenSans] font-regular text-[16px] text-white mt-[19px] leading-[17px]">
                            Tomberg 212 <br /> 1200 Woluwe-Saint-Lambert
                            </p>
                          </div>
                        </div>
                        <div className="box-border px-[40px] border-r-[1px] border-r-white/[.4]">
                          <div className="mt-[20px] flex flex-col">
                            <p className="font-[OpenSans] font-bold text-[16px] text-[#C49676]">
                              Téléphone
                            </p>
                            <p className="font-[OpenSans] font-regular text-[16px] text-white mt-[19px] leading-[17px]">
                              02/210.00.40
                            </p>
                          </div>
                        </div>
                        <div className="box-border px-[40px] border-r-[1px] border-r-white/[.4]">
                          <div className="mt-[20px] flex flex-col">
                            <p className="font-[OpenSans] font-bold text-[16px] text-[#C49676]">
                              Email
                            </p>
                            <p className="font-[OpenSans] font-regular text-[16px] text-white mt-[19px] leading-[17px]">
                              <a href="mailto:hello@riccirealestate.be">
                                hello@riccirealestate.be
                              </a>
                            </p>
                          </div>
                        </div>
                        <div className="box-border px-[40px]">
                          <div className="mt-[20px] flex flex-col">
                            <p className="font-[OpenSans] font-bold text-[16px] text-[#C49676]">
                              Suivez-nous sur
                            </p>
                            <div className="flex mt-[10px] w-full">
                              <a
                                href="https://www.facebook.com/Ricci-Real-Estate-103613902359682"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src={Facebook}
                                  alt="facebook"
                                  className="h-[34px] w-[34px] object-contain cursor-pointer mr-[13px]"
                                />
                              </a>
                              <a
                                href="https://www.instagram.com/ricci.realestate/"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src={Instagram}
                                  alt="Instagram"
                                  className="h-[34px] w-[34px] object-contain cursor-pointer"
                                />
                              </a>
                              <a
                                href="https://www.linkedin.com/company/ricci-real-estate-belgium/?viewAsMember=true"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src={Linkedin}
                                  alt="facebook"
                                  className="h-[34px] w-[34px] object-contain cursor-pointer ml-[13px]"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        container
                        justifyContent="flex-end"
                        className="box-border pl-[33px]"
                      >
                        <div className="w-full h-[1px] bg-white/[.4]" />
                      </Grid>
                      <Grid container className="">
                        <Grid container>
                          <div className="w-full box-border px-[40px]">
                            <p className="font-[OpenSans] font-bold text-[16px] text-[#C49676]">
                              Rubriques
                            </p>
                          </div>
                        </Grid>
                        <Grid container className="pl-[40px]">
                          <Grid container sm={3} direction="column">
                            <p
                              className="font-[OpenSans] font-regular text-[16px] text-white mt-[20px] cursor-pointer"
                              onClick={() => navigate("/result/?type=1")}
                            >
                              A vendre
                            </p>
                            <p
                              className="font-[OpenSans] font-regular text-[16px] text-white mt-[7px] cursor-pointer"
                              onClick={() => navigate("/result/?type=2")}
                            >
                              A louer
                            </p>
                          </Grid>
                          <Grid container sm={3} direction="column">
                            <p
                              className="font-[OpenSans] font-regular text-[16px] text-white mt-[20px] cursor-pointer"
                              onClick={() => navigate("/estimer-mon-bien")}
                            >
                              Estimer mon bien
                            </p>
                            <p
                              className="font-[OpenSans] font-regular text-[16px] text-white mt-[7px] cursor-pointer"
                              onClick={() => navigate("/gerer-mon-bien")}
                            >
                              Gérer mon bien
                            </p>
                          </Grid>
                          <Grid container sm={3} direction="column">
                            <p
                              className="font-[OpenSans] font-regular text-[16px] text-white mt-[20px] cursor-pointer"
                              onClick={() => navigate("/contact")}
                            >
                              Contact
                            </p>
                            <p
                              className="font-[OpenSans] font-regular text-[16px] text-white mt-[7px] cursor-pointer"
                              onClick={() =>
                                navigate("/condition-dutilisations")
                              }
                            >
                              Condition d’utilisations
                            </p>
                          </Grid>
                          <Grid container sm={3} direction="column">
                            <p
                              className="font-[OpenSans] font-regular text-[16px] text-white mt-[20px] cursor-pointer"
                              onClick={() => navigate("/privacy-page")}
                            >
                              Cookies
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </BreakpointController>
            <BreakpointController direction="up" breakpoint="sm">
              <div className="w-full h-[395px] mb-[70px] lg:mb-[0px] md:mb-[0px] sm:mb-[0px] bg-[#003030] flex justify-center">
                <Container maxWidth="lg" className="h-full">
                  <Grid container alignItems="center" className="h-full">
                    <Grid container alignItems="center">
                      <Grid container xs={6} sm={6} className="h-[153px]">
                        <Grid
                          container
                          justifyContent="center"
                          className="h-full border-r-[1px] border-r-white/[.4]"
                        >
                          <img
                            src={Logo}
                            alt="logo"
                            className="h-[126px] object-contain mt-[15px]"
                          />
                        </Grid>
                      </Grid>
                      <Grid container xs={6} sm={6} className="pl-[25px]">
                        <div className="box-border border-b-[1px] border-b-white/[.4] w-full">
                          <div className="mt-[11px] flex flex-col mb-[9px]">
                            <p className="font-[OpenSans] font-bold text-[12px] text-[#C49676]">
                              Contact
                            </p>
                            <p className="font-[OpenSans] font-regular text-[12px] text-white leading-[17px]">
                            Tomberg 212 <br /> 1200 Woluwe-Saint-Lambert
                            </p>
                          </div>
                        </div>
                        <div className="box-border border-b-[1px] border-b-white/[.4] w-full">
                          <div className="mt-[11px] flex flex-col mb-[9px]">
                            <p className="font-[OpenSans] font-bold text-[12px] text-[#C49676]">
                              Téléphone
                            </p>
                            <p className="font-[OpenSans] font-regular text-[12px] text-white leading-[17px]">
                              02/210.00.40
                            </p>
                          </div>
                        </div>
                        <div className="box-border w-full">
                          <div className="mt-[11px] flex flex-col">
                            <p className="font-[OpenSans] font-bold text-[12px] text-[#C49676]">
                              Email
                            </p>
                            <p className="font-[OpenSans] font-regular text-[12px] text-white leading-[17px]">
                              <a href="mailto:hello@riccirealestate.be">
                                hello@riccirealestate.be
                              </a>
                            </p>
                          </div>
                        </div>
                      </Grid>
                      <Grid container className="mt-[13px]">
                        <div className="w-full h-[1px] bg-white/[.4]" />
                      </Grid>
                      <Grid container>
                        <Grid container className="mt-[18px]">
                          <p className="font-[OpenSans] font-bold text-[12px] text-[#C49676]">
                            Rubriques
                          </p>
                        </Grid>
                        <Grid container className="mt-[6px]">
                          <Grid container xs={6} sm={6} direction="column">
                            <p
                              className="font-[OpenSans] font-regular text-[12px] text-white leading-[26px] cursor-pointer"
                              onClick={() => navigate("/result/?type=1")}
                            >
                              A vendre
                            </p>
                            <p
                              className="font-[OpenSans] font-regular text-[12px] text-white leading-[26px] cursor-pointer"
                              onClick={() => navigate("/result/?type=2")}
                            >
                              A louer
                            </p>
                            <p
                              className="font-[OpenSans] font-regular text-[12px] text-white leading-[26px] cursor-pointer"
                              onClick={() => navigate("/estimer-mon-bien")}
                            >
                              Estimer mon bien
                            </p>
                            <p
                              className="font-[OpenSans] font-regular text-[12px] text-white leading-[26px] cursor-pointer"
                              onClick={() => navigate("/gerer-mon-bien")}
                            >
                              Gérer mon bien
                            </p>
                            <p
                              className="font-[OpenSans] font-regular text-[12px] text-white leading-[26px] cursor-pointer"
                              onClick={() => navigate("/contact")}
                            >
                              Contactez nous
                            </p>
                          </Grid>
                          <Grid container xs={6} sm={6} className="h-full">
                            <Grid
                              container
                              direction="column"
                              className="h-[50%]"
                            >
                              <p
                                className="font-[OpenSans] font-regular text-[12px] text-white leading-[26px] cursor-pointer"
                                onClick={() =>
                                  navigate("/condition-dutilisations")
                                }
                              >
                                Condition d’utilisations
                              </p>
                              <p
                                className="font-[OpenSans] font-regular text-[12px] text-white leading-[26px] cursor-pointer"
                                onClick={() => navigate("/privacy-page")}
                              >
                                Cookies
                              </p>
                            </Grid>
                            <Grid container className="h-[50%]">
                              <a
                                href="https://www.facebook.com/Ricci-Real-Estate-103613902359682"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src={Facebook}
                                  alt="facebook"
                                  className="h-[30px] w-[30px] object-contain mr-[16px]"
                                />
                              </a>
                              <a
                                href="https://www.instagram.com/ricci.realestate/"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src={Instagram}
                                  alt="instagram"
                                  className="h-[30px] w-[30px] object-contain mr-[16px]"
                                />
                              </a>
                              <a
                                href="https://www.linkedin.com/company/ricci-real-estate-belgium/?viewAsMember=true"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src={Linkedin}
                                  alt="facebook"
                                  className="h-[34px] w-[34px] object-contain cursor-pointer ml-[13px]"
                                />
                              </a>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* 
                                        
                                        <Grid container className = "">
                                            <Grid container>
                                                <div className = "w-full box-border px-[40px]">
                                                    <p className = "font-[OpenSans] font-bold text-[16px] text-[#C49676]">Rubriques</p>
                                                </div>
                                            </Grid>
                                            <Grid container className = "pl-[40px]">
                                                <Grid container sm = {3} direction = "column">
                                                    <p className = "font-[OpenSans] font-regular text-[16px] text-white mt-[20px] cursor-pointer">A vendre</p>
                                                    <p className = "font-[OpenSans] font-regular text-[16px] text-white mt-[7px] cursor-pointer">A louer</p>
                                                </Grid>
                                                <Grid container sm = {3} direction = "column">
                                                    <p className = "font-[OpenSans] font-regular text-[16px] text-white mt-[20px] cursor-pointer">Estimer mon bien</p>
                                                    <p className = "font-[OpenSans] font-regular text-[16px] text-white mt-[7px] cursor-pointer">Gérer mon bien</p>
                                                </Grid>
                                                <Grid container sm = {3} direction = "column">
                                                    <p className = "font-[OpenSans] font-regular text-[16px] text-white mt-[20px] cursor-pointer">Contact</p>
                                                    <p className = "font-[OpenSans] font-regular text-[16px] text-white mt-[7px] cursor-pointer">Condition d’utilisations</p>
                                                </Grid>
                                                <Grid container sm = {3} direction = "column">
                                                    <p className = "font-[OpenSans] font-regular text-[16px] text-white mt-[20px] cursor-pointer">Cookies</p>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid> */}
                  </Grid>
                </Container>
              </div>
            </BreakpointController>
          </>
        )}
      </Grid>
    </>
  );
}

Footer.defaultProps = {
  hasFields: false,
};

Footer.propTypes = {
  hasFields: PropTypes.bool,
};
