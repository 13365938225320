import React, { useState,useRef } from "react";
import { Grid, Container } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import BreakpointController from "../../breakpoint_controller/breakpoint_controller";
import { IoIosCloseCircleOutline } from "react-icons/io";

// image imports here
import Logo from "../../../assets/images/logos/full_white.svg";
import LogoFull from "../../../assets/images/logos/full_green.svg";
import BrandText from "../../../assets/images/texts/real_estate_exp_single.svg";
import Dropdown from "../../../assets/icons/dropdown.svg";
import Facebook from "../../../assets/icons/social/facebook.svg";
import Instagram from "../../../assets/icons/social/instagram.svg";
import Linkedin from "../../../assets/icons/social/Linkedin.svg";

import "./main.scss";

export default function Main() {
  const [activeTab, setActiveTab] = useState(0);
  const [menuState, setMenuState] = useState(false);
  const [dropdownState, setDropdownState] = useState(false);
  const [selectedType, setSelectedType] = useState(0);
  const [postalCodeState, setPostalCodeState] = useState("")
  const videoRef = useRef();
  const navigate = useNavigate();

  const handleMenuChange = () => {
    setMenuState(!menuState);
  };

  const handleDropdownClick = () => {
    setDropdownState(!dropdownState)
  }

  const handleDropdownItemClick = id => {
    setSelectedType(id);
    setDropdownState(false)
  }

  const getType = () => {
    switch(selectedType) {
      case 0: 
        return 'Tout';
      case 1: 
        return 'Maison';
      case 2: 
        return 'Appartement';
      case 3: 
        return 'Terrain';
      case 4: 
        return 'Bureau';
      case 5: 
        return 'Commerce';
      case 6: 
        return 'Immeuble de rapport';
      case 7:
        return 'Garage';
      default: 
        return 'Tout';
    }
  }

  const handleSearchClick = () => {
    if(activeTab === 2){
      navigate("/estimer-mon-bien")
    }
    // generate url params then navigate to result
    else{
      let generatedUrl = `/result/?type=${activeTab === 0 ? '1' : activeTab === 2 ? '2' : '2'}`;
      if(selectedType && selectedType !== 0) {
        generatedUrl += `&category=${selectedType}`
      }
      if(postalCodeState) {
        generatedUrl += `&zip=${postalCodeState}`
      }
      navigate(generatedUrl)
    }
  }

//   document.onclick= function(event) {
//     // Compensate for IE<9's non-standard event model
//     //
//     console.log("clicked",videoRef.current)
    
//     videoRef.current && videoRef.current.play();
// };
  const goTo = id => {
    if(id === 1) {
      navigate("/result/?type=1");
    }
    else if(id === 2) {
      navigate("/result/?type=2");
    }
    else if(id === 3) {
      navigate("/gerer-mon-bien")
    }
    else if(id === 4) {
      navigate("/estimer-mon-bien")
    }
    setMenuState(false)
  }

  return (
    <Grid container className="relative">
      <div className="w-full h-screen absolute top-0 left-0 overflow-hidden">
        <BreakpointController direction="down" breakpoint="sm">
          <video width="100%" height="100%" autoPlay muted loop playsInline ref={videoRef}>
            <source
              src="https://player.vimeo.com/progressive_redirect/playback/721036251/rendition/1080p/file.mp4?loc=external&signature=1c04ebc2882e9f2d2daf9e2ed5ffba1826a37b7a93a7bf9c22388335f7fb80e4"
              type="video/mp4"

            />
          </video>
        </BreakpointController>
        <BreakpointController direction="up" breakpoint="sm">
          <video width="100%" height="100%" autoPlay muted loop playsInline ref={videoRef}>
            <source
              src="https://player.vimeo.com/progressive_redirect/playback/721039109/rendition/720p/file.mp4?loc=external&signature=3504fe37a61bce13c2eafc0715cbd6d7575060713a286c8f511b88b606db0ce7"
              type="video/mp4"
            />
          </video>
        </BreakpointController>
      </div>
      <div className="w-full h-screen flex box-border p-[27px] relative">
        <Container maxWidth="lg">
          <Grid container>
            <BreakpointController direction="down" breakpoint="sm">
              <Grid container justifyContent="space-between">
                <div className="flex items-center">
                  <p
                    className="font-[OpenSans] font-regular text-[16px] text-white mr-[70px] cursor-pointer"
                    onClick={() => navigate("/result/?type=1")}
                  >
                    A VENDRE
                  </p>
                  <p
                    className="font-[OpenSans] font-regular text-[16px] text-white cursor-pointer"
                    onClick={() => navigate("/result/?type=2")}
                  >
                    A LOUER
                  </p>
                </div>
                <div className="flex items-center">
                  <p 
                    className="font-[OpenSans] font-regular text-[16px] text-white mr-[70px] uppercase cursor-pointer" 
                    onClick = {() =>  navigate("/gerer-mon-bien")}
                  >
                    gérér MON BIEN
                  </p>
                  <p
                    className="font-[OpenSans] font-regular text-[16px] text-white mr-[70px] cursor-pointer"
                    onClick={() => navigate("/estimer-mon-bien")}
                  >
                    ESTIMER MON BIEN
                  </p>
                  <p
                    className="font-[OpenSans] font-regular text-[16px] text-white cursor-pointer"
                    onClick={() => navigate("/contact")}
                  >
                    CONTACT
                  </p>
                </div>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                <img
                  src={Logo}
                  alt="logo"
                  className="h-[256px] object-contain"
                />
                <img
                  src={BrandText}
                  alt="brand text"
                  className="w-[540px] object-contain mt-[67px]"
                />
              </Grid>
            </BreakpointController>
            <BreakpointController direction="up" breakpoint="sm">
              <Grid container justifyContent="flex-end">
                <div
                  className="flex flex-col w-[25px] h-[25px] justify-between cursor-pointer relative"
                  onClick={handleMenuChange}
                >
                  <div className="w-full h-[3px] rounded-[2px] bg-white" />
                  <div className="w-full h-[3px] rounded-[2px] bg-white" />
                  <div className="w-full h-[3px] rounded-[2px] bg-white" />
                </div>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                <img
                  src={Logo}
                  alt="logo"
                  className="h-[256px] object-contain mt-[10px]"
                />
                <img
                  src={BrandText}
                  alt="brand text"
                  className="w-[540px] object-contain mt-[20px]"
                />
              </Grid>
              {/* side menu overlay */}
              <div
                className="w-full h-screen fixed top-0 left-0 flex box-border pr-[15px] animated z-[2]"
                style={{
                  opacity: menuState ? 1 : 0,
                  transform: menuState
                    ? "translateX(0px)"
                    : "translateX(-100vw)",
                }}
              >
                <div className="w-full h-full bg-white header-sidemenu-container flex flex-col box-border px-[29px] py-[10px] relative">
                  <Grid container>
                    <img
                      src={LogoFull}
                      alt="logo"
                      className="h-[100px] object-contain"
                    />
                  </Grid>
                  <Grid container>
                    <p className="font-[Poppins] font-semibold text-[26px] text-[#222] mt-[20px]">
                      The real estate<br></br>experience
                    </p>
                  </Grid>
                  <Grid container>
                  <div className="flex mt-[30px]">
              <a
                href="https://www.facebook.com/Ricci-Real-Estate-103613902359682"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Facebook}
                  alt="facebook"
                  className="h-[30px] w-[30px] object-contain cursor-pointer mx-[16px]"
                />
              </a>
              <a
                href="https://www.instagram.com/ricci.realestate/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Instagram}
                  alt="instagram"
                  className="h-[30px] w-[30px] object-contain cursor-pointer"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/ricci-real-estate-belgium/?viewAsMember=true"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Linkedin}
                  alt="Linkedin"
                  className="h-[30px] w-[30px] object-contain cursor-pointer ml-[13px]"
                />
              </a>
            </div>
                  </Grid>
                  <Grid container>
                    <div className="flex flex-col mt-[62px]">
                      <p className="font-[OpenSans] font-medium text-[16px] text-[#003030] uppercase" onClick = {() => goTo(1)}>
                        à vendre
                      </p>
                      <p className="font-[OpenSans] font-medium text-[16px] text-[#003030] uppercase mt-[20px]" onClick = {() => goTo(2)}>
                        à louer
                      </p>
                      <p className="font-[OpenSans] font-medium text-[16px] text-[#003030] uppercase mt-[20px]" onClick={() => goTo(3)}>
                        gérér MON BIEN
                      </p>
                      <p className="font-[OpenSans] font-medium text-[16px] text-[#003030] uppercase mt-[20px]" onClick={() => goTo(4)}>
                        ESTIMER MON BIEN
                      </p>
                    </div>
                  </Grid>
                  <Grid container>
                    <button
                      type="button"
                      className="h-[43px] w-[139px] rounded-[5px] bg-[#003030] cursor-pointer flex justify-center items-center mt-[51px]"
                    >
                      <p className="font-[OpenSans] font-bold text-[14px] text-white/[0.9]">
                        Contactez-nous
                      </p>
                    </button>
                  </Grid>
                  {/* close button */}
                  <div
                    className="absolute top-[13px] right-[13px]"
                    onClick={handleMenuChange}
                  >
                    <IoIosCloseCircleOutline size="2em" color="#003030" />
                  </div>
                </div>
              </div>
            </BreakpointController>
            <BreakpointController direction="down" breakpoint="sm">
              <Grid container justifyContent="center">
                <div className="max-w-[651px] w-[90%] h-[150px] flex flex-col mt-[130px]">
                  <div className="flex">
                    <div
                      className="w-[185px] h-[46px] bg-white rounded-tl-[5px] rounded-tr-[5px] flex justify-center items-center main-search-container-tab relative cursor-pointer"
                      style={{ zIndex: activeTab === 0 && 1 }}
                      onClick={() => setActiveTab(0)}
                    >
                      <div className="flex justify-center relative">
                        <p className="font-[OpenSans] font-bold text-[16px] text-[#003030]">
                          A vendre
                        </p>
                        <div
                          className="w-[15px] h-[2px] bg-[#003030] absolute bottom-[-5px] animated"
                          style={{
                            opacity: activeTab === 0 ? 1 : 0,
                            transform:
                              activeTab === 0
                                ? "translateY(0px)"
                                : "translateY(7px)",
                          }}
                        />
                      </div>
                      <div className="w-full h-[20px] bg-white absolute top-[95%] left-0" />
                    </div>
                    <div
                      className="w-[185px] h-[46px] bg-white rounded-tl-[5px] rounded-tr-[5px] flex justify-center items-center main-search-container-tab relative cursor-pointer"
                      style={{ zIndex: activeTab === 1 && 1 }}
                      onClick={() => setActiveTab(1)}
                    >
                      <div className="flex justify-center relative">
                        <p className="font-[OpenSans] font-bold text-[16px] text-[#003030]">
                          A louer
                        </p>
                        <div
                          className="w-[15px] h-[2px] bg-[#003030] absolute bottom-[-5px] animated"
                          style={{
                            opacity: activeTab === 1 ? 1 : 0,
                            transform:
                              activeTab === 1
                                ? "translateY(0px)"
                                : "translateY(7px)",
                          }}
                        />
                      </div>
                      <div className="w-full h-[20px] bg-white absolute top-[95%] left-0" />
                    </div>
                    <div
                      className="w-[185px] h-[46px] bg-white rounded-tl-[5px] rounded-tr-[5px] flex justify-center items-center main-search-container-tab relative cursor-pointer"
                      style={{ zIndex: activeTab === 2 && 1 }}
                      onClick={() => setActiveTab(2)}
                    >
                      <div className="flex justify-center relative">
                        <p className="font-[OpenSans] font-bold text-[16px] text-[#003030]">
                          Estimer
                        </p>
                        <div
                          className="w-[15px] h-[2px] bg-[#003030] absolute bottom-[-5px] animated"
                          style={{
                            opacity: activeTab === 2 ? 1 : 0,
                            transform:
                              activeTab === 2
                                ? "translateY(0px)"
                                : "translateY(7px)",
                          }}
                        />
                      </div>
                      <div className="w-full h-[20px] bg-white absolute top-[95%] left-0" />
                    </div>
                  </div>
                  <div className="w-full h-[104px] bg-white rounded-tr-[5px] rounded-br-[5px] rounded-bl-[5px] main-search-container relative flex items-center justify-center">
                    <div className="w-[186px] h-[43px] relative mr-[25px]">
                      <div className = " w-full h-full rounded-[5px] bg-[#F1F4F6] box-border px-[16px] py-[12px] flex justify-between items-center cursor-pointer relative" onClick = {handleDropdownClick}>
                        <p className="font-[OpenSans] font-regular text-[14px] text-[#949AA4]/[0.9]">
                          {getType()}
                        </p>
                        <img
                          src={Dropdown}
                          alt="dropdown arrow"
                          className="h-[10px] w-[10px] object-contain animated"
                          style = {{transform: dropdownState ? 'rotate(180deg)' : 'rotate(0deg)'}}
                        />
                      </div>
                      <div className = "w-full rounded-b-[5px] bg-[#F1F4F6] flex flex-col absolute top-[100%] left-0 animated origin-top" style = {{opacity: dropdownState ? 1 : 0, transform: dropdownState ? 'scaleY(1)' : 'scaleY(0)'}}>
                          <div className = "hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer" onClick = {() => handleDropdownItemClick(0)}>
                            <p className="font-[OpenSans] font-regular text-[14px] ">
                              Tout
                            </p>
                          </div>
                          <div className = "hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer" onClick = {() => handleDropdownItemClick(1)}>
                            <p className="font-[OpenSans] font-regular text-[14px]">
                              Maison
                            </p>
                          </div>
                          <div className = "hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer" onClick = {() => handleDropdownItemClick(2)}>
                            <p className="font-[OpenSans] font-regular text-[14px] ">
                              Appartement
                            </p>
                          </div>
                          <div className = "hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer" onClick = {() => handleDropdownItemClick(5)}>
                            <p className="font-[OpenSans] font-regular text-[14px] ">
                              Commerce 
                            </p>
                          </div>
                          <div className = "hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer" onClick = {() => handleDropdownItemClick(4)}>
                            <p className="font-[OpenSans] font-regular text-[14px] ">
                              Bureau
                            </p>
                          </div>
                          <div className = "hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer" onClick = {() => handleDropdownItemClick(3)}>
                            <p className="font-[OpenSans] font-regular text-[14px] ">
                              Terrain 
                            </p>
                          </div>
                          <div className = "hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white  w-full box-border px-[16px] py-[12px] cursor-pointer" onClick = {() => handleDropdownItemClick(7)}>
                            <p className="font-[OpenSans]  font-regular text-[14px] ">
                              Garage
                            </p>
                          </div>
                      </div>
                    </div>
                    <div className="w-[256px] h-[43px] rounded-[5px] bg-[#F1F4F6] mr-[25px] flex justify-center items-center box-border px-[16px] py-[12px]">
                      <input
                        className="font-[OpenSans] font-regular text-[14px] text-black main-search-input bg-transparent outline-none w-full"
                        placeholder="Code postal"
                        type = "number"
                        value = {postalCodeState}
                        onChange = {event => setPostalCodeState(event.target.value)}
                      />
                    </div>
                    <div className="hoverEffect w-[94px] h-[43px] rounded-[5px] bg-[#003030] flex justify-center items-center cursor-pointer" onClick = {()=>{
                       handleSearchClick()
                      }}>
                      <p className="font-[OpenSans] font-bold text-[14px] text-white/[0.9] hoverEffect">
                        Chercher
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
            </BreakpointController>
            <BreakpointController direction="up" breakpoint="sm">
              <Grid container justifyContent="center">
                <div className="max-w-[651px] w-[115%] h-[150px] flex flex-col mt-[10px]">
                  <div className="flex">
                    <div
                      className="w-[185px] h-[46px] bg-white rounded-tl-[5px] rounded-tr-[5px] flex justify-center items-center main-search-container-tab relative cursor-pointer"
                      style={{ zIndex: activeTab === 0 && 1 }}
                      onClick={() => setActiveTab(0)}
                    >
                      <div className="flex justify-center relative">
                        <p className="font-[OpenSans] font-bold text-[16px] text-[#003030]">
                          A vendre
                        </p>
                        <div
                          className="w-[15px] h-[2px] bg-[#003030] absolute bottom-[-5px] animated"
                          style={{
                            opacity: activeTab === 0 ? 1 : 0,
                            transform:
                              activeTab === 0
                                ? "translateY(0px)"
                                : "translateY(7px)",
                          }}
                        />
                      </div>
                      <div className="w-full h-[20px] bg-white absolute top-[95%] left-0" />
                    </div>
                    <div
                      className="w-[185px] h-[46px] bg-white rounded-tl-[5px] rounded-tr-[5px] flex justify-center items-center main-search-container-tab relative cursor-pointer"
                      style={{ zIndex: activeTab === 1 && 1 }}
                      onClick={() => setActiveTab(1)}
                    >
                      <div className="flex justify-center relative">
                        <p className="font-[OpenSans] font-bold text-[16px] text-[#003030]">
                          A louer
                        </p>
                        <div
                          className="w-[15px] h-[2px] bg-[#003030] absolute bottom-[-5px] animated"
                          style={{
                            opacity: activeTab === 1 ? 1 : 0,
                            transform:
                              activeTab === 1
                                ? "translateY(0px)"
                                : "translateY(7px)",
                          }}
                        />
                      </div>
                      <div className="w-full h-[20px] bg-white absolute top-[95%] left-0" />
                    </div>
                    <div
                      className="w-[185px] h-[46px] bg-white rounded-tl-[5px] rounded-tr-[5px] flex justify-center items-center main-search-container-tab relative cursor-pointer"
                      style={{ zIndex: activeTab === 2 && 1 }}
                      onClick={() => setActiveTab(2)}
                    >
                      <div className="flex justify-center relative">
                        <p className="font-[OpenSans] font-bold text-[16px] text-[#003030]">
                          Estimer
                        </p>
                        <div
                          className="w-[15px] h-[2px] bg-[#003030] absolute bottom-[-5px] animated"
                          style={{
                            opacity: activeTab === 2 ? 1 : 0,
                            transform:
                              activeTab === 2
                                ? "translateY(0px)"
                                : "translateY(7px)",
                          }}
                        />
                      </div>
                      <div className="w-full h-[20px] bg-white absolute top-[95%] left-0" />
                    </div>
                  </div>
                  <div className="w-full bg-white rounded-tr-[5px] rounded-br-[5px] rounded-bl-[5px] main-search-container relative flex flex-col items-center justify-center box-border p-[25px]">
                    <div className="w-full h-[43px] relative" onClick = {handleDropdownClick}>
                      <div className = "w-full h-full rounded-[5px] bg-[#F1F4F6] box-border px-[16px] py-[12px] flex justify-between items-center">
                        <p className="font-[OpenSans] font-regular text-[14px] text-[#949AA4]/[0.9]">
                          {getType()}
                        </p>
                        <img
                          src={Dropdown}
                          alt="dropdown arrow"
                          className="h-[10px] w-[10px] object-contain animated"
                          style = {{transform: dropdownState ? 'rotate(180deg)' : 'rotate(0deg)'}}
                        />
                      </div>
                      <div className = "w-full rounded-b-[5px] bg-[#F1F4F6] flex flex-col absolute top-[100%] left-0 animated origin-top" style = {{opacity: dropdownState ? 1 : 0, transform: dropdownState ? 'scaleY(1)' : 'scaleY(0)'}}>
                        <div className = "hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer" onClick = {() => handleDropdownItemClick(0)}>
                          <p className="font-[OpenSans] font-regular text-[14px] ">
                            Tout
                          </p>
                        </div>
                        <div className = "hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer" onClick = {() => handleDropdownItemClick(1)}>
                          <p className="font-[OpenSans] font-regular text-[14px]">
                            Maison
                          </p>
                        </div>
                        <div className = "hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer" onClick = {() => handleDropdownItemClick(2)}>
                          <p className="font-[OpenSans] font-regular text-[14px] ">
                            Appartement
                          </p>
                        </div>
                        <div className = "hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer" onClick = {() => handleDropdownItemClick(5)}>
                          <p className="font-[OpenSans] font-regular text-[14px] ">
                            Commerce 
                          </p>
                        </div>
                        <div className = "hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer" onClick = {() => handleDropdownItemClick(4)}>
                          <p className="font-[OpenSans] font-regular text-[14px] ">
                            Bureau
                          </p>
                        </div>
                        <div className = "hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white w-full box-border px-[16px] py-[12px] cursor-pointer" onClick = {() => handleDropdownItemClick(3)}>
                          <p className="font-[OpenSans] font-regular text-[14px] ">
                            Terrain 
                          </p>
                        </div>
                        <div className = "hover:bg-[#303030] text-[#949AA4]/[0.9] hover:text-white  w-full box-border px-[16px] py-[12px] cursor-pointer" onClick = {() => handleDropdownItemClick(7)}>
                          <p className="font-[OpenSans]  font-regular text-[14px] ">
                            Garage
                          </p>
                        </div>
                      </div>
                    </div>
                    
                    <div className="w-full h-[43px] rounded-[5px] bg-[#F1F4F6] flex justify-center items-center box-border px-[16px] py-[12px] mt-[15px]">
                      <input
                        className="font-[OpenSans] font-regular text-[14px] text-black main-search-input bg-transparent outline-none w-full"
                        placeholder="Code postal"
                        type = "number"
                        value = {postalCodeState}
                        onChange = {event => setPostalCodeState(event.target.value)}
                      />
                    </div>
                    <div className="w-full flex justify-end">
                      <div className="hoverEffect w-[94px] h-[43px] rounded-[5px] bg-[#003030] flex justify-center items-center cursor-pointer mt-[15px]" onClick = {() => handleSearchClick()}>
                        <p className="hoverEffect Chercherfont-[OpenSans] font-bold text-[14px] text-white/[0.9]">
                          Chercher
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </BreakpointController>
          </Grid>
        </Container>
      </div>
    </Grid>
  );
}
