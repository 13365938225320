import React from "react";
import { Grid, Container } from "@mui/material";
import Avatar from "@mui/material/Avatar";

import "./banner.scss";

const Banner = (props) => {
  const { data } = props;
  return (
    <Container>
      <Grid
        container
        justifyContent="center"
        className="my-[30px] xl:my-[60px] lg:my-[60px]"
      >
        <Grid item xs={12} lg={12}>
          <div className="main-banner rounded-[15px] flex lg:p-[30px] sm:p-[20px] md:p-[30px] p-[16px]">
            <div>
              <div
                style={{
                  background: "#f6f6f6",
                  padding: "14px",
                  borderTopLeftRadius: "20px",
                  borderBottomLeftRadius: "20px",
                  width: "45px",
                  height: "60%",
                }}
              ></div>
              <div
                style={{
                  background: "#f6f6f6",
                  padding: "14px",
                  borderTopLeftRadius: "20px",
                  width: "45px",
                  height: "38%",
                  marginTop: "8px",
                }}
              ></div>
            </div>
            <div className="lg:px-12 md:px-12 px-4 flex-1 ">
              <div className="flex items-start lg:items-center xl:items-center md:items-center sm:items-center">
                <div className="">
                  <div className="flex justify-center items-center h-[60px] w-[60px] rounded-full bg-[#bdbdbd]">
                    <p className="text-white font-normal">
                      {data?.name?.substring(0, 1)}
                    </p>
                  </div>
                </div>
                <div className="pl-6">
                  <h1 className="font-[OpenSans] font-bold">{data?.name}</h1>
                  <h1 className="font-[OpenSans] text-[#666666]">
                    {data?.title}
                  </h1>
                </div>
              </div>
              <p className="text-[#222222] font-[OpenSans] pt-4">
                {data?.descreption}
              </p>
            </div>
            <div>
              <div
                style={{
                  background: "#f6f6f6",
                  padding: "14px",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                  width: "45px",
                  height: "60%",
                }}
              ></div>
              <div
                style={{
                  background: "#f6f6f6",
                  padding: "14px",
                  borderTopRightRadius: "20px",
                  width: "45px",
                  height: "38%",
                  marginTop: "8px",
                }}
              ></div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Banner;
