import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import CardComponent from "../../components/card/card";
import CardTwo from "../../components/cardTwo/cardTwo";
import EmptyCard from "../../components/empty_card/empty_card";
import Filter from "../../components/filters/filters";
import { cardData, cardFourData, cardEightData } from "./constant";
import { getEstatesList } from "../../api/routes";
import { sortBy, orderBy } from "lodash";

// image asset imports
import icon from "../../assets/icons/dropdown.svg";

import "./result_page.scss";

const ResultPage = (props) => {
  const [originalEstateList, setOriginalEstateList] = useState([]);
  const [estateList, setEstateList] = useState([]);
  const [sortFilterState, setSortFilterState] = useState(0);
  const { show, setShow } = props;
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [searchParam, setSearchParam] = useSearchParams();

  useEffect(() => {
    getEstatesList(setOriginalEstateList);
  }, [searchParam]);

  useEffect(() => {
    if(originalEstateList && estateList) {
      sortEstateList(sortFilterState)
    }
  }, [sortFilterState])

  const filterEstateList = (type, category, postalCode) => {
    const estateListCopy = JSON.parse(JSON.stringify(originalEstateList));
    let estateListFiltered = JSON.parse(JSON.stringify(originalEstateList));
    console.log(postalCode)
    if (type) {
      estateListFiltered = estateListCopy.filter(estate => estate?.purpose?.id == type)
    }
    if(category) {
      estateListFiltered = estateListFiltered.filter(estate => estate?.category?.id == category)
    }
    if(postalCode) {
      estateListFiltered = estateListFiltered.filter(estate => estate?.zip == postalCode)
    }
    const sortedEstateList = sortEstateList(0, estateListFiltered)
    setEstateList(sortedEstateList);
  };

  const filterEstateListWithParams = (
    category,
    purpose,
    postalCode,
    city,
    rooms,
    areaMin,
    priceMax,
    reference,
    sortState
  ) => {
    const estateListCopy = JSON.parse(JSON.stringify(originalEstateList));
    let estateListFiltered = JSON.parse(JSON.stringify(estateListCopy));;
    // we filter through the list one by one to avoid situations where a default value is being sent in one of the values
    if (category) {
      estateListFiltered = estateListFiltered.filter(
        (estate) => estate?.category?.id == category
      );
    }
    if(purpose && purpose !== 0) {
      estateListFiltered = estateListFiltered.filter(
        (estate) => estate?.purpose?.id == purpose
      );
    }
    if (postalCode && postalCode.length > 0) {
      let blankArray = [];
      estateListFiltered.forEach(estate => {
        postalCode.forEach(code => {
          if(estate?.zip == code?.id) {
            blankArray.push(estate)
          }
        })
      })
      estateListFiltered = blankArray
    }
    if(city) {
      estateListFiltered = estateListFiltered.filter(
        (estate) => estate?.city?.toLowerCase().includes(city?.toLowerCase())
      );
    }
    if (rooms && rooms !== 0) {
      estateListFiltered = estateListFiltered.filter(
        (estate) => estate?.rooms == (rooms - 1)
      );
    }
    if (areaMin) {
      estateListFiltered = estateListFiltered.filter(
        (estate) => estate?.area >= areaMin
      );
    }
    if (priceMax) {
      estateListFiltered = estateListFiltered.filter(
        (estate) => estate?.price <= priceMax
      );
    }
    if (reference) {
      estateListFiltered = estateListFiltered.filter(
        (estate) => estate?.referenceNumber?.includes(reference)
      );
    }
    // last we sort the filtered data
    const sortedEstateList = sortEstateList(sortFilterState, estateListFiltered)
    setEstateList(sortedEstateList);
    setLoading(false);

  };

  const sortEstateList = (sortId, estateListParam) => {
    if(estateListParam) {
      // when an estate data array is passed
      if(sortId == 0) {
        // sort by recently added
        const sortedEstateList = estateListParam.reverse();
        return sortedEstateList;
      }
      else if(sortId == 1) {
        // sort by price ascending
        const sortedEstateList = orderBy(estateListParam, ['price'], ['asc'])
        return sortedEstateList;
      }
      else if(sortId == 2) {
        // sort by price descending
        const sortedEstateList = orderBy(estateListParam, ['price'], ['desc'])
        return sortedEstateList;
      }
      else if(sortId == 3) {
        // sort by alphabetical
        const sortedEstateList = orderBy(estateListParam, ['name'], ['asc'])
        return sortedEstateList;
      }
      else {
        return estateListParam;
      }
    }
    else {
      // when an estate array param is not passed, we use the state var
      if(sortId == 0) {
        // sort by recently added
        const sortedEstateList = estateList.reverse();
        setEstateList(sortedEstateList)
      }
      else if(sortId == 1) {
        // sort by price ascending
        const sortedEstateList = orderBy(estateList, ['price'], ['asc'])
        setEstateList(sortedEstateList)
      }
      else if(sortId == 2) {
        // sort by price descending
        const sortedEstateList = orderBy(estateList, ['price'], ['desc'])
        setEstateList(sortedEstateList)
      }
      else if(sortId == 3) {
        // sort by alphabetical
        const sortedEstateList = orderBy(estateList, ['name'], ['asc'])
        setEstateList(sortedEstateList)
      }
    }
  }

  const handleClick = () => {
    navigate("/estimer-mon-bien");
  };

  const handleModal = () => {
    setShow(true);
  };

  return (
    <>
      <Filter onFilterChange={filterEstateListWithParams} onFilterLoad = {filterEstateList} originalEstateList = {originalEstateList} sortEstateList = {sortEstateList} setLoading = {setLoading} loading = {loading} />
      <Container className="my-[30px]">
        <Grid container justifyContent="end" className="mb-[30px]">
          <Grid item lg={2} md={3} sm={3} xs={6}>
            <div class="flex flex-wrap ">
              <div class="w-full flex flex-wrap">
                <div class="w-full flex flex-wrap pr-0 ">
                  <div class="relative w-full border-none">
                    <select class="text-[#222222] bg-[#F1F4F6] font-bold outline-0 text-[#222222] font-[OpenSans] text-[14px]  appearance-none  inline-block py-3 pl-3 pr-8 rounded leading-tight w-full" value = {sortFilterState} onChange = {event => setSortFilterState(event.target.value)}>
                      {/* <option value="" disabled selected>
                        le plus recent
                      </option> */}
                      <option value = "0">le plus recent </option>
                      <option value = "1">Prix croissant </option>
                      <option value = "2">Prix decroissant</option>
                      <option value = "3">communes A- Z </option>
                    </select>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                      <i class="fas fa-chevron-down text-gray-400">
                        <img className="h-[14px]" src={icon} alt="" />
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        
        <Grid container spacing={4}>
          {estateList && estateList?.length > 0 ? (
            estateList?.map((single, index) => (
              <>
                {index === 3 && (
                  <Grid
                    item
                    xs={12}
                    lg={4}
                    sm={6}
                    md={4}
                    // key={single?.id}
                    className="card-with-logo-background"
                  >
                    <CardTwo data={cardFourData} handleClick={handleClick} />
                  </Grid>
                )}
                {index === 7 && (
                  <Grid
                    item
                    xs={12}
                    lg={4}
                    sm={6}
                    md={4}
                    // key={single?.id}
                    className="card-with-logo-background"
                  >
                    <CardTwo data={cardEightData} handleClick={handleModal} />
                  </Grid>
                )}
                <Grid item xs={12} lg={4} sm={6} md={4} key={single?.id}>
                  <CardComponent data={single} />
                </Grid>
              </>
            ))
          ) : (
            <EmptyCard />
          )}
          {/* {cardData?.length > 0 && (
            <Grid container className="py-[30px]" justifyContent="center">
              <button class="font-[OpenSans] bg-white text-[#003030] text-[14px] font-bold py-2 px-4 border border-[#DDDDDD] rounded">
                Charger plus
              </button>
            </Grid>
          )} */}
        </Grid>
      </Container>
    </>
  );
};

export default ResultPage;
