import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FirstSection from "../../components/detail-page/firstSection";
import SliderSection from "../../components/home_page/slider/slider";
import DetailPageModal from "../../components/detail_page_modal/detail_modal";
import "./detailPage.scss";
import { getEstatesList } from "../../api/routes";

export default function DetailPage() {
  const [show, setShow] = useState(false);
  const [originalEstateList, setOriginalEstateList] = useState([]);
  const [refState, setRefState] = useState("");

  const { stateId } = useParams();
  const getData = () => {
    
    getEstatesList(setOriginalEstateList);
  };

        useEffect(() => {
            getData();
        }, []);

  return (
    <>
      <FirstSection DataState={originalEstateList} setShow={setShow} setRefState = {setRefState} estateId = {stateId} />
      <SliderSection />
      <DetailPageModal show={show} refState = {refState} setShow={setShow}/>
    </>
  );
}
