import Mail from "../../assets/icons/mail.svg";
import house from "../../assets/icons/house.svg";
import appartement from "../../assets/icons/appartement.svg";
import immeuble from "../../assets/icons/immeuble.svg";
import commerce from "../../assets/icons/commerce.svg";
import terrain from "../../assets/icons/terrain.svg";
import garage from "../../assets/icons/garage.svg";

export const buttonData = [
  {
    id: 1,
    text: "1",
  },
  {
    id: 2,
    text: "2",
  },
  {
    id: 3,
    text: "3",
  },
  {
    id: 4,
    text: "4",
  },
  {
    id: 5,
    text: "5+",
  },
];

export const typeData = [
  {
    id: 1,
    text: "Maison",
    image: house,
  },
  {
    id: 2,
    text: "Appartement",
    image: appartement,
  },
  {
    id: 3,
    text: "Immeuble de rapport",
    image: immeuble,
  },
  // {
  //   id: 4,
  //   text: "Commerce / Bureau",
  //   image: commerce,
  // },
  // {
  //   id: 5,
  //   text: "Terrain",
  //   image: terrain,
  // },
  // {
  //   id: 6,
  //   text: "Garage",
  //   image: garage,
  // },
];
