import React from "react";
import { Container, Grid } from "@mui/material";

const DefinationSection = () => {
  return (
    <Grid container justifyContent="center">
      <Grid
        container
        justifyContent="center"
        className="bg-[#F1F4F6] py-[40px]"
      >
        <h1 className="font-[Poppins] text-[30px] xs:text-[40px] lg:text-[40px] text-center">
          <span className="text-[#222222] font-bold">
            Conditions générales{" "}
          </span>
          <span className="text-[#222222]">d’utilisation</span>
        </h1>
      </Grid>
      <Container>
        <Grid container justifyContent="center" className="pt-[40px]">
          <Grid container xs={12} lg={10} sm={12}>
            <h1 className="font-[Poppins] font-semibold text-[18px] lg:text-[26px] xs:text-[26px] pb-[10px]">
              Définitions & Application
            </h1>
            <Grid className="text-[#666666] text-[14px] font-[OpenSans] space-y-4">
              <p>
                Les présentes Conditions Générales d’Utilisation sont
                applicables pour toute utilisation du site web
                www.riccirealestate.be (ci-après le « Site Web »).
              </p>
              <p>
                Le site web est administré et géré par MGB Groupe srl sous la
                dénomination commerciale Ricci Real Estate, située Rue Kelle
                123/B4 à 1150 Woluwe-Saint-Pierre, et inscrite à la BCE sous le
                numéro : BE0839.995.848, (ci-après dénommé « le prestataire »).
              </p>
              <p>
                Le terme « utilisateur » fait référence à tout utilisateur, soit
                toute personne physique ou morale, inscrite ou non sur le Site
                Web, et qui consulte le Site Web ou son contenu, qui télécharge
                des fichiers, les utilise, s’enregistre via tout formulaire
                disponible sur le Site Web, devient membre, s’abonne ou
                contracte avec le prestataire.
              </p>

              <p>
                Le prestataire et l’utilisateur sont ci-après dénommés « les
                parties ».
              </p>
              <p>
                En surfant sur le Site Web, en lisant des documents, en
                téléchargeant des fichiers, en les consultant et/ou en les
                utilisant d’une quelconque manière, en s’enregistrant via tout
                formulaire disponible sur le Site Web, en devenant membre, en
                s’abonnant ou en contractant avec le prestataire, l’utilisateur
                marque formellement, sans conditions et sans aucune réserve, son
                accord sur les présentes conditions générales et s’engage à les
                respecter.
              </p>
              <p>
                Ces conditions sont applicables à toute consultation
                d’information, commande, abonnement, et contrat liant les
                parties. En outre, les présentes conditions générales excluent
                toutes autres conditions générales et s’y substituent.
              </p>
              <p>
                Le prestataire se réserve le droit de modifier les présentes
                conditions générales à tout moment, sans notification préalable.
                Ces modifications s’appliqueront immédiatement à toute
                utilisation du site web.
              </p>
              <p>
                Les règles et lignes de conduite supplémentaires seront réputées
                faire partie intégrante des présentes conditions générales. Il
                est donc conseillé à l’utilisateur de se référer régulièrement à
                la dernière version des présentes conditions, disponible en
                permanence à l’adresse suivante : www.riccirealestate.be.
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default DefinationSection;
