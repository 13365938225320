import React from "react";
import { Container, Grid } from "@mui/material";
// import "./access.scss";

const LastSection = () => {
  return (
    <Container>
      <Grid container justifyContent="center" className="pt-[40px]">
        <Grid container xs={12} lg={10} sm={12}>
          <Grid container>
            <h1 className="font-[Poppins] font-semibold text-[18px] lg:text-[26px] xs:text-[26px] pb-[10px]">
              Dispositions diverses
            </h1>
          </Grid>
          <Grid container>
            <h1 className="font-[Poppins] font-bold text-[16px] pb-[10px] pt-[10px]">
              Protection des données à caractère personnel
            </h1>
          </Grid>
          <Grid className="text-[#666666] text-[14px] font-[OpenSans] space-y-4">
            <p>
              Étant donné que certaines fonctionnalités du Site Web requièrent
              la communication préalable de données à caractère personnel, le
              prestataire s’engage à traiter et protéger les données à caractère
              personnel de façon loyale et transparente dans le respect de la
              loi.
            </p>
            <p>
              Pour de plus amples informations quant à la manière dont les
              données à caractère personnel sont traitées par le prestataire,
              l’utilisateur peut se référer à la Politique vie privée du
              prestataire qui est expliquée dans notre politique des cookies.
            </p>
          </Grid>
          <Grid container>
            <h1 className="font-[Poppins] font-bold text-[16px]  pb-[10px] pt-[20px]">
              Force majeure
            </h1>
          </Grid>
          <Grid className="text-[#666666] text-[14px] font-[OpenSans] space-y-4">
            <p>
              Le prestataire ne peut être tenu pour responsable, tant sur le
              plan contractuel qu’extracontractuel, en cas d’inexécution,
              temporaire ou définitive, de ses obligations lorsque cette
              inexécution résulte d’un cas de force majeure ou fortuit.
            </p>
            <p>
              Seront notamment considérés comme des cas de force majeure ou
              fortuits, les événements suivants : (1) la perte ou la destruction
              totale ou partielle du système informatique du prestataire ou de
              sa base de données lorsque l’un ou l’autre de ces événements ne
              peut raisonnablement pas lui être directement imputé et qu’il
              n’est pas démontré que le prestataire a omis de prendre les
              mesures raisonnables permettant de prévenir l’un ou l’autre de ces
              événements ; (2) les tremblements de terre ; (3) les incendies ;
              (4) les inondations ; (5) les épidémies ; (6) les actes de guerre
              ou de terrorisme ; (7) les grèves, déclarées ou non ; (8) les
              lock-out ; (9) les blocus ; (10) les insurrections et émeutes ;
              (11) un arrêt de fourniture d’énergie (telle que l’électricité) ;
              (12) une défaillance du réseau Internet ou du système de stockage
              des données ; (13) une défaillance du réseau de télécommunications
              ; (14) une perte de connectivité au réseau Internet ou au réseau
              de télécommunications dont dépend le prestataire ; (15) un fait ou
              une décision d’un tiers lorsque cette décision affecte la bonne
              exécution du présent contrat ; ou (16) toute autre cause échappant
              au contrôle raisonnable du prestataire.
            </p>
            <p>
              Si, en raison de circonstances indépendantes de la volonté du
              prestataire, l’exécution de ses obligations ne peut être
              poursuivie ou est simplement rendue plus onéreuse ou difficile, le
              prestataire et l’utilisateur s’engagent à négocier de bonne foi et
              loyalement une adaptation des conditions contractuelles dans un
              délai raisonnable en vue d’en restaurer l’équilibre. A défaut
              d’accord dans un délai raisonnable, chacune des parties pourra
              invoquer la résiliation de la relation contractuelle les unissant
              sans dédommagement ou indemnité de quelque nature que ce soit.
            </p>
          </Grid>
          <Grid container>
            <h1 className="font-[Poppins] font-bold text-[16px]  pb-[10px] pt-[20px]">
              Illégalité
            </h1>
          </Grid>
          <Grid className="text-[#666666] text-[14px] font-[OpenSans] space-y-4">
            <p>
              L’éventuelle illégalité ou nullité d’un article, d’un paragraphe
              ou d’une disposition (ou partie d’un article, d’un paragraphe ou
              d’une disposition) ne saurait affecter de quelque manière la
              légalité des autres articles, paragraphes ou dispositions de ces
              conditions générales, ni non plus le reste de cet article, de ce
              paragraphe ou de cette disposition, à moins d’intention contraire
              évidente dans le texte.
            </p>
            <p>
              Si une quelconque partie des présentes conditions générales est
              considérée comme étant complètement invalide, le prestataire la
              remplacera par une disposition qui s’approchera le plus près
              possible de l’effet économique de la disposition déclarée
              invalide.
            </p>
          </Grid>
          <Grid container>
            <h1 className="font-[Poppins] font-bold text-[16px]  pb-[10px] pt-[20px]">
              Titres
            </h1>
          </Grid>
          <Grid className="text-[#666666] text-[14px] font-[OpenSans] space-y-4">
            <p>
              Les titres utilisés dans les présentes conditions générales ne le
              sont qu’à des fins de référence et de commodité seulement. Ils
              n’affectent en rien la signification ou la portée des dispositions
              qu’ils désignent.
            </p>
          </Grid>
          <Grid container>
            <h1 className="font-[Poppins] font-bold text-[16px]  pb-[10px] pt-[20px]">
              Totalité et intégralité de l’entente
            </h1>
          </Grid>
          <Grid className="text-[#666666] text-[14px] font-[OpenSans] space-y-4">
            <p>
              Les présentes conditions générales ainsi que tout contrat
              représentent la totalité et l’intégralité de l’entente intervenue
              entre les parties.
            </p>
            <p>
              Aucune déclaration, représentation, promesse ou condition non
              contenue dans les présentes conditions générales ne peut et ne
              doit être admise pour contredire, modifier ou affecter de quelque
              façon que ce soit les termes de celles-ci.
            </p>
            <p>
              En outre, les présentes conditions générales ainsi que tout
              contrat se substituent à tout accord éventuellement intervenu
              antérieurement entre les parties et s’appliquent à tout nouvel
              accord.
            </p>
          </Grid>
          <Grid container>
            <h1 className="font-[Poppins] font-bold text-[16px]  pb-[10px] pt-[20px]">
              Droit applicable et juridiction compétente{" "}
            </h1>
          </Grid>
          <Grid className="text-[#666666] text-[14px] font-[OpenSans] space-y-4">
            <p>La présente convention est soumise au droit belge.</p>
            <p>
              En cas de litige relatif à la validité, à l’interprétation, à
              l’exécution ou à la rupture de la présente convention, les parties
              doivent mettre en œuvre leurs meilleurs efforts afin de trouver
              une solution amiable. A défaut d’une solution amiable, les
              tribunaux de l’arrondissement judiciaire de Bruxelles seront
              compétents.
            </p>
            <p>
              La présente version des Conditions Générales d’Utilisation entre
              en vigueur et a été mise à jour en date du 17/05/2022.
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LastSection;
