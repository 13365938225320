import React from "react";

const RadioButton = (props) => {
  const {
    label,
    name,
    defaultChecked,
    labelOne,
    labelTwo,
    value,
    handleCheckBox,
    handleCheckBoxNo,
  } = props;
  return (
    <div className="">
      <div class="">
        <label className="font-[OpenSans] font-bold text-[16px] text-[#222222]">
          {label}
          <div class="mt-[10px]">
            <label class="inline-flex items-center">
              <input
                type="radio"
                class="form-radio accent-[#003030] h-[18px] w-[18px]"
                name={name}
                value={value}
                checked={value === "yes"}
                onChange={() => {
                  handleCheckBox(name);
                }}
              />
              <span class="font-[OpenSans] font-bold text-[14px] text-[#949AA4] ml-2">
                {labelOne}
              </span>
            </label>
            <label class="inline-flex items-center ml-6">
              <input
                type="radio"
                class="form-radio accent-[#003030] h-[18px] w-[18px]"
                name={name}
                value={value}
                checked={value === "no"}
                onChange={() => {
                  handleCheckBoxNo(name);
                }}
              />
              <span class="font-[OpenSans] font-bold text-[14px] text-[#949AA4] ml-2">
                {labelTwo}
              </span>
            </label>
          </div>
        </label>
      </div>
    </div>
  );
};
export default RadioButton;
