import React from "react";
import { Grid } from "@mui/material";
import "./TopSection.scss";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import logo from "../../../assets/images/contact/full_grey.svg"
import location from "../../../assets/images/contact/location.svg"
import phone from "../../../assets/images/contact/phone.svg"
import mail from "../../../assets/images/contact/mail.svg"



const TopSection = () => {
    return (
        <>
            <div class="container mx-auto lg:w-full xs:w-full md:w-full">
                <div class="container mx-auto lg:w-4/5 xs:w-full md:w-full mt-[40px]">
                    <h1 className="font-[Poppins] font-bold  text-center">
                        <span className="text-[#222222] lg:text-[40px] md:text-[40px] xs:text-[40px]">Contact</span>
                    </h1>
                    <h2 className="pt-[10px] text-[#949AA4] font-[Poppins] font-semibold lg:text-[26px]  md:text-[16px] xs:text-[16px] text-center">
                        Nous sommes ravis de pouvoir vous aider et vous conseiller.
                    </h2>
                    {/* <h2 className="text-[#949AA4] font-[Poppins] font-semibold lg:text-[26px] md:text-[16px] xs:text-[16px] text-center">
                        Contactez-nous ou passez par notre agence
                    </h2> */}
                </div>
                <div class="container mx-auto lg:w-11/12 xs:w-full md:w-full mt-[30px]">
                    <img
                        src={require("../../../assets/images/contact/optimised/group.jpg")}
                        alt="img02"
                        className="w-full h-auto"
                    />
                </div>
               
                <div class="grid lg:col-12 md:lg:col-12 xs:lg:col-12">
                <div class="order-last lg:order-first xl:order-first md:order-first lg:col-start-4 lg:col-span-2 md:col-start-2 md:col-span-1 lg:max-w-[350px] md:max-w-[300px] xs:max-w-[250px] ">
                    <Card  className="lg:-mt-16 md:-mt-12 xs:-mt-0 logodiv borderRadius h-80">
                        <CardContent >
                            <img src={logo} className="center"></img>
                        </CardContent>
                    </Card>
                    </div>
                    <div class="lg:col-start-6 lg:col-span-4 md:col-start-3 md:col-span-2   lg:max-w-[350px] md:max-w-[300px] xs:max-w-[250px] ">
                    
                    <Card  className="font-[OpenSans]  lg:-mt-16 md:-mt-12 xs:-mt-0 borderRadius h-80">
                        <div className="flex items-center h-full w-full">
                        <div className='space-y-8 px-[40px]'>
                            <div className="flex items-center">
                            <img src={location} className="h-[32px]"></img>
                            <div>
                            <h1 sx={{ fontSize: 16 }}   className="font-[OpenSans] pl-[20px]  ">
                            Tomberg 212 <br/>1200 Woluwe-Saint-Lambert</h1>
                            {/* <h1 sx={{ fontSize: 16 }}   className="font-[OpenSans] pl-[20px]  ">
                            </h1> */}
                            </div>
                            </div>
                            
                            <div className="flex items-center">
                            <img src={phone} className="h-[32px]"></img>
                            <h1 sx={{ fontSize: 16 }}   className="font-[OpenSans] pl-[20px]  ">
                                02/210.00.40
                            </h1>
                            </div>
                            <div className="flex items-center">
                            <img src={mail} className="h-[32px]"></img>
                            <h1 sx={{ fontSize: 16 }}   className="font-[OpenSans] pl-[20px]  ">
                                hello@riccirealestate.be
                            </h1>
                            </div>

                        </div>
                        </div>
                    </Card>
                    </div>
                </div>
            </div>
            

        </>
    );
};

export default TopSection;
