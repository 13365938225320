/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Grid, Container } from "@mui/material";
import TableComponent from "../../components/table/table";
import Mail from "../../assets/icons/mail.svg";

const CookiesPage = () => {
  return (
    <Grid container justifyContent="center">
      <Grid
        container
        justifyContent="center"
        className="bg-[#F1F4F6] py-[40px]"
      >
        <h1 className="font-[Poppins] text-[30px] xs:text-[40px] lg:text-[40px] text-center">
          <span className="text-[#222222] font-bold">Politique des </span>
          <span className="text-[#222222]">Cookies</span>
        </h1>
      </Grid>
      <Container>
        <Grid container justifyContent="center" className="py-[40px]">
          <Grid container xs={12} lg={10} sm={12}>
            <h1 className="font-[Poppins] font-semibold text-[18px] lg:text-[26px] xs:text-[26px] ">
              Objet
            </h1>
            <div className="text-[#666666] text-[14px] font-[OpenSans]">
              <p className="pt-[10px]">
                La présente Politique est établie par MGB Groupe srl, société
                éditrice du site internet https://riccirealestate.be (ci-après
                le « site web »), dont le siège social est situé rue Kelle 123 à
                1150 Woluwe-Saint-Pierre (boîte 4) &nbsp;sous le numéro
                d’entreprise BE 0839.995.848 (ci-après dénommée « Ricci Real
                Estate »).
              </p>
              <p className="pt-[10px]">
                Ricci Real Estate s’efforce de vous offrir le meilleur service
                possible. Nous souhaitons dès lors préserver la confiance que
                vous nous portez en vous informant de notre politique
                d’utilisation et de stockage des cookies depuis notre site web
                vers votre terminal (ordinateur, smartphone, tablette tactile,
                télévision interactive, etc.)
              </p>
            </div>
            <h1 className="font-[Poppins] font-semibold text-[18px] lg:text-[26px] xs:text-[26px] pt-[30px]">
              Qu’est ce qu’un cookie ?
            </h1>
            <div className="text-[#666666] text-[14px] font-[OpenSans]">
              <p className="pt-[10px]">
                Un cookie est un petit fichier texte qui contient des fragments
                d’informations. Lorsque vous visitez des sites web, les
                informations récoltées par différents cookies sont conservées
                sur votre terminal (exemple : le choix de votre langue de
                préférence pour un site web). Lorsque vous visitez à nouveau ces
                sites web, ils vont chercher dans votre terminal les
                informations qu’ils y ont enregistré (exemple : configuration du
                site web par rapport à votre langue de préférence).
              </p>
              <p className="pt-[10px]">
                Il convient de faire la distinction entre deux types de cookies.
                D’une part, les «{" "}
                <span className="text-[#222222] font-bold">
                  {" "}
                  cookies d’origine{" "}
                </span>{" "}
                » qui sont mis en place par nous-même. D’autre part, les «{" "}
                <span className="text-[#222222] font-bold">
                  {" "}
                  cookies tiers{" "}
                </span>{" "}
                » qui sont mis en place par d’autres sociétés que nous (exemple
                : les cookies tiers sont souvent utilisés par des sociétés du
                secteur publicitaire pour cibler vos préférences et vous envoyer
                des publicités susceptibles de vous intéresser).
              </p>
              <p className="pt-[10px]">
                Les cookies ont généralement une date d'expiration. Certains
                cookies expirent lorsque vous fermez votre navigateur (ce que
                l'on appelle les «{" "}
                <span className="text-[#222222] font-bold">
                  {" "}
                  cookies de session{" "}
                </span>{" "}
                »), tandis que d'autres restent plus longtemps sur votre
                terminal, parfois même jusqu'à ce que vous les supprimiez
                manuellement (ce que l'on appelle les «{" "}
                <span className="text-[#222222] font-bold">
                  {" "}
                  cookies permanents{" "}
                </span>{" "}
                »).
              </p>
            </div>
            <h1 className="font-[Poppins] font-semibold text-[18px] lg:text-[26px] xs:text-[26px] pt-[30px]">
              Gestion des cookies
            </h1>
            <div className="text-[#666666] text-[14px] font-[OpenSans]">
              <p className="pt-[10px]">
                Certains cookies nécessitent votre consentement préalable et
                explicite. La bannière placée sur la page d’accueil du site web
                vous permet d’exprimer votre consentement à notre « Politique en
                matière de cookies ». Elle renvoie également à notre « Tableau
                des paramètres relatifs aux cookies », qui vous permet de
                configurer le placement, ou non, de cookies sur votre terminal.
              </p>
              <p className="pt-[10px]">
                Vous pouvez, à tout moment, accéder à notre « Tableau des
                paramètres relatifs aux cookies », en cliquant sur le lien
                hypertexte situé en bas de toutes les pages de notre site web,
                et modifier ou retirer votre consentement.
              </p>
              <p className="pt-[10px]">
                De plus, la plupart des navigateurs utilisent des cookies. Vous
                pouvez également supprimer ces cookies ou refuser leur
                installation à tout moment et gratuitement en modifiant les
                options de votre logiciel de navigation. La configuration de
                chaque navigateur est différente. Chaque procédure est décrite
                dans le menu d’aide de votre navigateur. Pour cela,
                veuillez-vous rendre sur les liens suivants :
              </p>
              <ul className="pt-[10px] pl-[20px] break-all">
                <li>
                  Firefox:
                  <a
                    href="https://support.mozilla.org/fr/kb/effacer-les-cookies-pour-supprimer-les-information"
                    className="text-[#222222] underline ml-[3px]"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://support.mozilla.org/fr/kb/effacer-les-cookies-pour-supprimer-les-information
                  </a>
                </li>
                <li>
                  Chrome:
                  <a
                    href="https://support.google.com/chrome/answer/95647?hl=fr"
                    className="text-[#222222] underline ml-[3px]"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://support.google.com/chrome/answer/95647?hl=fr
                  </a>
                </li>
                <li>
                  Safari:
                  <a
                    href="https://support.mozilla.org/fr/kb/effacer-les-cookies-pour-supprimer-les-information"
                    className="text-[#222222] underline ml-[3px]"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://support.mozilla.org/fr/kb/effacer-les-cookies-pour-supprimer-les-information
                  </a>
                </li>
                <li>
                  Internet Explorer:
                  <a
                    href="https://support.microsoft.com/fr-be/help/17442/windows-internet-explorer-delete-manage-cookies"
                    className="text-[#222222] underline ml-[3px]"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://support.microsoft.com/fr-be/help/17442/windows-internet-explorer-delete-manage-cookies
                  </a>
                </li>
              </ul>
              <p className="pt-[10px]">
                En refusant l’utilisation de cookies, vous pouvez accéder à
                notre site web. Toutefois, certaines fonctionnalités seront
                limitées, voire impossibles.
              </p>
            </div>
            <Grid container>
              <h1 className="font-[Poppins] font-semibold text-[18px] lg:text-[26px] xs:text-[26px] pt-[30px]">
                Types de cookies, finalités, dates d’expiration et obligation
                d’obtenir le consentement ou non
              </h1>
              <Grid container className="mt-[10px]">
                <TableComponent />
              </Grid>
            </Grid>
            <Grid container>
              <div>
                <h1 className="font-[Poppins] font-semibold text-[18px] lg:text-[26px] xs:text-[26px] pt-[30px]">
                  Vos droits sur le traitement de vos données à caractère
                  personnel
                </h1>
              </div>
              <div>
                <p className="text-[#666666] pt-[10px] text-[14px] font-[OpenSans]">
                  Certains cookies récoltent des données à caractère personnel.
                  Dans ce cadre, vous disposez de certains droits (droit
                  d’accès, un droit de rectification, un droit d’opposition, un
                  droit de retrait du consentement, etc.). Nous vous renvoyons
                  vers notre Politique vie privée qui reprend ces points avec
                  précision.
                </p>
              </div>
            </Grid>
            <div>
              <h1 className="font-[Poppins] font-semibold text-[18px] lg:text-[26px] xs:text-[26px] pt-[30px]">
                Nous contacter
              </h1>
              <div>
                <p className="text-[#666666] pt-[10px] text-[14px] font-[OpenSans]">
                  Droit applicable et juridictions compétentes
                </p>
              </div>
              <div className="pt-[30px]">
                <div className="flex items-center">
                  <div>
                    <img className="h-[26px]" src={Mail} alt="" />
                  </div>
                  <p className="pl-[20px]  text-[14px] font-[OpenSans] text-[#222222]">
                    hello@riccirealestate.be
                  </p>
                </div>
              </div>
              <div className="pt-[10px]">
                <div className="flex items-center">
                  <div>
                    <img className="h-[26px]" src={Mail} alt="" />
                  </div>
                  <p className="pl-[20px]  text-[14px] font-[OpenSans] ">
                    http://riccirealestate.be
                  </p>
                </div>
              </div>
              <div className="pt-[10px]">
                <div className="flex items-center">
                  <div>
                    <img className="h-[26px]" src={Mail} alt="" />
                  </div>
                  <p className="pl-[20px]  text-[14px] font-[OpenSans] text-[#222222]">
                  rue Kelle 123 bte 4, 1150 Woluwe-Saint-Pierre 
                  </p>
                </div>
              </div>
            </div>
            <Grid container>
              <h1 className="font-[Poppins] font-semibold text-[18px] lg:text-[26px] xs:text-[26px] pt-[30px]">
                Modification
              </h1>
              <div>
                <p className="text-[#666666] pt-[10px] text-[14px] font-[OpenSans]">
                  Nous nous réservons le droit de modifier à tout moment les
                  dispositions de la présente Politique. Nous publierons les
                  modifications directement sur notre site web.
                </p>
              </div>
            </Grid>
            <Grid container>
              <h1 className="font-[Poppins] font-semibold text-[18px] lg:text-[26px] xs:text-[26px] pt-[30px]">
                Droit applicable et juridictions compétentes
              </h1>
              <div className="text-[#666666] text-[14px] font-[OpenSans]">
                <p className="pt-[10px]">
                  La présente Politique est régie par le droit belge.
                </p>
                <p className="pt-[10px]">
                  Tout litige relatif à l’interprétation ou l’exécution de la
                  présente Politique sera soumis au droit belge et relèvera de
                  la compétence exclusive des tribunaux francophones de
                  l’arrondissement judiciaire de Bruxelles.
                </p>
                <p className="pt-[10px]">
                  La présente version de la Politique entre en vigueur et a été
                  mise à jour en date du (DATE).
                </p>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default CookiesPage;
