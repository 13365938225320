import axios from "axios";

const username = "nicolas@agifly.be";
const password = "Agifly2022";

// standalone token fetch api
export const getApiToken = () => {
    axios.post("https://api.whise.eu/token", {
        Username: username,
        Password: password
    }).then(response => {
        const token = response.data.token;
        localStorage.setItem('token', token);
    }).catch(error => {
        console.log(error)
    })
}

async function getApiTokenPromise() {
    const response = await axios.post("https://api.whise.eu/token", {
        Username: username,
        Password: password
    })
    return response;
}

async function getClientTokenPromise() {
    const token = localStorage.getItem('token');
    const response = await axios.post("https://api.whise.eu/v1/admin/clients/token", {
        ClientId: 8209,
        OfficeId: 10503
    }, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return response;
}

export const getEstatesList = (setData) => {
    const token = localStorage.getItem('token');
    const clientToken = localStorage.getItem('client-token');

    if (token) {
        // token exists
        if(clientToken) {
            // client token exists
            axios.post("https://api.whise.eu/v1/estates/list", {
                Filter: {
                    LanguageId: 'fr-BE'
                }
            }, {
                headers: {
                    Authorization: `Bearer ${clientToken}`
                }
            }).then(response => {
                setData(response?.data?.estates || [])
            }).catch(error => {
                console.log(error.response.status)
            })
        }
        else {
            // client token doesn't exist
            getClientTokenPromise().then(clientTokenResponse => {
                axios.post("https://api.whise.eu/v1/estates/list", {
                    Filter: {
                        LanguageId: 'fr-BE'
                    }
                }, {
                    headers: {
                        Authorization: `Bearer ${clientTokenResponse.data.token}`
                    }
                }).then(response => {
                    setData(response?.data?.estates || [])
                    localStorage.setItem('client-token', clientTokenResponse.data.token)
                }).catch(error => {
                    console.log(error.response.status)
                })
            })
        }
    }
    else {
        // token doesn't exist
        getApiTokenPromise().then(tokenResponse => {
            localStorage.setItem("token", tokenResponse.data.token)
            if(clientToken) {
                // client token exists
                axios.post("https://api.whise.eu/v1/estates/list", {
                    Filter: {
                        LanguageId: 'fr-BE'
                    }
                }, {
                    headers: {
                        Authorization: `Bearer ${clientToken}`
                    }
                }).then(response => {
                    setData(response?.data?.estates || [])
                }).catch(error => {
                    console.log(error)
                })
            }
            else {
                // client token doesn't exist
                getClientTokenPromise().then(clientTokenResponse => {
                    axios.post("https://api.whise.eu/v1/estates/list", {
                        Filter: {
                            LanguageId: 'fr-BE'
                        }
                    }, {
                        headers: {
                            Authorization: `Bearer ${tokenResponse.data.token}`
                        }
                    }).then(response => {
                        setData(response?.data?.estates || [])
                        localStorage.setItem('client-token', clientTokenResponse.data.token)
                    }).catch(error => {
                        console.log(error.response.status)
                    })
                })
            }
        }).catch(error => {
            console.log(error)
        })
    }
}

export async function getSubDetailsList(props) {
    
    const obj = [];
    const response = await axios.get(`https://api.whise.eu/reference?item=subdetailtype`)
        .then(res => {
            const arr = res.data;
            arr.forEach(element => {
                obj[element.id] = element;
            });
            props["LabelsInfo"] = obj;            
            return props;
        })

    return props;
}


    
