import React, { useState } from "react";
import { Grid, Container } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import BreakpointController from "../../components/breakpoint_controller/breakpoint_controller";
import { IoIosCloseCircleOutline } from "react-icons/io";

// image imports here
import Logo from "../../assets/images/logos/round_grey.svg";
import LogoFull from "../../assets/images/logos/full_green.svg";
import Mail from "../../assets/icons/mail.svg";
import Phone from "../../assets/icons/phone.svg";
import Facebook from "../../assets/icons/social/facebook.svg";
import Instagram from "../../assets/icons/social/instagram.svg";
import Linkedin from "../../assets/icons/social/Linkedin.svg";
import "./header.scss";

const MobileHeader = () => {
  const [menuState, setMenuState] = useState(false);

  const navigate = useNavigate();

  const handleMenuChange = () => {
    setMenuState(!menuState);
  };

  const goTo = (id) => {
    if (id === 1) {
      navigate("/result/?type=1");
    } else if (id === 2) {
      navigate("/result/?type=2");
    } else if (id === 3) {
      navigate("/gerer-mon-bien");
    } else if (id === 4) {
      navigate("/estimer-mon-bien");
    } else if (id === 5) {
      navigate("/contact");
    }
    setMenuState(false);
  };

  return (
    <Grid container>
      <div className="w-full h-[70px] bg-[#003030] flex justify-between items-center relative px-[20px] box-border relative">
        <div className="w-full h-[70px] absolute top-0 left-0 flex justify-center">
          <div className="h-[106px] w-[112px] rounded-[100%] bg-[#003030] flex justify-center items-center">
            <img
              src={Logo}
              alt="logo"
              className="w-[90px] h-[90px] object-contain cursor-pointer"
              onClick={() => navigate("/home")}
            />
          </div>
        </div>
        <div className="flex relative">
          <a href="tel:02/210.00.40">
            <img
              src={Phone}
              alt="phone"
              className="h-[24px] w-[24px] object-contain cursor-pointer"
            />
          </a>
          <a className="flex" href={`mailto:hello@riccirealestate.be`}>
            <img
              src={Mail}
              alt="mail"
              className="h-[24px] w-[24px] object-contain cursor-pointer ml-[27px]"
            />
          </a>
        </div>
        <div
          className="flex flex-col w-[25px] h-[25px] justify-between cursor-pointer relative"
          onClick={handleMenuChange}
        >
          <div className="w-full h-[3px] rounded-[2px] bg-white" />
          <div className="w-full h-[3px] rounded-[2px] bg-white" />
          <div className="w-full h-[3px] rounded-[2px] bg-white" />
        </div>
      </div>
      {/* side menu overlay */}
      <div
        className="w-full h-screen absolute top-0 left-0 flex box-border pr-[15px] animated z-[9]"
        style={{
          opacity: menuState ? 1 : 0,
          transform: menuState ? "translateX(0px)" : "translateX(-100vw)",
        }}
      >
        <div className="w-full h-full bg-white header-sidemenu-container flex flex-col box-border px-[29px] py-[10px] relative">
          <Grid container>
            <img
              src={LogoFull}
              alt="logo"
              className="h-[100px] object-contain"
            />
          </Grid>
          <Grid container>
            <p className="font-[Poppins] font-semibold text-[26px] text-[#222] mt-[20px]">
              The real estate<br></br>experience
            </p>
          </Grid>
          <Grid container>
            <div className="flex mt-[30px]">
              <a
                href="https://www.facebook.com/Ricci-Real-Estate-103613902359682"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Facebook}
                  alt="facebook"
                  className="h-[30px] w-[30px] object-contain cursor-pointer mx-[16px]"
                />
              </a>
              <a
                href="https://www.instagram.com/ricci.realestate/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Instagram}
                  alt="instagram"
                  className="h-[30px] w-[30px] object-contain cursor-pointer"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/ricci-real-estate-belgium/?viewAsMember=true"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Linkedin}
                  alt="Linkedin"
                  className="h-[30px] w-[30px] object-contain cursor-pointer ml-[13px]"
                />
              </a>
            </div>
          </Grid>
          <Grid container>
            <div className="flex flex-col mt-[62px]">
              <p
                className="font-[OpenSans] font-medium text-[16px] text-[#003030] uppercase"
                onClick={() => goTo(1)}
              >
                à vendre
              </p>
              <p
                className="font-[OpenSans] font-medium text-[16px] text-[#003030] uppercase mt-[20px]"
                onClick={() => goTo(2)}
              >
                à louer
              </p>
              <p
                className="font-[OpenSans] font-medium text-[16px] text-[#003030] uppercase mt-[20px]"
                onClick={() => goTo(3)}
              >
                gérér MON BIEN
              </p>
              <p
                className="font-[OpenSans] font-medium text-[16px] text-[#003030] uppercase mt-[20px]"
                onClick={() => goTo(4)}
              >
                ESTIMER MON BIEN
              </p>
            </div>
          </Grid>
          <Grid container>
            <button
              type="button"
              className="h-[43px] w-[139px] rounded-[5px] bg-[#003030] cursor-pointer flex justify-center 
              items-center mt-[51px]"
              onClick={() => goTo(5)}
            >
              <p className="font-[OpenSans] font-bold text-[14px] text-white/[0.9]">
                Contactez-nous
              </p>
            </button>
          </Grid>
          {/* close button */}
          <div
            className="absolute top-[13px] right-[13px]"
            onClick={handleMenuChange}
          >
            <IoIosCloseCircleOutline size="2em" color="#003030" />
          </div>
        </div>
      </div>
    </Grid>
  );
};

const DesktopHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Grid container>
      <div className="w-full h-[70px] bg-[#003030] flex justify-between items-center relative">
        <Container maxWidth="lg">
          <Grid container justifyContent="space-between" alignItems="center">
            <div className="w-full h-[70px] absolute top-0 left-0 flex justify-center">
              <div className="h-[106px] w-[112px] rounded-[100%] bg-[#003030] flex justify-center items-center">
                <img
                  src={Logo}
                  alt="logo"
                  className="w-[90px] h-[90px] object-contain cursor-pointer"
                  onClick={() => navigate("/home")}
                />
              </div>
            </div>
            <div className="flex relative">
              <p
                className={
                  location?.search === "?type=1"
                    ? `border-b-[1px] border-white font-[OpenSans] font-regular text-[16px] text-white mr-[70px] cursor-pointer`
                    : `font-[OpenSans] font-regular text-[16px] text-white mr-[70px] cursor-pointer`
                }
                onClick={() => navigate("/result/?type=1")}
              >
                A VENDRE
              </p>
              <p
                className={
                  location?.search === "?type=2"
                    ? `border-b-[1px] border-white font-[OpenSans] font-regular text-[16px] text-white cursor-pointer`
                    : `font-[OpenSans] font-regular text-[16px] text-white cursor-pointer`
                }
                onClick={() => navigate("/result/?type=2")}
              >
                A LOUER
              </p>
            </div>
            <div className="flex relative">
              <p
                className={
                  location?.pathname === "/gerer-mon-bien"
                    ? `border-b-[1px] border-white font-[OpenSans] font-regular text-[16px] text-white mr-[70px] cursor-pointer uppercase`
                    : `font-[OpenSans] font-regular text-[16px] text-white mr-[70px] cursor-pointer uppercase`
                }
                onClick={() => navigate("/gerer-mon-bien")}
              >
                gérér MON BIEN
              </p>
            
            <div className="flex relative">
              <p
                className={
                  location?.pathname == "/estimer-mon-bien"
                    ? `border-b-[1px] border-white font-[OpenSans] font-regular text-[16px] text-white mr-[70px] cursor-pointer uppercase`
                    : `font-[OpenSans] font-regular text-[16px] text-white mr-[70px] cursor-pointer uppercase`
                }
                onClick={() => navigate("/estimer-mon-bien")}
              >
                ESTIMER MON BIEN
              </p>
            </div>
            <div className="flex relative">
              <p
                className={
                  location?.pathname == "/contact"
                    ? ` border-b-[1px] border-white font-[OpenSans] font-regular text-[16px] text-white cursor-pointer`
                    : ` font-[OpenSans] font-regular text-[16px] text-white cursor-pointer`
                }
                onClick={() => navigate("/contact")}
              >
                CONTACT
              </p>
            </div>
            </div>
          </Grid>
        </Container>
      </div>
      <div className="w-full h-[35px] bg-white flex justify-between items-center">
        <Container maxWidth="lg">
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            className="relative"
          >
            <div className="flex">
              <div className="flex items-center">
                <img
                  src={Mail}
                  alt="mail"
                  className="h-[18px] w-[18px] object-contain mr-[10px]"
                />
                <p className="font-[OpenSans] font-regular text-[16px] text-[#949AA4] mr-[70px]">
                  hello@riccirealestate.be
                </p>
              </div>
              <div className="flex  items-center">
                <img
                  src={Phone}
                  alt="phone"
                  className="h-[18px] w-[18px] object-contain mr-[10px]"
                />
                <p className="font-[OpenSans] font-regular text-[16px] text-[#949AA4]">
                  02/210.00.40
                </p>
              </div>
            </div>
          </Grid>
        </Container>
      </div>
    </Grid>
  );
};

export default function Header() {
  return (
    <Grid container>
      <BreakpointController direction="up" breakpoint="sm">
        <MobileHeader />
      </BreakpointController>
      <BreakpointController direction="down" breakpoint="sm">
        <DesktopHeader />
      </BreakpointController>
    </Grid>
  );
}
