import * as React from "react";
import ManagePageTop from "../../components/manage_property/manage_property";
import Banner from "../../components/home_page/banner/banner";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { carusalData, responsive } from "../../utils/constants";
import { Grid } from "@mui/material";

const ManagePage = () => {
  return (
    <>
      <ManagePageTop />
      <div className="hidden md:block lg:block xl:block">
        <Grid>
          <Carousel
            responsive={responsive}
            swipeable={true}
            draggable={true}
            arrows={false}
            infinite={true}
            autoPlay
            keyBoardControl={true}
            transitionDuration={5000}
          >
            {carusalData?.map((value) => (
              <Banner data={value} />
            ))}
          </Carousel>
        </Grid>
      </div>
    </>
  );
};

export default ManagePage;
