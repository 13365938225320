import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PropTypes from "prop-types";
import ScrollToTopWrapper from "../config/scrollToTop";
import { getEstatesList } from "../api/routes";

// page imports here
import ComingSoon from "./coming_soon/coming_soon";
import HomePage from "./home_page/home_page";
import Header from "./header/header";
import Footer from "./footer/footer";
import Contact from "./contact/contact";
import CookiesPage from "./cookies_page/cookies_page";
import CookieBanner from "../components/cookies_banner/cookie_banner";
import EstimatePage from "./estimate_page/estimate_page";
import ManagePage from "./manage_page/manage_page";
import ResultPage from "./result_page/result_page";
import DetailPage from "./detailPage/detailPage";
import DetailPageModal from "../components/detail_page_modal/detail_modal";
import RegisterModal from "../components/modal/modal";
import TermAndCondition from "./term_and_condition";

import "./core.scss";

const ComponentWrapper = (props) => {
  const { disableHeader, disableFooter, alternateFooter } = props;
  return (
    <>
      {!disableHeader && <Header />}
      {props.children}
      <CookieBanner />

      {!disableFooter && <Footer hasFields={alternateFooter} />}
    </>
  );
};

export default function Core() {
  const [show, setShow] = useState(false);
  return (
    <Router>
      <ScrollToTopWrapper>
        <Routes>
          <Route path="/" element={<ComponentWrapper disableHeader alternateFooter>
                  <HomePage show={show} setShow={setShow} />
                  <RegisterModal show={show} setShow={setShow} />
                </ComponentWrapper>} />
          <>
            <Route
              path="/home"
              element={
                <ComponentWrapper disableHeader alternateFooter>
                  <HomePage show={show} setShow={setShow} />
                  <RegisterModal show={show} setShow={setShow} />
                </ComponentWrapper>
              }
            />
            <Route
              path="/estimer-mon-bien"
              element={
                <ComponentWrapper>
                  <EstimatePage />
                </ComponentWrapper>
              }
            />
            <Route
              path="/gerer-mon-bien"
              element={
                <ComponentWrapper>
                  <ManagePage />
                </ComponentWrapper>
              }
            />
            <Route
              path="/contact"
              element={
                <ComponentWrapper alternateFooter>
                  <Contact />
                </ComponentWrapper>
              }
            />
            <Route
              path="/result"
              element={
                <ComponentWrapper>
                  <RegisterModal show={show} setShow={setShow} />

                  <ResultPage show={show} setShow={setShow}/>
                </ComponentWrapper>
              }
            />
            <Route
              path="/detail-page/:stateId"
              element={
                <ComponentWrapper>
                  <DetailPage />
                </ComponentWrapper>
              }
            />
            <Route
              path="/privacy-page"
              element={
                <ComponentWrapper>
                  <CookiesPage />
                </ComponentWrapper>
              }
            />
            <Route
              path="/condition-dutilisations"
              element={
                <ComponentWrapper>
                  <TermAndCondition />
                </ComponentWrapper>
              }
            />
          </>
        </Routes>
      </ScrollToTopWrapper>
    </Router>
  );
}

ComponentWrapper.propTypes = {
  disableHeader: PropTypes.bool,
  disableFooter: PropTypes.bool,
  alternateFooter: PropTypes.bool,
};

ComponentWrapper.defaultProps = {
  disableHeader: false,
  disableFooter: false,
  alternateFooter: false,
};
