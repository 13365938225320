import React from "react";
import { Container, Grid } from "@mui/material";
import "./access.scss";

const AccessSection = () => {
  return (
    <Container>
      <Grid container justifyContent="center" className="pt-[40px]">
        <Grid container xs={12} lg={10} sm={12}>
          <h1 className="font-[Poppins] font-semibold text-[18px] lg:text-[26px] xs:text-[26px] pb-[10px]">
            Accès & utilisation du Site Web et de son contenu
          </h1>
          <Grid className="text-[#666666] text-[14px] font-[OpenSans] space-y-4">
            <p>
              Le Site Web peut être consulté par des ordinateurs sur lesquels un
              software actuel est installé (navigateur, système opérationnel,
              etc.). Le prestataire ne garantit aucune compatibilité et ne peut
              nullement être tenu pour responsable si l’utilisateur ne peut
              consulter et/ou utiliser tout ou partie du Site Web ou de son
              contenu, quelle qu’en soit la raison.
            </p>
            <p>
              Par conséquent, il appartient à l’utilisateur de se doter des
              moyens informatiques, et éventuellement humains, nécessaires afin
              d’assurer sa connectivité avec le Site Web.
            </p>
            <p>
              Dans le cadre de l’accès de l’utilisateur au Site Web ou à son
              contenu, celui-ci s’interdit expressément, de quelque manière que
              ce soit et quels que soient les moyens techniques utilisés, de :
            </p>
            <ul className="list-style-for-term">
              <li>
                tenter de se procurer l’accès à des parties du site web qui ne
                sont pas placées publiquement en ligne ;
              </li>
              <li>
                poser un quelconque acte qui, à un quelconque moment, pourrait
                compromettre le bon fonctionnement du Site Web, de quelque
                manière que ce soit ;
              </li>
              <li>
                utiliser quelque système (automatique) que ce soit, tel que,
                sans que cette liste soit limitative, les « robots », « spiders
                », « offlines readers », etc., ayant pour but de créer : (i) des
                attaques par déni de service (notamment, mais sans s’y limiter,
                les DoS réseau, Dos applicatives, DDoS réseau, en ce compris les
                DrDOS, ou encore les DDoS applicatives) ; (ii) des messages
                permettant d’influencer les questions et requêtes, les réponses
                et participations à des concours, les votes, ou encore sur toute
                interaction avec un autre utilisateur du Site Web, même lorsque
                l’utilisateur réagit à une requête formulée sur le Site Web (par
                exemple une participation à un concours) ;
              </li>
              <li>
                afficher, télécharger, envoyer, transmettre par e-mail ou de
                toute autre manière tout contenu qui soit illégal, nuisible,
                menaçant, constitutif de harcèlement, abusif, diffamatoire,
                vulgaire, obscène, menaçant pour la vie privée d’autrui,
                haineux, raciste, ou autrement répréhensible ;
              </li>
              <li>
                consulter, afficher, télécharger, envoyer, transmettre, tout
                contenu qui serait contraire aux lois internationales en vigueur
                ;
              </li>
              <li>
                tenter d’induire en erreur d’autres utilisateurs en usurpant le
                nom ou la dénomination sociale d’autres personnes ;
              </li>
              <li>
                télécharger, afficher, envoyer, transmettre par e-mail ou de
                toute autre manière tout contenu violent, tout brevet, marque
                déposée, secret de fabrication, droit de propriété
                intellectuelle ou tout autre droit de propriété appartenant à
                autrui ;
              </li>
              <li>
                télécharger, afficher, transmettre par e-mail ou de toute autre
                manière tout contenu comprenant des virus informatiques ou tout
                autre code, dossier ou programme conçus pour interrompre,
                détruire, entraver, perturber, ou limiter la fonctionnalité de
                tout logiciel, ordinateur, service, serveur, réseau ou outil de
                télécommunication sans que cette énumération soit limitative ;
              </li>
              <li>
                commettre toute action ayant un effet perturbateur entravant la
                capacité d’autres utilisateurs d’accéder au Site Web ;
              </li>
              <li>
                refuser de se conformer aux conditions requises, aux procédures,
                aux règles générales ou aux dispositions réglementaires
                applicables aux réseaux connectés au Site Web ;
              </li>
              <li>
                harceler de quelque manière que ce soit un ou plusieurs autres
                utilisateurs du Site Web ou de son contenu ;
              </li>
              <li>
                collecter et stocker des données personnelles afférentes aux
                autres utilisateurs.
              </li>
            </ul>
            <Grid className="text-[#666666] text-[14px] font-[OpenSans] space-y-4">
              <p>
                L’utilisateur s’engage à prendre toutes les précautions
                raisonnables et nécessaires afin d’éviter que son équipement ou
                ses données soient atteints par des virus, des bugs, des chevaux
                de Troie, ou tout autre programme informatique malveillant, de
                quelque nature que ce soit.
              </p>
              <p>
                Pour l'accès ou l'utilisation de certaines parties du Site Web,
                l’utilisateur pourrait devoir s’enregistrer ou devenir membre.
                Dans ce cas, lors de son enregistrement, l’utilisateur s’engage
                à fournir des données exactes, à jour, et complètes, et d’en
                assurer la mise à jour régulière. Dans le cas contraire, le
                prestataire sera en droit de suspendre ou de résilier le compte
                de l’utilisateur, ou de lui refuser l’accès à tout ou partie du
                Site Web ou de son contenu.
              </p>
              <p>
                Le cas échéant, l’utilisateur s’engage à garder son login et son
                mot de passe secrets et de ne pas les partager avec des tiers.
                L’utilisateur reste seul responsable de la confidentialité de
                son mot de passe et de toute utilisation qui pourrait survenir à
                son insu. En cas de doute sur le caractère confidentiel du mot
                de passe, il appartient à l’utilisateur de le modifier
                immédiatement ou d’en aviser dans les plus brefs délais le
                prestataire par écrit.
              </p>

              <p>
                L’utilisateur accepte que les fonctionnalités proposées au
                travers du Site Web soient susceptibles d’évoluer. Ainsi,
                certaines seront supprimées et d’autres ajoutées, sans que
                l’utilisateur ne puisse considérer que l’accès à une
                fonctionnalité particulière ne constitue un droit acquis. De
                même, le prestataire décidera seul de l’opportunité d’inclure ou
                de supprimer tout contenu présenté sur le Site Web.
              </p>
              <p>
                Le prestataire se réserve le droit, à tout moment et pour
                quelque motif que ce soit, de modifier ou d’interrompre
                temporairement ou de manière permanente tout ou partie de
                l’accès au Site Web et ce sans devoir en informer préalablement
                les utilisateurs.
              </p>
              <p>
                Il en sera ainsi par exemple en cas de maintenance du Site Web
                ou de modification importante du contenu et/ou des
                fonctionnalités proposés.
              </p>
              <p>
                Il en sera également ainsi dans le cas où le prestataire peut
                légitimement croire que l’utilisateur a violé, ou agi en
                contradiction avec, les présentes conditions générales ou toute
                autre prescription légale en vigueur au moment de la violation.
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AccessSection;
