import React from "react";
import DefinationSection from "./defination/defination";
import AccessSection from "./access/access";
import LicenceSection from "./licence/licence";
import PrioritySection from "./priorities/priorities";
import ResponsibilitySection from "./responsibility/responsibility";
import LastSection from "./last_section/last_section";

const TermAndCondition = () => {
  return (
    <div className="pb-[40px]">
      <DefinationSection />
      <AccessSection />
      <LicenceSection />
      <PrioritySection />
      <ResponsibilitySection />
      <LastSection />
    </div>
  );
};

export default TermAndCondition;
