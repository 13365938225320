import React from "react";
import { Grid, Container } from "@mui/material";
import "./about.scss";

const AboutHome = () => {
  return (
    <Container>
      <Grid
        justifyContent="center"
        className="my-[60px] about-home-main flex justify-center"
      >
        <Grid container xs={12} lg={10} sm={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} lg={5} sm={5}>
              <div>
                <h1 className="font-[Poppins] font-bold text-[30px] ">
                  <span className="heading-linear">A propos</span>
                  <span className="text-[#222222]"> de nous</span>
                </h1>
                <p className="pt-[30px] text-[#666666] font-[OpenSans]">
                  Passionné du secteur de l’immobilier depuis plus d’une dizaine
                  d’années, nous vous accompagnons tout au long des étapes de
                  vos projets.
                </p>
                <p className="pt-[15px] text-[#666666] font-[OpenSans]">
                  Notre expertise immobilière ainsi que notre service sur mesure
                  seront mis à votre profit afin de vous assurer le plus haut
                  degré d’efficacité et de professionnalisme.
                </p>
                <p className="pt-[30px] text-[#666666] font-[OpenSans]">
                  Votre confiance et votre fidélité sont nos plus grandes
                  satisfactions.
                </p>
                <p className="pt-[30px] text-[#666666] font-[OpenSans]">
                  Que ce soit pour la vente, la location, l'estimation et la
                  gestion de patrimoine, notre équipe vous conseille sur toutes
                  matières immobilières.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} lg={5} sm={5} className="py-[20px]">
              <div>
                <img
                  src={require("../../../assets/images/homepage/optimised/logomaison.gif")}
                  alt=""
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutHome;
