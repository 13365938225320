import ImageOne from "../../assets/icons/epc/a.svg";
import ImageTwo from "../../assets/icons/epc/a+.svg";
import ImageThree from "../../assets/icons/epc/g.svg";
import Image from "../../assets//images//homepage/banner2.png";
export const cardData = [
  {
    id: 1,
    title: "Villa",
    subTitle: "1150 Woluwe-Saint-Pierre",
    amount: "940.000 €",
    refNo: " 00245798",
    badge: "Nouveau",
    label: "A Vendre",
    image: Image,
    footer: {
      itemOne: "4ch.",
      itemTwo: "2 Sdb ",
      itemThree: "195 m²",
      image: ImageOne,
    },
  },
  {
    id: 2,
    title: "Villa",
    subTitle: "1150 Woluwe-Saint-Pierre",
    amount: "940.000 €",
    refNo: " 00245798",
    badge: "Nouveau",
    label: "A Vendre",
    image: Image,
    footer: {
      itemOne: "4ch.",
      itemTwo: "2 Sdb ",
      itemThree: "195 m²",
      image: ImageThree,
    },
  },
  {
    id: 3,
    title: "Villa",
    subTitle: "1150 Woluwe-Saint-Pierre",
    amount: "940.000 €",
    refNo: " 00245798",
    badge: "",
    label: "A Vendre",
    image: Image,
    footer: {
      itemOne: "4ch.",
      itemTwo: "2 Sdb ",
      itemThree: "195 m²",
      image: ImageTwo,
    },
  },
  {
    id: 4,
    title: "Villa",
    subTitle: "1150 Woluwe-Saint-Pierre",
    amount: "940.000 €",
    refNo: " 00245798",
    badge: "Nouveau",
    label: "A Vendre",
    image: Image,
    footer: {
      itemOne: "4ch.",
      itemTwo: "2 Sdb ",
      itemThree: "195 m²",
      image: ImageThree,
    },
  },
  {
    id: 5,
    title: "Villa",
    subTitle: "1150 Woluwe-Saint-Pierre",
    amount: "940.000 €",
    refNo: " 00245798",
    badge: "",
    label: "A Vendre",
    image: Image,
    footer: {
      itemOne: "4ch.",
      itemTwo: "2 Sdb ",
      itemThree: "195 m²",
      image: ImageOne,
    },
  },
  {
    id: 6,
    title: "Villa",
    subTitle: "1150 Woluwe-Saint-Pierre",
    amount: "940.000 €",
    refNo: " 00245798",
    badge: "Nouveau",
    label: "A Vendre",
    image: Image,
    footer: {
      itemOne: "4ch.",
      itemTwo: "2 Sdb ",
      itemThree: "195 m²",
      image: ImageOne,
    },
  },
  {
    id: 7,
    title: "Villa",
    subTitle: "1150 Woluwe-Saint-Pierre",
    amount: "940.000 €",
    refNo: " 00245798",
    badge: "Nouveau",
    image: Image,
    label: "A Vendre",
    footer: {
      itemOne: "4ch.",
      itemTwo: "2 Sdb ",
      itemThree: "195 m²",
      image: ImageTwo,
    },
  },
  {
    id: 8,
    title: "Villa",
    subTitle: "1150 Woluwe-Saint-Pierre",
    amount: "940.000 €",
    refNo: " 00245798",
    badge: "Nouveau",
    label: "A Vendre",
    image: Image,
    footer: {
      itemOne: "4ch.",
      itemTwo: "2 Sdb ",
      itemThree: "195 m²",
      image: ImageThree,
    },
  },
  {
    id: 9,
    title: "Villa",
    subTitle: "1150 Woluwe-Saint-Pierre",
    amount: "940.000 €",
    refNo: " 00245798",
    badge: "Nouveau",
    label: "A Vendre",
    image: Image,
    footer: {
      itemOne: "4ch.",
      itemTwo: "2 Sdb ",
      itemThree: "195 m²",
      image: ImageTwo,
    },
  },
  {
    id: 10,
    title: "Villa",
    subTitle: "1150 Woluwe-Saint-Pierre",
    amount: "940.000 €",
    refNo: " 00245798",
    badge: "Nouveau",
    label: "A Vendre",
    image: Image,
    footer: {
      itemOne: "4ch.",
      itemTwo: "2 Sdb ",
      itemThree: "195 m²",
      image: ImageOne,
    },
  },
];

export const cardFourData = {
  titleBold: "Estimez",
  titleNormal: " mon bien",
  paragraph:
    " Vous souhaitez une estimation professionnelle et réaliste de votre bien immobilier ? Obtenez le conseil d’experts en faisant appel aux services de RICCI Real Estat",
  buttonText: " Estimer mon bien",
};

export const cardEightData = {
  titleBold: "Ne manquez pas ",
  titleNormal: "une occassion",
  paragraph:
    "Inscrivez-vous dans notre listing client! Cela nous permettra de vous contacter proactivement dès que nous rentrons des biens correspondant à vos critères ! ",
  buttonText: "S’inscrire dans la liste",
};
