import React from "react";
import { Container, Grid } from "@mui/material";
// import "./access.scss";

const ResponsibilitySection = () => {
  return (
    <Container>
      <Grid container justifyContent="center" className="pt-[40px]">
        <Grid container xs={12} lg={10} sm={12}>
          <Grid container>
            <h1 className="font-[Poppins] font-semibold text-[18px] lg:text-[26px] xs:text-[26px] pb-[10px]">
              Responsabilités
            </h1>
          </Grid>
          <Grid container>
            <h1 className="font-[Poppins] font-bold text-[16px]  pb-[10px] pt-[10px]">
              Responsabilité de l’utilisateur
            </h1>
          </Grid>
          <Grid className="text-[#666666] text-[14px] font-[OpenSans] space-y-4">
            <p>
              La consultation et l’utilisation du site web, ainsi que le
              téléchargement de fichiers, de quelque nature que ce soit et avec
              quelques moyens techniques que ce soit, du Site Web et de son
              contenu, se déroulent toujours sous la responsabilité de
              l’utilisateur, en ce compris vis-à-vis des tiers.
            </p>
            <p>
              Chaque utilisateur est lui-même responsable de son enregistrement
              et/ou de son affiliation, ainsi que des éventuels abus ou dégâts
              qui en découleraient. Le prestataire ne peut être tenu pour
              responsable de quelque utilisation impropre de l'enregistrement ou
              de l'affiliation, du login et/ou du mot de passe que ce soit.
            </p>
            <p>
              L’utilisateur accepte également d’être responsable, vis-à-vis de
              toute personne, et plus particulièrement des personnes
              représentées, de quelque manière que ce soit, de tout contenu
              qu’il publie soit sur le Site Web, soit par l’intermédiaire du
              Site Web.
            </p>
            <p>
              Le Site Web peut contenir des liens vers d’autres sites web sur
              lesquels le prestataire n’exerce aucun contrôle sur le plan
              technique ni sur celui du contenu. L’utilisateur reste seul
              responsable de la décision d’activer ces liens. Le prestataire ne
              garantit par conséquent en aucun cas le caractère exact et complet
              du contenu, l’accès et la disponibilité de ces autres sites web,
              les liens externes auxquels ils renvoient, ni les conséquences
              pouvant résulter de la consultation et/ou de l’utilisation, de
              quelque manière que ce soit, de ces sites. Il revient dès lors à
              l’utilisateur de déterminer lui-même s’il est opportun de visiter
              ces sites.
            </p>
            <p>
              Si l’utilisateur place un message, de quelque nature qu’il soit,
              ou toute autre forme d’information, de données et/ou d’avis sur le
              Site Web, celui-ci s’engage à utiliser exclusivement des
              informations (images, photos) qui n’entrent pas en conflit avec
              des droits intellectuels et/ou industriels de tiers (droit
              d’auteur, droit d’images, …), ni avec les normes et les bonnes
              mœurs, ni avec une quelconque disposition légale. A ce titre,
              l’utilisateur garantit expressément le prestataire contre toute
              plainte ou action intentée par des tiers fondée sur le contenu
              qu’il aurait placé sur le Site Web.
            </p>
          </Grid>
          <h1 className="font-[Poppins] font-bold text-[16px]  pb-[10px] pt-[20px]">
            Responsabilité du prestataire
          </h1>
          <Grid className="text-[#666666] text-[14px] font-[OpenSans] space-y-4">
            <p>
              Le prestataire est tenu à une obligation de moyen. Le prestataire
              ne sera en aucun cas tenu pour responsable des éventuels dommages
              directs ou indirects que l’utilisateur encoure lors de son
              utilisation du Site Web, des sites qui y sont liés, et/ou du
              contenu mis à sa disposition.
            </p>
            <p>
              Le prestataire fait ses meilleurs efforts afin de veiller à ce que
              les données et documents qui font partie du Site Web soient
              complets, exacts et actuels. Les fautes et/ou les lacunes et/ou
              les données dépassées ne peuvent jamais être exclues et, par
              conséquent, le prestataire ne donne aucune garantie à cet égard.
            </p>
            <p>
              De même, le prestataire n’est tenu que de son dol ou de sa faute
              lourde. Il n’est pas responsable du dol ou de la faute lourde de
              ses préposés, commettants et en règle générale de ses agents
              d’exécution.
            </p>
            <p>
              Le prestataire fait ses meilleurs efforts afin de veiller à ce que
              le Site Web reste accessible, à chaque instant, pour un nombre
              normal d’utilisateurs, mais ne pourra être tenu pour responsable
              pour tout dommage direct ou indirect lié à une modification,
              suspension ou interruption de l’accès au Site Web, pour quelque
              cause que ce soit.
            </p>
            <p>
              Le prestataire n’est pas non plus responsable des contacts et
              relations entre les utilisateurs du Site Web.
            </p>
            <p>
              Le prestataire ne garantit en outre pas la compatibilité des
              fichiers qui font partie du, ou qui figurent sur le Site Web avec
              l’équipement de l’utilisateur, ni l’accessibilité à ces éléments.
            </p>
            <p>
              L’utilisateur tiendra également le prestataire indemne de toute
              réclamation, dans l’un ou l’autre des cas suivants :
            </p>
            <ul className="list-style-for-term">
              <li>
                perte d’occasions ou de revenus de quelque nature que ce soit en
                raison du fonctionnement ou du non fonctionnement, ou de
                l’utilisation ou de l’absence d’utilisation du Site Web, ou du
                contenu s’y trouvant ou devant s’y trouver ;
              </li>
              <li>
                intrusion illégale ou non autorisée de tout tiers dans le
                serveur web ou sur le Site Web du prestataire;
              </li>
              <li>
                introduction d’un virus informatique dans le serveur web ou sur
                le Site Web ;
              </li>
              <li>encombrement temporaire de la bande passante ;</li>
              <li>
                interruption du service de connexion internet pour une cause
                hors du contrôle du prestataire.
              </li>
            </ul>
            <p>L’utilisateur reconnaît et accepte :</p>
            <ul className="list-style-for-term">
              <li>
                les restrictions et les risques liés à l’utilisation d’internet
                ou de tout autre moyen par lequel le Site Web est actuellement
                ou sera à l’avenir mis à disposition ;
              </li>
              <li>
                les risques de stockage et de transmission d’informations par la
                voie électronique ou numérique ;
              </li>
              <li>
                le fait que le prestataire ne peut être tenu pour responsable de
                tout dommage causé par l’utilisation du Site Web (ainsi que de
                tout ou partie de son contenu) ou d’internet, suite aux risques
                précités ;
              </li>
              <li>
                le fait que les communications électroniques échangées et les
                backups réalisés par le prestataire puissent servir de preuve.
              </li>
            </ul>
            <p>
              Bien que le prestataire fasse ses meilleurs efforts afin de
              maintenir le Site Web libre de bugs, virus, trojan horse et
              spyware, ceux-ci ne peuvent être exclus. Le prestataire ne peut
              nullement être tenu pour responsable pour tout préjudice et/ou
              perte qui en résulteraient, notamment eu égard aux données des
              utilisateurs. Il est dès lors fortement conseillé aux utilisateurs
              d’installer les firewall, anti-virus et autres softwares de
              protection nécessaires, afin d’empêcher les éventuels
              endommagements de leurs ordinateurs, et d’être prudent quant à la
              communication de données personnelles.
            </p>
            <p>
              En ce qui concerne les messages provenant de tiers, le prestataire
              ne peut d’aucune manière être tenu pour responsable d’un
              quelconque préjudice qui en découlerait, ni d’éventuelles fautes
              dans leurs contenus. Tous les textes, données, photos, vidéos,
              messages ou autres matériels placés dans ces messages tombent sous
              la responsabilité exclusive de la personne qui les a postés.
            </p>
            <p>
              Les publicités qui sont insérées sur le Site Web sont toujours la
              tâche de tierces parties. Le prestataire ne peut en aucun cas être
              tenu pour responsable de la légalité, l’exactitude, l’offre, le
              contenu, le bon fonctionnement et/ou la qualité des biens et/ou
              services offerts dans le cadre de ces publicités.
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ResponsibilitySection;
