import { Grid } from "@mui/material";
import "../manage_property.scss";
import React, { useState,useEffect } from "react";
import Input from "../../input/input";
import { Formik } from "formik";
import * as Yup from "yup";

const SecondFormMobile = (props) => {
  const { open, setState, state, handleSubmitForm} = props;
  useEffect(()=>{
    setState((st) => ({
      ...st,
      ['message']: `Bonjour,\n\n  Je souhaiterai avoir plus d’informations\n  concernant les services de gestion\n  relatifs à mon bien.\n  \n  Merci d'avance,\n`,
    }));
  },[])
  
  const validationSchema = Yup.object().shape({
    nom: Yup.string().required("* Ce champ est obligatoire"),
    telephone: Yup.string().required("* Ce champ est obligatoire"),
    prenom: Yup.string().required("* Ce champ est obligatoire"),
    email: Yup.string()
      .email("*Must be a valid email address")
      .required("*Ce champ est obligatoire"),
    message: Yup.string().required("* Ce champ est obligatoire"),
  });
  if(!state?.message)
  return <></>
  return (
    <div class="w-full bg-white rounded-[15px] card-shadow mt-[30px]">
      <div class="flex items-center bg-gray-50 rounded-[15px]">
        <div class="flex-1 h-full bg-white rounded-[15px] ">
          <div class="flex flex-col md:flex-row">
            <div class="h-32 md:h-auto md:w-1/2 hidden md:block lg:block xl:block">
              <img
                class="object-cover w-full h-full rounded-tl-[10px] rounded-bl-[10px]"
                src={require("../../../assets/images/general/optimised/form2.jpg")}
                alt="img"
              />
            </div>
            <div class="sm:px-[20px] lg:px-[20px] md:px-[20px] xl:px-[20px] px-[10px] py-[20px] md:w-1/2">
              <Formik
                initialValues={{
                  nom: "",
                  prenom: "",
                  email: "",
                  message: state.message,
                  telephone: "",
                }}
                validateOnChange={false} // this one
                validateOnBlur={false}
                onSubmit={(values, { setSubmitting, resetForm },validateForm) => {
                  //handleSubmitForm(values, resetForm);onSubmit
                }}
                // Hooks up our validationSchema to Formik
                validationSchema={validationSchema}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    resetForm,
                    validateForm
                  } = props;
                  return (
                    <form
                      class="w-full"
                      id="myForm"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validateForm().then((errors) => {
                        if(Object.keys(errors).length === 0){
                        handleSubmitForm(values, resetForm)
                        }
                        }
                        )}}
                    >
                      <h1 className="font-[OpenSans] font-bold text-[16px] text-[#222222]">
                        Informations personnelles
                      </h1>
                      <Grid className="">
                        <Grid container marginTop={0} spacing={2}>
                          <Grid item lg={6} xs={12}>
                            <Input
                              bg="#F1F4F6"
                              placeholder="Nom"
                              name="nom"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.nom}
                            />
                            <div className="font-[OpenSans] text-[11px] lg:text-[14px] md:text-[14px] sm:text-[14px] font-bold text-[#ff0000b0] pt-[5px]">
                              {errors.nom && errors.nom}
                            </div>
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Input
                              bg="#F1F4F6"
                              placeholder="Prénom"
                              name="prenom"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.prenom}
                            />
                            <div className="font-[OpenSans] text-[11px] lg:text-[14px] md:text-[14px] sm:text-[14px] font-bold text-[#ff0000b0] pt-[5px]">
                              {errors.prenom && errors.prenom}
                            </div>
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Input
                              bg="#F1F4F6"
                              text="number"
                              name="telephone"
                              placeholder="Téléphone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.telephone}
                            />
                            <div className="font-[OpenSans] text-[11px] lg:text-[14px] md:text-[14px] sm:text-[14px] font-bold text-[#ff0000b0] pt-[5px]">
                              {errors.telephone && errors.telephone}
                            </div>
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Input
                              bg="#F1F4F6"
                              type="email"
                              name="email"
                              placeholder="Email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            <div className="font-[OpenSans] text-[11px] lg:text-[14px] md:text-[14px] sm:text-[14px] font-bold text-[#ff0000b0] pt-[5px]">
                              {errors.email && errors.email}
                            </div>
                          </Grid>
                          <Grid item lg={12} xs={12}>
                            <textarea
                              className="outline-0 mt-1 block resize-none w-full rounded w-full py-3 px-3 bg-[#F1F4F6] font-bold text-[#222222] font-[OpenSans] text-[14px]"
                              id="exampleFormControlTextarea1"
                              rows="8"
                              name="message"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.message}
                            />
                            {/* <div className="font-[OpenSans] text-[11px] lg:text-[14px] md:text-[14px] sm:text-[14px] font-bold text-[#ff0000b0] pt-[5px]">
                              {errors.message && errors.message}
                            </div> */}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid>
                        <p className="pt-[10px] text-[10px] text-[#222222] font-[OpenSans]">
                          En envoyant ma demande de contact, je déclare accepter
                          que mes données complétées dans ce formulaire soient
                          utilisées pour les buts mentionnés ci-dessus par Ricci
                          Real Estate ; et ce, en accord avec la charte de
                          protection de la vie privée du site. Je peux à tout
                          moment retirer mon consentement en introduisant une
                          demande écrite à l’adresse hello@riccirealestate.be
                        </p>
                      </Grid>
                      <Grid className="mt-[20px] text-center">
                        <button
                          class="w-4/5 font-[OpenSans] text-[14px] font-bold bg-[#003030] button-color-next py-3 px-8  rounded-[5px]"
                          type="submit"
                        >
                          Envoyer
                        </button>
                        <Grid>
                          {open && (
                            <p className="text-[14px] font-[OpenSans] pt-[10px] text-[#003030] text-left">
                              Votre demande a bien été envoyée, nous
                                prendrons contact avec vous dans les plus brefs
                                délais
                            </p>
                          )}
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SecondFormMobile;
