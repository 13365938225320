import { Container, Grid } from "@mui/material";
import "./manage_property.scss";
import React, { useEffect, useState } from "react";
import Stepper from "./stepper/stepper";
import ReactCardFlip from "react-card-flip";
import FirstForm from "./first_from";
import SecondForm from "./second_form";
import FirstFormMobile from "./forms_mobile/first_form";
import SecondFormMobile from "./forms_mobile/second_form";
import { sendPropertyData } from "../../service/manage_property";

const ManagePageTop = () => {
  
  const [selectedButton, setSelectedButton] = useState(1);
  const [selectedType, setSelectedType] = useState(1);
  const [isFlipped, setIsFlipped] = useState(false);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    type: "Maison",
    chambres: "1",
    garage: "yes",
    parking: "yes",
    jardin: "yes",
    terasse: "yes",
  });
  const handleClick = (e) => {
    setIsFlipped(!isFlipped);
  };
  useEffect(() => {
    setOpen(false);
  }, []);

  const handleSubmitForm = (values, resetForm) => {
    var templateParams = {
      type: state.type,
      chambres: state.chambres,
      garage: state.garage,
      parking: state.parking,
      jardin: state.jardin,
      terasse: state.terasse,
      superficie: state.superficie,
      construction: state.construction,
      renovation: state.renovation,
      rue: state.rue,
      nr: state.nr,
      codepostal: state.codepostal,
      ville: state.ville,
      nom: values.nom,
      prenom: values.prenom,
      email: values.email,
      telephone: values.telephone,
      message: values.message,
    };
    sendPropertyData(templateParams)
      .then((response) => {
        setOpen(true);
        setState({message: `Bonjour,\n\n  Je souhaiterai avoir plus d’informations\n  concernant les services de gestion\n  relatifs à mon bien.\n  \n  Merci d'avance,\n`});
        resetForm();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div className="logo-background-form-estimate bg-[length:38%] lg:bg-[length:22%] xl:bg-[length:22%] md:bg-[length:22%] sm:bg-[length:34%]">
      <Container className="py-[40px] ">
        <Grid container>
          <Grid container justifyContent="center">
            {!isFlipped && (
              <Grid item xs={12} lg={10} sm={12}>
                <h1 className="font-[Poppins] font-bold xl:text-[30px] md:text-[30px] lg:text-[30px] text-[26px] text-center">
                  <span className="heading-linear">
                    Demandez la gestion locative
                  </span>
                  <span className="text-[#222222]"> de votre bien </span>
                </h1>
                <p className="text-center pt-[10px] lg:pt-[30px] md:pt-[30px] xl:pt-[30px] xl:text-[16px] lg:text-[16px] md:text-[16px] text-[14px] text-[#666666] font-[OpenSans]">
                  La gestion et l’optimalisation de votre patrimoine sont
                  entièrement prises en charge par notre équipe de
                  professionnels
                </p>
              </Grid>
            )}
            <Grid container className="pt-[0px] lg:pt-[20px] xl:pt-[20px] ">
              <Stepper />
            </Grid>
            <div className="hidden lg:block xl:block">
              <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
                <FirstForm
                  selectedButton={selectedButton}
                  setSelectedButton={setSelectedButton}
                  selectedType={selectedType}
                  setSelectedType={setSelectedType}
                  handleClick={handleClick}
                  state={state}
                  setState={setState}
                />
                <SecondForm
                  state={state}
                  setState={setState}
                  handleSubmitForm={handleSubmitForm}
                  open={open}
                 />
              </ReactCardFlip>
            </div>
            <div className="block lg:hidden xl:hidden">
              <FirstFormMobile
                selectedButton={selectedButton}
                setSelectedButton={setSelectedButton}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
                handleClick={handleClick}
                state={state}
                setState={setState}
              />
              <SecondFormMobile
                state={state}
                setState={setState}
                open={open}
                handleSubmitForm={handleSubmitForm}
               />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ManagePageTop;
