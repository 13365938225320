import React from "react";
import { Grid, Container } from "@mui/material";
import "./service.scss";

const Service = () => {
  return (
    <Container>
      <Grid className="flex justify-center">
        <Grid item xs={12} lg={10} sm={12} className="py-12 service-home-main">
          <Grid container className="flex justify-center">
            <Grid item xs={12} lg={10} sm={12}>
              <div>
                <h1 className="font-[Poppins] font-bold text-[30px] text-center">
                  <span className="text-[#222222]"> Ricci Real Estate </span>
                  <span className=" heading-linear"> c'est avant tout</span>
                </h1>
              </div>
              <Grid
                container
                spacing={2}
                className="pt-[40px]"
                justifyContent={"center"}
              >
                <Grid lg={6} item md={6} sm={6}>
                  <div class="grid">
                    <figure class="effect-sadie">
                      <img
                        src={require("../../../assets/images/homepage/optimised/one.jpg")}
                        alt="img02"
                        className="w-full h-56 object-cover rounded-[10px]"
                      />
                      <figcaption>
                        <h2 className="font-semibold text-[24px] lg:text-[26px] font-[Poppins]">
                          Une véritable expertise
                        </h2>
                        <p className="font-[OpenSans] text-[12px] lg:text-[16px] sm:text-[14px] md:text-[14px]  p-[14px] lg:p-[20px] md:p-[20px] sm:p-[14px]">
                          Nos rapports d'expertises sont réalisés de manière
                          transparente et réaliste, en abordant les éléments
                          particuliers qui ont retenu notre attention pour
                          évaluer au mieux la valeur de vente ou de location de
                          votre bien.
                        </p>
                      </figcaption>
                    </figure>
                  </div>
                </Grid>
                <Grid lg={6} item md={6} sm={6}>
                  <div class="grid">
                    <figure class="effect-sadie">
                      <img
                        src={require("../../../assets/images/homepage/optimised/two.jpg")}
                        alt="img02"
                        className="w-full h-56 object-cover rounded-[10px]"
                      />
                      <figcaption>
                        <h2 className="font-semibold text-[24px] lg:text-[26px] font-[Poppins]">
                          Un service unique
                        </h2>
                        <p className="font-[OpenSans] text-[12px] lg:text-[16px] sm:text-[14px] md:text-[14px]  p-[14px] lg:p-[20px] md:p-[20px] sm:p-[14px]">
                          Chaque transaction étant unique, nous mettons à votre
                          disposition nos connaissances les plus adaptées selon
                          les caractéristiques du projet.
                        </p>
                      </figcaption>
                    </figure>
                  </div>
                </Grid>
                <Grid lg={6} item md={6} sm={6}>
                  <div class="grid">
                    <figure class="effect-sadie">
                      <img
                        src={require("../../../assets/images/homepage/optimised/three.jpg")}
                        alt="img02"
                        className="w-full h-56 object-cover rounded-[10px]"
                      />
                      <figcaption>
                        <h2 className="font-semibold text-[24px] lg:text-[26px] font-[Poppins]">
                          Des conseils sur mesure
                        </h2>
                        <p className="font-[OpenSans] text-[12px] lg:text-[16px]  sm:text-[14px] md:text-[14px] p-[14px] lg:p-[20px] md:p-[20px] sm:p-[14px]">
                          Grace à l'ensemble de nos approches
                          pluridisciplinaires, nous veillons à vous offrir les
                          conseils spécifiques à vos projets.
                        </p>
                      </figcaption>
                    </figure>
                  </div>
                </Grid>
                <Grid lg={6} item md={6} sm={6}>
                  <div class="grid">
                    <figure class="effect-sadie">
                      <img
                        src={require("../../../assets/images/homepage/optimised/four.jpg")}
                        alt="img02"
                        className="w-full h-56 object-cover rounded-[10px]"
                      />
                      <figcaption>
                        <h2 className="font-semibold text-[24px] lg:text-[26px] font-[Poppins]">
                          Un suivi constant
                        </h2>
                        <p className="font-[OpenSans] text-[12px] lg:text-[16px] sm:text-[14px] md:text-[14px] p-[14px] lg:p-[20px] md:p-[20px] sm:p-[14px]">
                          L'ensemble de notre équipe vous accompagne tout au
                          long du processus de votre transaction immobilière et
                          ce dans l'objectif de rendre l'expérience la plus
                          harmonieuse possible.
                        </p>
                      </figcaption>
                    </figure>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Service;
