import React from "react";

const InputComponent = (props) => {
  const {
    placeholder,
    value,
    defaultValue,
    bg,
    border,
    onChange,
    onBlue,
    name,
    type,
    required,
  } = props;
  return (
    <input
      className={`outline-0 block  w-full ${border} rounded w-full py-3 px-3 bg-[${bg}] font-bold text-[#222222] font-[OpenSans] text-[14px]`}
      placeholder={placeholder}
      value={value}
      required={required}
      name={name}
      type={type}
      defaultValue={defaultValue}
      onChange={onChange}
      onBlue={onBlue}
    />
  );
};

export default InputComponent;
