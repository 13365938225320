export const postalCodes = [
    { id: "1000", text: "1000" }, 
    { id: "1005", text: "1005" }, 
    { id: "1006", text: "1006" }, 
    { id: "1007", text: "1007" }, 
    { id: "1008", text: "1008" }, 
    { id: "1009", text: "1009" }, 
    { id: "1010", text: "1010" }, 
    { id: "1011", text: "1011" }, 
    { id: "1012", text: "1012" }, 
    { id: "1020", text: "1020" }, 
    { id: "1030", text: "1030" }, 
    { id: "1031", text: "1031" }, 
    { id: "1033", text: "1033" }, 
    { id: "1040", text: "1040" }, 
    { id: "1041", text: "1041" }, 
    { id: "1043", text: "1043" }, 
    { id: "1044", text: "1044" }, 
    { id: "1045", text: "1045" }, 
    { id: "1047", text: "1047" }, 
    { id: "1048", text: "1048" }, 
    { id: "1049", text: "1049" }, 
    { id: "1050", text: "1050" }, 
    { id: "1060", text: "1060" }, 
    { id: "1070", text: "1070" }, 
    { id: "1080", text: "1080" }, 
    { id: "1081", text: "1081" }, 
    { id: "1082", text: "1082" }, 
    { id: "1083", text: "1083" }, 
    { id: "1090", text: "1090" }, 
    { id: "1100", text: "1100" }, 
    { id: "1105", text: "1105" }, 
    { id: "1110", text: "1110" }, 
    { id: "1120", text: "1120" }, 
    { id: "1130", text: "1130" }, 
    { id: "1140", text: "1140" }, 
    { id: "1150", text: "1150" }, 
    { id: "1160", text: "1160" }, 
    { id: "1170", text: "1170" }, 
    { id: "1180", text: "1180" }, 
    { id: "1190", text: "1190" }, 
    { id: "1200", text: "1200" }, 
    { id: "1210", text: "1210" }, 
    { id: "1300", text: "1300" }, 
    { id: "1301", text: "1301" }, 
    { id: "1310", text: "1310" }, 
    { id: "1315", text: "1315" }, 
    { id: "1320", text: "1320" }, 
    { id: "1325", text: "1325" }, 
    { id: "1330", text: "1330" }, 
    { id: "1331", text: "1331" }, 
    { id: "1332", text: "1332" }, 
    { id: "1340", text: "1340" }, 
    { id: "1341", text: "1341" }, 
    { id: "1342", text: "1342" }, 
    { id: "1348", text: "1348" }, 
    { id: "1350", text: "1350" }, 
    { id: "1357", text: "1357" }, 
    { id: "1360", text: "1360" }, 
    { id: "1367", text: "1367" }, 
    { id: "1370", text: "1370" }, 
    { id: "1380", text: "1380" }, 
    { id: "1390", text: "1390" }, 
    { id: "1400", text: "1400" }, 
    { id: "1401", text: "1401" }, 
    { id: "1402", text: "1402" }, 
    { id: "1404", text: "1404" }, 
    { id: "1410", text: "1410" }, 
    { id: "1414", text: "1414" }, 
    { id: "1420", text: "1420" }, 
    { id: "1421", text: "1421" }, 
    { id: "1428", text: "1428" }, 
    { id: "1430", text: "1430" }, 
    { id: "1435", text: "1435" }, 
    { id: "1440", text: "1440" }, 
    { id: "1450", text: "1450" }, 
    { id: "1457", text: "1457" }, 
    { id: "1460", text: "1460" }, 
    { id: "1461", text: "1461" }, 
    { id: "1470", text: "1470" }, 
    { id: "1471", text: "1471" }, 
    { id: "1472", text: "1472" }, 
    { id: "1473", text: "1473" }, 
    { id: "1474", text: "1474" }, 
    { id: "1476", text: "1476" }, 
    { id: "1480", text: "1480" }, 
    { id: "1490", text: "1490" }, 
    { id: "1495", text: "1495" }, 
    { id: "1500", text: "1500" }, 
    { id: "1501", text: "1501" }, 
    { id: "1502", text: "1502" }, 
    { id: "1547", text: "1547" }, 
    { id: "1560", text: "1560" }, 
    { id: "1570", text: "1570" }, 
    { id: "1600", text: "1600" }, 
    { id: "1601", text: "1601" }, 
    { id: "1620", text: "1620" }, 
    { id: "1640", text: "1640" }, 
    { id: "1650", text: "1650" }, 
    { id: "1651", text: "1651" }, 
    { id: "1653", text: "1653" }, 
    { id: "1654", text: "1654" }, 
    { id: "1670", text: "1670" }, 
    { id: "1671", text: "1671" }, 
    { id: "1674", text: "1674" }, 
    { id: "1700", text: "1700" }, 
    { id: "1731", text: "1731" }, 
    { id: "1740", text: "1740" }, 
    { id: "1741", text: "1741" }, 
    { id: "1745", text: "1745" }, 
    { id: "1755", text: "1755" }, 
    { id: "1760", text: "1760" }, 
    { id: "1761", text: "1761" }, 
    { id: "1770", text: "1770" }, 
    { id: "1780", text: "1780" }, 
    { id: "1785", text: "1785" }, 
    { id: "1790", text: "1790" }, 
    { id: "1800", text: "1800" }, 
    { id: "1804", text: "1804" }, 
    { id: "1818", text: "1818" }, 
    { id: "1820", text: "1820" }, 
    { id: "1830", text: "1830" }, 
    { id: "1831", text: "1831" }, 
    { id: "1840", text: "1840" }, 
    { id: "1850", text: "1850" }, 
    { id: "1851", text: "1851" }, 
    { id: "1852", text: "1852" }, 
    { id: "1853", text: "1853" }, 
    { id: "1860", text: "1860" }, 
    { id: "1861", text: "1861" }, 
    { id: "1880", text: "1880" }, 
    { id: "1910", text: "1910" }, 
    { id: "1930", text: "1930" }, 
    { id: "1931", text: "1931" }, 
    { id: "1932", text: "1932" }, 
    { id: "1933", text: "1933" }, 
    { id: "1934", text: "1934" }, 
    { id: "1935", text: "1935" }, 
    { id: "1950", text: "1950" }, 
    { id: "1970", text: "1970" }, 
    { id: "1980", text: "1980" }, 
    { id: "1981", text: "1981" }, 
    { id: "1982", text: "1982" }, 
    { id: "2000", text: "2000" }, 
    { id: "2018", text: "2018" }, 
    { id: "2020", text: "2020" }, 
    { id: "2030", text: "2030" }, 
    { id: "2040", text: "2040" }, 
    { id: "2050", text: "2050" }, 
    { id: "2060", text: "2060" }, 
    { id: "2070", text: "2070" }, 
    { id: "2100", text: "2100" }, 
    { id: "2110", text: "2110" }, 
    { id: "2140", text: "2140" }, 
    { id: "2150", text: "2150" }, 
    { id: "2160", text: "2160" }, 
    { id: "2170", text: "2170" }, 
    { id: "2180", text: "2180" }, 
    { id: "2200", text: "2200" }, 
    { id: "2220", text: "2220" }, 
    { id: "2221", text: "2221" }, 
    { id: "2222", text: "2222" }, 
    { id: "2223", text: "2223" }, 
    { id: "2230", text: "2230" }, 
    { id: "2235", text: "2235" }, 
    { id: "2240", text: "2240" }, 
    { id: "2242", text: "2242" }, 
    { id: "2243", text: "2243" }, 
    { id: "2250", text: "2250" }, 
    { id: "2260", text: "2260" }, 
    { id: "2270", text: "2270" }, 
    { id: "2275", text: "2275" }, 
    { id: "2280", text: "2280" }, 
    { id: "2288", text: "2288" }, 
    { id: "2290", text: "2290" }, 
    { id: "2300", text: "2300" }, 
    { id: "2310", text: "2310" }, 
    { id: "2320", text: "2320" }, 
    { id: "2321", text: "2321" }, 
    { id: "2322", text: "2322" }, 
    { id: "2323", text: "2323" }, 
    { id: "2328", text: "2328" }, 
    { id: "2330", text: "2330" }, 
    { id: "2340", text: "2340" }, 
    { id: "2350", text: "2350" }, 
    { id: "2360", text: "2360" }, 
    { id: "2370", text: "2370" }, 
    { id: "2380", text: "2380" }, 
    { id: "2381", text: "2381" }, 
    { id: "2382", text: "2382" }, 
    { id: "2387", text: "2387" }, 
    { id: "2390", text: "2390" }, 
    { id: "2400", text: "2400" }, 
    { id: "2430", text: "2430" }, 
    { id: "2431", text: "2431" }, 
    { id: "2440", text: "2440" }, 
    { id: "2450", text: "2450" }, 
    { id: "2460", text: "2460" }, 
    { id: "2470", text: "2470" }, 
    { id: "2480", text: "2480" }, 
    { id: "2490", text: "2490" }, 
    { id: "2491", text: "2491" }, 
    { id: "2500", text: "2500" }, 
    { id: "2520", text: "2520" }, 
    { id: "2530", text: "2530" }, 
    { id: "2531", text: "2531" }, 
    { id: "2540", text: "2540" }, 
    { id: "2547", text: "2547" }, 
    { id: "2550", text: "2550" }, 
    { id: "2560", text: "2560" }, 
    { id: "2570", text: "2570" }, 
    { id: "2580", text: "2580" }, 
    { id: "2590", text: "2590" }, 
    { id: "2600", text: "2600" }, 
    { id: "2610", text: "2610" }, 
    { id: "2620", text: "2620" }, 
    { id: "2627", text: "2627" }, 
    { id: "2630", text: "2630" }, 
    { id: "2640", text: "2640" }, 
    { id: "2650", text: "2650" }, 
    { id: "2660", text: "2660" }, 
    { id: "2800", text: "2800" }, 
    { id: "2801", text: "2801" }, 
    { id: "2811", text: "2811" }, 
    { id: "2812", text: "2812" }, 
    { id: "2820", text: "2820" }, 
    { id: "2830", text: "2830" }, 
    { id: "2840", text: "2840" }, 
    { id: "2845", text: "2845" }, 
    { id: "2850", text: "2850" }, 
    { id: "2860", text: "2860" }, 
    { id: "2861", text: "2861" }, 
    { id: "2870", text: "2870" }, 
    { id: "2880", text: "2880" }, 
    { id: "2890", text: "2890" }, 
    { id: "2900", text: "2900" }, 
    { id: "2910", text: "2910" }, 
    { id: "2920", text: "2920" }, 
    { id: "2930", text: "2930" }, 
    { id: "2940", text: "2940" }, 
    { id: "2950", text: "2950" }, 
    { id: "2960", text: "2960" }, 
    { id: "2970", text: "2970" }, 
    { id: "2980", text: "2980" }, 
    { id: "2990", text: "2990" }, 
    { id: "3000", text: "3000" }, 
    { id: "3001", text: "3001" }, 
    { id: "3010", text: "3010" }, 
    { id: "3012", text: "3012" }, 
    { id: "3018", text: "3018" }, 
    { id: "3020", text: "3020" }, 
    { id: "3040", text: "3040" }, 
    { id: "3050", text: "3050" }, 
    { id: "3051", text: "3051" }, 
    { id: "3052", text: "3052" }, 
    { id: "3053", text: "3053" }, 
    { id: "3054", text: "3054" }, 
    { id: "3060", text: "3060" }, 
    { id: "3061", text: "3061" }, 
    { id: "3070", text: "3070" }, 
    { id: "3071", text: "3071" }, 
    { id: "3078", text: "3078" }, 
    { id: "3080", text: "3080" }, 
    { id: "3090", text: "3090" }, 
    { id: "3110", text: "3110" }, 
    { id: "3111", text: "3111" }, 
    { id: "3118", text: "3118" }, 
    { id: "3120", text: "3120" }, 
    { id: "3128", text: "3128" }, 
    { id: "3130", text: "3130" }, 
    { id: "3140", text: "3140" }, 
    { id: "3150", text: "3150" }, 
    { id: "3190", text: "3190" }, 
    { id: "3191", text: "3191" }, 
    { id: "3200", text: "3200" }, 
    { id: "3201", text: "3201" }, 
    { id: "3202", text: "3202" }, 
    { id: "3210", text: "3210" }, 
    { id: "3211", text: "3211" }, 
    { id: "3212", text: "3212" }, 
    { id: "3220", text: "3220" }, 
    { id: "3221", text: "3221" }, 
    { id: "3270", text: "3270" }, 
    { id: "3271", text: "3271" }, 
    { id: "3272", text: "3272" }, 
    { id: "3290", text: "3290" }, 
    { id: "3293", text: "3293" }, 
    { id: "3294", text: "3294" }, 
    { id: "3300", text: "3300" }, 
    { id: "3320", text: "3320" }, 
    { id: "3321", text: "3321" }, 
    { id: "3350", text: "3350" }, 
    { id: "3360", text: "3360" }, 
    { id: "3370", text: "3370" }, 
    { id: "3380", text: "3380" }, 
    { id: "3381", text: "3381" }, 
    { id: "3384", text: "3384" }, 
    { id: "3390", text: "3390" }, 
    { id: "3391", text: "3391" }, 
    { id: "3400", text: "3400" }, 
    { id: "3401", text: "3401" }, 
    { id: "3404", text: "3404" }, 
    { id: "3440", text: "3440" }, 
    { id: "3450", text: "3450" }, 
    { id: "3454", text: "3454" }, 
    { id: "3460", text: "3460" }, 
    { id: "3461", text: "3461" }, 
    { id: "3470", text: "3470" }, 
    { id: "3471", text: "3471" }, 
    { id: "3472", text: "3472" }, 
    { id: "3473", text: "3473" }, 
    { id: "3500", text: "3500" }, 
    { id: "3501", text: "3501" }, 
    { id: "3510", text: "3510" }, 
    { id: "3511", text: "3511" }, 
    { id: "3512", text: "3512" }, 
    { id: "3520", text: "3520" }, 
    { id: "3530", text: "3530" }, 
    { id: "3540", text: "3540" }, 
    { id: "3545", text: "3545" }, 
    { id: "3550", text: "3550" }, 
    { id: "3560", text: "3560" }, 
    { id: "3570", text: "3570" }, 
    { id: "3580", text: "3580" }, 
    { id: "3581", text: "3581" }, 
    { id: "3582", text: "3582" }, 
    { id: "3583", text: "3583" }, 
    { id: "3590", text: "3590" }, 
    { id: "3600", text: "3600" }, 
    { id: "3620", text: "3620" }, 
    { id: "3621", text: "3621" }, 
    { id: "3630", text: "3630" }, 
    { id: "3631", text: "3631" }, 
    { id: "3640", text: "3640" }, 
    { id: "3650", text: "3650" }, 
    { id: "3660", text: "3660" }, 
    { id: "3665", text: "3665" }, 
    { id: "3668", text: "3668" }, 
    { id: "3670", text: "3670" }, 
    { id: "3680", text: "3680" }, 
    { id: "3690", text: "3690" }, 
    { id: "3700", text: "3700" }, 
    { id: "3717", text: "3717" }, 
    { id: "3720", text: "3720" }, 
    { id: "3721", text: "3721" }, 
    { id: "3722", text: "3722" }, 
    { id: "3723", text: "3723" }, 
    { id: "3724", text: "3724" }, 
    { id: "3730", text: "3730" }, 
    { id: "3732", text: "3732" }, 
    { id: "3740", text: "3740" }, 
    { id: "3742", text: "3742" }, 
    { id: "3746", text: "3746" }, 
    { id: "3770", text: "3770" }, 
    { id: "3790", text: "3790" }, 
    { id: "3791", text: "3791" }, 
    { id: "3792", text: "3792" }, 
    { id: "3793", text: "3793" }, 
    { id: "3798", text: "3798" }, 
    { id: "3800", text: "3800" }, 
    { id: "3803", text: "3803" }, 
    { id: "3806", text: "3806" }, 
    { id: "3830", text: "3830" }, 
    { id: "3831", text: "3831" }, 
    { id: "3832", text: "3832" }, 
    { id: "3840", text: "3840" }, 
    { id: "3850", text: "3850" }, 
    { id: "3870", text: "3870" }, 
    { id: "3890", text: "3890" }, 
    { id: "3891", text: "3891" }, 
    { id: "3900", text: "3900" }, 
    { id: "3910", text: "3910" }, 
    { id: "3920", text: "3920" }, 
    { id: "3930", text: "3930" }, 
    { id: "3940", text: "3940" }, 
    { id: "3941", text: "3941" }, 
    { id: "3945", text: "3945" }, 
    { id: "3950", text: "3950" }, 
    { id: "3960", text: "3960" }, 
    { id: "3970", text: "3970" }, 
    { id: "3971", text: "3971" }, 
    { id: "3980", text: "3980" }, 
    { id: "3990", text: "3990" }, 
    { id: "4000", text: "4000" }, 
    { id: "4020", text: "4020" }, 
    { id: "4030", text: "4030" }, 
    { id: "4031", text: "4031" }, 
    { id: "4032", text: "4032" }, 
    { id: "4040", text: "4040" }, 
    { id: "4041", text: "4041" }, 
    { id: "4042", text: "4042" }, 
    { id: "4050", text: "4050" }, 
    { id: "4051", text: "4051" }, 
    { id: "4052", text: "4052" }, 
    { id: "4053", text: "4053" }, 
    { id: "4090", text: "4090" }, 
    { id: "4100", text: "4100" }, 
    { id: "4101", text: "4101" }, 
    { id: "4102", text: "4102" }, 
    { id: "4120", text: "4120" }, 
    { id: "4121", text: "4121" }, 
    { id: "4122", text: "4122" }, 
    { id: "4130", text: "4130" }, 
    { id: "4140", text: "4140" }, 
    { id: "4141", text: "4141" }, 
    { id: "4160", text: "4160" }, 
    { id: "4161", text: "4161" }, 
    { id: "4162", text: "4162" }, 
    { id: "4163", text: "4163" }, 
    { id: "4170", text: "4170" }, 
    { id: "4171", text: "4171" }, 
    { id: "4180", text: "4180" }, 
    { id: "4181", text: "4181" }, 
    { id: "4190", text: "4190" }, 
    { id: "4210", text: "4210" }, 
    { id: "4217", text: "4217" }, 
    { id: "4218", text: "4218" }, 
    { id: "4219", text: "4219" }, 
    { id: "4250", text: "4250" }, 
    { id: "4252", text: "4252" }, 
    { id: "4253", text: "4253" }, 
    { id: "4254", text: "4254" }, 
    { id: "4257", text: "4257" }, 
    { id: "4260", text: "4260" }, 
    { id: "4261", text: "4261" }, 
    { id: "4263", text: "4263" }, 
    { id: "4280", text: "4280" }, 
    { id: "4287", text: "4287" }, 
    { id: "4300", text: "4300" }, 
    { id: "4317", text: "4317" }, 
    { id: "4340", text: "4340" }, 
    { id: "4342", text: "4342" }, 
    { id: "4347", text: "4347" }, 
    { id: "4350", text: "4350" }, 
    { id: "4351", text: "4351" }, 
    { id: "4357", text: "4357" }, 
    { id: "4360", text: "4360" }, 
    { id: "4367", text: "4367" }, 
    { id: "4400", text: "4400" }, 
    { id: "4420", text: "4420" }, 
    { id: "4430", text: "4430" }, 
    { id: "4431", text: "4431" }, 
    { id: "4432", text: "4432" }, 
    { id: "4450", text: "4450" }, 
    { id: "4451", text: "4451" }, 
    { id: "4452", text: "4452" }, 
    { id: "4453", text: "4453" }, 
    { id: "4458", text: "4458" }, 
    { id: "4460", text: "4460" }, 
    { id: "4470", text: "4470" }, 
    { id: "4480", text: "4480" }, 
    { id: "4500", text: "4500" }, 
    { id: "4520", text: "4520" }, 
    { id: "4530", text: "4530" }, 
    { id: "4537", text: "4537" }, 
    { id: "4540", text: "4540" }, 
    { id: "4550", text: "4550" }, 
    { id: "4557", text: "4557" }, 
    { id: "4560", text: "4560" }, 
    { id: "4570", text: "4570" }, 
    { id: "4577", text: "4577" }, 
    { id: "4590", text: "4590" }, 
    { id: "4600", text: "4600" }, 
    { id: "4601", text: "4601" }, 
    { id: "4602", text: "4602" }, 
    { id: "4606", text: "4606" }, 
    { id: "4607", text: "4607" }, 
    { id: "4608", text: "4608" }, 
    { id: "4610", text: "4610" }, 
    { id: "4620", text: "4620" }, 
    { id: "4621", text: "4621" }, 
    { id: "4623", text: "4623" }, 
    { id: "4624", text: "4624" }, 
    { id: "4630", text: "4630" }, 
    { id: "4631", text: "4631" }, 
    { id: "4632", text: "4632" }, 
    { id: "4633", text: "4633" }, 
    { id: "4650", text: "4650" }, 
    { id: "4651", text: "4651" }, 
    { id: "4652", text: "4652" }, 
    { id: "4653", text: "4653" }, 
    { id: "4654", text: "4654" }, 
    { id: "4670", text: "4670" }, 
    { id: "4671", text: "4671" }, 
    { id: "4672", text: "4672" }, 
    { id: "4680", text: "4680" }, 
    { id: "4681", text: "4681" }, 
    { id: "4682", text: "4682" }, 
    { id: "4683", text: "4683" }, 
    { id: "4684", text: "4684" }, 
    { id: "4690", text: "4690" }, 
    { id: "4700", text: "4700" }, 
    { id: "4701", text: "4701" }, 
    { id: "4710", text: "4710" }, 
    { id: "4711", text: "4711" }, 
    { id: "4720", text: "4720" }, 
    { id: "4721", text: "4721" }, 
    { id: "4728", text: "4728" }, 
    { id: "4730", text: "4730" }, 
    { id: "4731", text: "4731" }, 
    { id: "4750", text: "4750" }, 
    { id: "4760", text: "4760" }, 
    { id: "4761", text: "4761" }, 
    { id: "4770", text: "4770" }, 
    { id: "4771", text: "4771" }, 
    { id: "4780", text: "4780" }, 
    { id: "4782", text: "4782" }, 
    { id: "4783", text: "4783" }, 
    { id: "4784", text: "4784" }, 
    { id: "4790", text: "4790" }, 
    { id: "4791", text: "4791" }, 
    { id: "4800", text: "4800" }, 
    { id: "4801", text: "4801" }, 
    { id: "4802", text: "4802" }, 
    { id: "4820", text: "4820" }, 
    { id: "4821", text: "4821" }, 
    { id: "4830", text: "4830" }, 
    { id: "4831", text: "4831" }, 
    { id: "4834", text: "4834" }, 
    { id: "4837", text: "4837" }, 
    { id: "4840", text: "4840" }, 
    { id: "4841", text: "4841" }, 
    { id: "4845", text: "4845" }, 
    { id: "4850", text: "4850" }, 
    { id: "4851", text: "4851" }, 
    { id: "4852", text: "4852" }, 
    { id: "4860", text: "4860" }, 
    { id: "4861", text: "4861" }, 
    { id: "4870", text: "4870" }, 
    { id: "4877", text: "4877" }, 
    { id: "4880", text: "4880" }, 
    { id: "4890", text: "4890" }, 
    { id: "4900", text: "4900" }, 
    { id: "4910", text: "4910" }, 
    { id: "4920", text: "4920" }, 
    { id: "4950", text: "4950" }, 
    { id: "4960", text: "4960" }, 
    { id: "4970", text: "4970" }, 
    { id: "4980", text: "4980" }, 
    { id: "4983", text: "4983" }, 
    { id: "4987", text: "4987" }, 
    { id: "4990", text: "4990" }, 
    { id: "5000", text: "5000" }, 
    { id: "5001", text: "5001" }, 
    { id: "5002", text: "5002" }, 
    { id: "5003", text: "5003" }, 
    { id: "5004", text: "5004" }, 
    { id: "5020", text: "5020" }, 
    { id: "5021", text: "5021" }, 
    { id: "5022", text: "5022" }, 
    { id: "5024", text: "5024" }, 
    { id: "5030", text: "5030" }, 
    { id: "5031", text: "5031" }, 
    { id: "5032", text: "5032" }, 
    { id: "5060", text: "5060" }, 
    { id: "5070", text: "5070" }, 
    { id: "5080", text: "5080" }, 
    { id: "5081", text: "5081" }, 
    { id: "5100", text: "5100" }, 
    { id: "5101", text: "5101" }, 
    { id: "5140", text: "5140" }, 
    { id: "5150", text: "5150" }, 
    { id: "5170", text: "5170" }, 
    { id: "5190", text: "5190" }, 
    { id: "5300", text: "5300" }, 
    { id: "5310", text: "5310" }, 
    { id: "5330", text: "5330" }, 
    { id: "5332", text: "5332" }, 
    { id: "5333", text: "5333" }, 
    { id: "5334", text: "5334" }, 
    { id: "5336", text: "5336" }, 
    { id: "5340", text: "5340" }, 
    { id: "5350", text: "5350" }, 
    { id: "5351", text: "5351" }, 
    { id: "5352", text: "5352" }, 
    { id: "5353", text: "5353" }, 
    { id: "5354", text: "5354" }, 
    { id: "5360", text: "5360" }, 
    { id: "5361", text: "5361" }, 
    { id: "5362", text: "5362" }, 
    { id: "5363", text: "5363" }, 
    { id: "5364", text: "5364" }, 
    { id: "5370", text: "5370" }, 
    { id: "5372", text: "5372" }, 
    { id: "5374", text: "5374" }, 
    { id: "5376", text: "5376" }, 
    { id: "5377", text: "5377" }, 
    { id: "5380", text: "5380" }, 
    { id: "5500", text: "5500" }, 
    { id: "5501", text: "5501" }, 
    { id: "5502", text: "5502" }, 
    { id: "5503", text: "5503" }, 
    { id: "5504", text: "5504" }, 
    { id: "5520", text: "5520" }, 
    { id: "5521", text: "5521" }, 
    { id: "5522", text: "5522" }, 
    { id: "5523", text: "5523" }, 
    { id: "5524", text: "5524" }, 
    { id: "5530", text: "5530" }, 
    { id: "5537", text: "5537" }, 
    { id: "5540", text: "5540" }, 
    { id: "5541", text: "5541" }, 
    { id: "5542", text: "5542" }, 
    { id: "5543", text: "5543" }, 
    { id: "5544", text: "5544" }, 
    { id: "5550", text: "5550" }, 
    { id: "5555", text: "5555" }, 
    { id: "5560", text: "5560" }, 
    { id: "5561", text: "5561" }, 
    { id: "5562", text: "5562" }, 
    { id: "5563", text: "5563" }, 
    { id: "5564", text: "5564" }, 
    { id: "5570", text: "5570" }, 
    { id: "5571", text: "5571" }, 
    { id: "5572", text: "5572" }, 
    { id: "5573", text: "5573" }, 
    { id: "5574", text: "5574" }, 
    { id: "5575", text: "5575" }, 
    { id: "5576", text: "5576" }, 
    { id: "5580", text: "5580" }, 
    { id: "5590", text: "5590" }, 
    { id: "5600", text: "5600" }, 
    { id: "5620", text: "5620" }, 
    { id: "5621", text: "5621" }, 
    { id: "5630", text: "5630" }, 
    { id: "5640", text: "5640" }, 
    { id: "5641", text: "5641" }, 
    { id: "5644", text: "5644" }, 
    { id: "5646", text: "5646" }, 
    { id: "5650", text: "5650" }, 
    { id: "5651", text: "5651" }, 
    { id: "5660", text: "5660" }, 
    { id: "5670", text: "5670" }, 
    { id: "5680", text: "5680" }, 
    { id: "6000", text: "6000" }, 
    { id: "6001", text: "6001" }, 
    { id: "6010", text: "6010" }, 
    { id: "6020", text: "6020" }, 
    { id: "6030", text: "6030" }, 
    { id: "6031", text: "6031" }, 
    { id: "6032", text: "6032" }, 
    { id: "6040", text: "6040" }, 
    { id: "6041", text: "6041" }, 
    { id: "6042", text: "6042" }, 
    { id: "6043", text: "6043" }, 
    { id: "6044", text: "6044" }, 
    { id: "6060", text: "6060" }, 
    { id: "6061", text: "6061" }, 
    { id: "6110", text: "6110" }, 
    { id: "6111", text: "6111" }, 
    { id: "6120", text: "6120" }, 
    { id: "6140", text: "6140" }, 
    { id: "6141", text: "6141" }, 
    { id: "6142", text: "6142" }, 
    { id: "6150", text: "6150" }, 
    { id: "6180", text: "6180" }, 
    { id: "6181", text: "6181" }, 
    { id: "6182", text: "6182" }, 
    { id: "6183", text: "6183" }, 
    { id: "6200", text: "6200" }, 
    { id: "6210", text: "6210" }, 
    { id: "6211", text: "6211" }, 
    { id: "6220", text: "6220" }, 
    { id: "6221", text: "6221" }, 
    { id: "6222", text: "6222" }, 
    { id: "6223", text: "6223" }, 
    { id: "6224", text: "6224" }, 
    { id: "6230", text: "6230" }, 
    { id: "6238", text: "6238" }, 
    { id: "6240", text: "6240" }, 
    { id: "6250", text: "6250" }, 
    { id: "6280", text: "6280" }, 
    { id: "6440", text: "6440" }, 
    { id: "6441", text: "6441" }, 
    { id: "6460", text: "6460" }, 
    { id: "6461", text: "6461" }, 
    { id: "6462", text: "6462" }, 
    { id: "6463", text: "6463" }, 
    { id: "6464", text: "6464" }, 
    { id: "6470", text: "6470" }, 
    { id: "6500", text: "6500" }, 
    { id: "6511", text: "6511" }, 
    { id: "6530", text: "6530" }, 
    { id: "6531", text: "6531" }, 
    { id: "6532", text: "6532" }, 
    { id: "6533", text: "6533" }, 
    { id: "6534", text: "6534" }, 
    { id: "6536", text: "6536" }, 
    { id: "6540", text: "6540" }, 
    { id: "6542", text: "6542" }, 
    { id: "6543", text: "6543" }, 
    { id: "6560", text: "6560" }, 
    { id: "6567", text: "6567" }, 
    { id: "6590", text: "6590" }, 
    { id: "6591", text: "6591" }, 
    { id: "6592", text: "6592" }, 
    { id: "6593", text: "6593" }, 
    { id: "6594", text: "6594" }, 
    { id: "6596", text: "6596" }, 
    { id: "6600", text: "6600" }, 
    { id: "6630", text: "6630" }, 
    { id: "6637", text: "6637" }, 
    { id: "6640", text: "6640" }, 
    { id: "6642", text: "6642" }, 
    { id: "6660", text: "6660" }, 
    { id: "6661", text: "6661" }, 
    { id: "6662", text: "6662" }, 
    { id: "6663", text: "6663" }, 
    { id: "6666", text: "6666" }, 
    { id: "6670", text: "6670" }, 
    { id: "6671", text: "6671" }, 
    { id: "6672", text: "6672" }, 
    { id: "6673", text: "6673" }, 
    { id: "6674", text: "6674" }, 
    { id: "6680", text: "6680" }, 
    { id: "6681", text: "6681" }, 
    { id: "6686", text: "6686" }, 
    { id: "6687", text: "6687" }, 
    { id: "6688", text: "6688" }, 
    { id: "6690", text: "6690" }, 
    { id: "6692", text: "6692" }, 
    { id: "6698", text: "6698" }, 
    { id: "6700", text: "6700" }, 
    { id: "6704", text: "6704" }, 
    { id: "6706", text: "6706" }, 
    { id: "6717", text: "6717" }, 
    { id: "6720", text: "6720" }, 
    { id: "6721", text: "6721" }, 
    { id: "6723", text: "6723" }, 
    { id: "6724", text: "6724" }, 
    { id: "6730", text: "6730" }, 
    { id: "6740", text: "6740" }, 
    { id: "6741", text: "6741" }, 
    { id: "6742", text: "6742" }, 
    { id: "6743", text: "6743" }, 
    { id: "6747", text: "6747" }, 
    { id: "6750", text: "6750" }, 
    { id: "6760", text: "6760" }, 
    { id: "6761", text: "6761" }, 
    { id: "6762", text: "6762" }, 
    { id: "6767", text: "6767" }, 
    { id: "6769", text: "6769" }, 
    { id: "6780", text: "6780" }, 
    { id: "6781", text: "6781" }, 
    { id: "6782", text: "6782" }, 
    { id: "6790", text: "6790" }, 
    { id: "6791", text: "6791" }, 
    { id: "6792", text: "6792" }, 
    { id: "6800", text: "6800" }, 
    { id: "6810", text: "6810" }, 
    { id: "6811", text: "6811" }, 
    { id: "6812", text: "6812" }, 
    { id: "6813", text: "6813" }, 
    { id: "6820", text: "6820" }, 
    { id: "6821", text: "6821" }, 
    { id: "6823", text: "6823" }, 
    { id: "6824", text: "6824" }, 
    { id: "6830", text: "6830" }, 
    { id: "6831", text: "6831" }, 
    { id: "6832", text: "6832" }, 
    { id: "6833", text: "6833" }, 
    { id: "6834", text: "6834" }, 
    { id: "6836", text: "6836" }, 
    { id: "6838", text: "6838" }, 
    { id: "6840", text: "6840" }, 
    { id: "6850", text: "6850" }, 
    { id: "6851", text: "6851" }, 
    { id: "6852", text: "6852" }, 
    { id: "6853", text: "6853" }, 
    { id: "6856", text: "6856" }, 
    { id: "6860", text: "6860" }, 
    { id: "6870", text: "6870" }, 
    { id: "6880", text: "6880" }, 
    { id: "6887", text: "6887" }, 
    { id: "6890", text: "6890" }, 
    { id: "6900", text: "6900" }, 
    { id: "6920", text: "6920" }, 
    { id: "6921", text: "6921" }, 
    { id: "6922", text: "6922" }, 
    { id: "6924", text: "6924" }, 
    { id: "6927", text: "6927" }, 
    { id: "6929", text: "6929" }, 
    { id: "6940", text: "6940" }, 
    { id: "6941", text: "6941" }, 
    { id: "6950", text: "6950" }, 
    { id: "6951", text: "6951" }, 
    { id: "6952", text: "6952" }, 
    { id: "6953", text: "6953" }, 
    { id: "6960", text: "6960" }, 
    { id: "6970", text: "6970" }, 
    { id: "6971", text: "6971" }, 
    { id: "6972", text: "6972" }, 
    { id: "6980", text: "6980" }, 
    { id: "6982", text: "6982" }, 
    { id: "6983", text: "6983" }, 
    { id: "6984", text: "6984" }, 
    { id: "6986", text: "6986" }, 
    { id: "6987", text: "6987" }, 
    { id: "6990", text: "6990" }, 
    { id: "6997", text: "6997" }, 
    { id: "7000", text: "7000" }, 
    { id: "7010", text: "7010" }, 
    { id: "7011", text: "7011" }, 
    { id: "7012", text: "7012" }, 
    { id: "7020", text: "7020" }, 
    { id: "7021", text: "7021" }, 
    { id: "7022", text: "7022" }, 
    { id: "7024", text: "7024" }, 
    { id: "7030", text: "7030" }, 
    { id: "7031", text: "7031" }, 
    { id: "7032", text: "7032" }, 
    { id: "7033", text: "7033" }, 
    { id: "7034", text: "7034" }, 
    { id: "7040", text: "7040" }, 
    { id: "7041", text: "7041" }, 
    { id: "7050", text: "7050" }, 
    { id: "7060", text: "7060" }, 
    { id: "7061", text: "7061" }, 
    { id: "7062", text: "7062" }, 
    { id: "7063", text: "7063" }, 
    { id: "7070", text: "7070" }, 
    { id: "7080", text: "7080" }, 
    { id: "7090", text: "7090" }, 
    { id: "7100", text: "7100" }, 
    { id: "7110", text: "7110" }, 
    { id: "7120", text: "7120" }, 
    { id: "7130", text: "7130" }, 
    { id: "7131", text: "7131" }, 
    { id: "7133", text: "7133" }, 
    { id: "7134", text: "7134" }, 
    { id: "7140", text: "7140" }, 
    { id: "7141", text: "7141" }, 
    { id: "7160", text: "7160" }, 
    { id: "7170", text: "7170" }, 
    { id: "7180", text: "7180" }, 
    { id: "7181", text: "7181" }, 
    { id: "7190", text: "7190" }, 
    { id: "7191", text: "7191" }, 
    { id: "7300", text: "7300" }, 
    { id: "7301", text: "7301" }, 
    { id: "7320", text: "7320" }, 
    { id: "7321", text: "7321" }, 
    { id: "7322", text: "7322" }, 
    { id: "7330", text: "7330" }, 
    { id: "7331", text: "7331" }, 
    { id: "7332", text: "7332" }, 
    { id: "7333", text: "7333" }, 
    { id: "7334", text: "7334" }, 
    { id: "7340", text: "7340" }, 
    { id: "7350", text: "7350" }, 
    { id: "7370", text: "7370" }, 
    { id: "7380", text: "7380" }, 
    { id: "7382", text: "7382" }, 
    { id: "7387", text: "7387" }, 
    { id: "7390", text: "7390" }, 
    { id: "7500", text: "7500" }, 
    { id: "7501", text: "7501" }, 
    { id: "7502", text: "7502" }, 
    { id: "7503", text: "7503" }, 
    { id: "7504", text: "7504" }, 
    { id: "7506", text: "7506" }, 
    { id: "7520", text: "7520" }, 
    { id: "7521", text: "7521" }, 
    { id: "7522", text: "7522" }, 
    { id: "7530", text: "7530" }, 
    { id: "7531", text: "7531" }, 
    { id: "7532", text: "7532" }, 
    { id: "7533", text: "7533" }, 
    { id: "7534", text: "7534" }, 
    { id: "7536", text: "7536" }, 
    { id: "7538", text: "7538" }, 
    { id: "7540", text: "7540" }, 
    { id: "7542", text: "7542" }, 
    { id: "7543", text: "7543" }, 
    { id: "7548", text: "7548" }, 
    { id: "7600", text: "7600" }, 
    { id: "7601", text: "7601" }, 
    { id: "7602", text: "7602" }, 
    { id: "7603", text: "7603" }, 
    { id: "7604", text: "7604" }, 
    { id: "7608", text: "7608" }, 
    { id: "7610", text: "7610" }, 
    { id: "7611", text: "7611" }, 
    { id: "7618", text: "7618" }, 
    { id: "7620", text: "7620" }, 
    { id: "7621", text: "7621" }, 
    { id: "7622", text: "7622" }, 
    { id: "7623", text: "7623" }, 
    { id: "7624", text: "7624" }, 
    { id: "7640", text: "7640" }, 
    { id: "7641", text: "7641" }, 
    { id: "7642", text: "7642" }, 
    { id: "7643", text: "7643" }, 
    { id: "7700", text: "7700" }, 
    { id: "7711", text: "7711" }, 
    { id: "7712", text: "7712" }, 
    { id: "7730", text: "7730" }, 
    { id: "7740", text: "7740" }, 
    { id: "7742", text: "7742" }, 
    { id: "7743", text: "7743" }, 
    { id: "7750", text: "7750" }, 
    { id: "7760", text: "7760" }, 
    { id: "7780", text: "7780" }, 
    { id: "7781", text: "7781" }, 
    { id: "7782", text: "7782" }, 
    { id: "7783", text: "7783" }, 
    { id: "7784", text: "7784" }, 
    { id: "7800", text: "7800" }, 
    { id: "7801", text: "7801" }, 
    { id: "7802", text: "7802" }, 
    { id: "7803", text: "7803" }, 
    { id: "7804", text: "7804" }, 
    { id: "7810", text: "7810" }, 
    { id: "7811", text: "7811" }, 
    { id: "7812", text: "7812" }, 
    { id: "7822", text: "7822" }, 
    { id: "7823", text: "7823" }, 
    { id: "7830", text: "7830" }, 
    { id: "7850", text: "7850" }, 
    { id: "7860", text: "7860" }, 
    { id: "7861", text: "7861" }, 
    { id: "7862", text: "7862" }, 
    { id: "7863", text: "7863" }, 
    { id: "7864", text: "7864" }, 
    { id: "7866", text: "7866" }, 
    { id: "7870", text: "7870" }, 
    { id: "7880", text: "7880" }, 
    { id: "7890", text: "7890" }, 
    { id: "7900", text: "7900" }, 
    { id: "7901", text: "7901" }, 
    { id: "7903", text: "7903" }, 
    { id: "7904", text: "7904" }, 
    { id: "7906", text: "7906" }, 
    { id: "7910", text: "7910" }, 
    { id: "7911", text: "7911" }, 
    { id: "7912", text: "7912" }, 
    { id: "7940", text: "7940" }, 
    { id: "7941", text: "7941" }, 
    { id: "7942", text: "7942" }, 
    { id: "7943", text: "7943" }, 
    { id: "7950", text: "7950" }, 
    { id: "7951", text: "7951" }, 
    { id: "7970", text: "7970" }, 
    { id: "7971", text: "7971" }, 
    { id: "7972", text: "7972" }, 
    { id: "7973", text: "7973" }, 
    { id: "8000", text: "8000" }, 
    { id: "8020", text: "8020" }, 
    { id: "8200", text: "8200" }, 
    { id: "8210", text: "8210" }, 
    { id: "8211", text: "8211" }, 
    { id: "8300", text: "8300" }, 
    { id: "8301", text: "8301" }, 
    { id: "8310", text: "8310" }, 
    { id: "8340", text: "8340" }, 
    { id: "8370", text: "8370" }, 
    { id: "8377", text: "8377" }, 
    { id: "8380", text: "8380" }, 
    { id: "8400", text: "8400" }, 
    { id: "8420", text: "8420" }, 
    { id: "8421", text: "8421" }, 
    { id: "8430", text: "8430" }, 
    { id: "8431", text: "8431" }, 
    { id: "8432", text: "8432" }, 
    { id: "8433", text: "8433" }, 
    { id: "8434", text: "8434" }, 
    { id: "8450", text: "8450" }, 
    { id: "8460", text: "8460" }, 
    { id: "8470", text: "8470" }, 
    { id: "8480", text: "8480" }, 
    { id: "8490", text: "8490" }, 
    { id: "8500", text: "8500" }, 
    { id: "8501", text: "8501" }, 
    { id: "8510", text: "8510" }, 
    { id: "8511", text: "8511" }, 
    { id: "8520", text: "8520" }, 
    { id: "8530", text: "8530" }, 
    { id: "8531", text: "8531" }, 
    { id: "8540", text: "8540" }, 
    { id: "8550", text: "8550" }, 
    { id: "8551", text: "8551" }, 
    { id: "8552", text: "8552" }, 
    { id: "8553", text: "8553" }, 
    { id: "8554", text: "8554" }, 
    { id: "8560", text: "8560" }, 
    { id: "8570", text: "8570" }, 
    { id: "8572", text: "8572" }, 
    { id: "8573", text: "8573" }, 
    { id: "8580", text: "8580" }, 
    { id: "8581", text: "8581" }, 
    { id: "8582", text: "8582" }, 
    { id: "8583", text: "8583" }, 
    { id: "8587", text: "8587" }, 
    { id: "8600", text: "8600" }, 
    { id: "8610", text: "8610" }, 
    { id: "8620", text: "8620" }, 
    { id: "8630", text: "8630" }, 
    { id: "8640", text: "8640" }, 
    { id: "8647", text: "8647" }, 
    { id: "8650", text: "8650" }, 
    { id: "8660", text: "8660" }, 
    { id: "8670", text: "8670" }, 
    { id: "8680", text: "8680" }, 
    { id: "8690", text: "8690" }, 
    { id: "8691", text: "8691" }, 
    { id: "8700", text: "8700" }, 
    { id: "8710", text: "8710" }, 
    { id: "8720", text: "8720" }, 
    { id: "8730", text: "8730" }, 
    { id: "8740", text: "8740" }, 
    { id: "8750", text: "8750" }, 
    { id: "8755", text: "8755" }, 
    { id: "8760", text: "8760" }, 
    { id: "8770", text: "8770" }, 
    { id: "8780", text: "8780" }, 
    { id: "8790", text: "8790" }, 
    { id: "8791", text: "8791" }, 
    { id: "8792", text: "8792" }, 
    { id: "8793", text: "8793" }, 
    { id: "8800", text: "8800" }, 
    { id: "8810", text: "8810" }, 
    { id: "8820", text: "8820" }, 
    { id: "8830", text: "8830" }, 
    { id: "8840", text: "8840" }, 
    { id: "8850", text: "8850" }, 
    { id: "8851", text: "8851" }, 
    { id: "8860", text: "8860" }, 
    { id: "8870", text: "8870" }, 
    { id: "8880", text: "8880" }, 
    { id: "8890", text: "8890" }, 
    { id: "8900", text: "8900" }, 
    { id: "8902", text: "8902" }, 
    { id: "8904", text: "8904" }, 
    { id: "8906", text: "8906" }, 
    { id: "8908", text: "8908" }, 
    { id: "8920", text: "8920" }, 
    { id: "8930", text: "8930" }, 
    { id: "8940", text: "8940" }, 
    { id: "8950", text: "8950" }, 
    { id: "8951", text: "8951" }, 
    { id: "8952", text: "8952" }, 
    { id: "8953", text: "8953" }, 
    { id: "8954", text: "8954" }, 
    { id: "8956", text: "8956" }, 
    { id: "8957", text: "8957" }, 
    { id: "8958", text: "8958" }, 
    { id: "8970", text: "8970" }, 
    { id: "8972", text: "8972" }, 
    { id: "8978", text: "8978" }, 
    { id: "8980", text: "8980" }, 
    { id: "9000", text: "9000" }, 
    { id: "9030", text: "9030" }, 
    { id: "9031", text: "9031" }, 
    { id: "9032", text: "9032" }, 
    { id: "9040", text: "9040" }, 
    { id: "9041", text: "9041" }, 
    { id: "9042", text: "9042" }, 
    { id: "9050", text: "9050" }, 
    { id: "9051", text: "9051" }, 
    { id: "9052", text: "9052" }, 
    { id: "9060", text: "9060" }, 
    { id: "9070", text: "9070" }, 
    { id: "9080", text: "9080" }, 
    { id: "9090", text: "9090" }, 
    { id: "9100", text: "9100" }, 
    { id: "9111", text: "9111" }, 
    { id: "9112", text: "9112" }, 
    { id: "9120", text: "9120" }, 
    { id: "9130", text: "9130" }, 
    { id: "9140", text: "9140" }, 
    { id: "9150", text: "9150" }, 
    { id: "9160", text: "9160" }, 
    { id: "9170", text: "9170" }, 
    { id: "9180", text: "9180" }, 
    { id: "9185", text: "9185" }, 
    { id: "9190", text: "9190" }, 
    { id: "9200", text: "9200" }, 
    { id: "9220", text: "9220" }, 
    { id: "9230", text: "9230" }, 
    { id: "9240", text: "9240" }, 
    { id: "9250", text: "9250" }, 
    { id: "9255", text: "9255" }, 
    { id: "9260", text: "9260" }, 
    { id: "9270", text: "9270" }, 
    { id: "9280", text: "9280" }, 
    { id: "9290", text: "9290" }, 
    { id: "9300", text: "9300" }, 
    { id: "9308", text: "9308" }, 
    { id: "9310", text: "9310" }, 
    { id: "9320", text: "9320" }, 
    { id: "9340", text: "9340" }, 
    { id: "9400", text: "9400" }, 
    { id: "9401", text: "9401" }, 
    { id: "9402", text: "9402" }, 
    { id: "9403", text: "9403" }, 
    { id: "9404", text: "9404" }, 
    { id: "9406", text: "9406" }, 
    { id: "9420", text: "9420" }, 
    { id: "9450", text: "9450" }, 
    { id: "9451", text: "9451" }, 
    { id: "9470", text: "9470" }, 
    { id: "9472", text: "9472" }, 
    { id: "9473", text: "9473" }, 
    { id: "9500", text: "9500" }, 
    { id: "9506", text: "9506" }, 
    { id: "9520", text: "9520" }, 
    { id: "9521", text: "9521" }, 
    { id: "9550", text: "9550" }, 
    { id: "9551", text: "9551" }, 
    { id: "9552", text: "9552" }, 
    { id: "9570", text: "9570" }, 
    { id: "9571", text: "9571" }, 
    { id: "9572", text: "9572" }, 
    { id: "9600", text: "9600" }, 
    { id: "9620", text: "9620" }, 
    { id: "9630", text: "9630" }, 
    { id: "9636", text: "9636" }, 
    { id: "9660", text: "9660" }, 
    { id: "9661", text: "9661" }, 
    { id: "9667", text: "9667" }, 
    { id: "9680", text: "9680" }, 
    { id: "9681", text: "9681" }, 
    { id: "9688", text: "9688" }, 
    { id: "9690", text: "9690" }, 
    { id: "9700", text: "9700" }, 
    { id: "9750", text: "9750" }, 
    { id: "9770", text: "9770" }, 
    { id: "9771", text: "9771" }, 
    { id: "9772", text: "9772" }, 
    { id: "9790", text: "9790" }, 
    { id: "9800", text: "9800" }, 
    { id: "9810", text: "9810" }, 
    { id: "9820", text: "9820" }, 
    { id: "9830", text: "9830" }, 
    { id: "9831", text: "9831" }, 
    { id: "9840", text: "9840" }, 
    { id: "9850", text: "9850" }, 
    { id: "9860", text: "9860" }, 
    { id: "9870", text: "9870" }, 
    { id: "9880", text: "9880" }, 
    { id: "9881", text: "9881" }, 
    { id: "9890", text: "9890" }, 
    { id: "9900", text: "9900" }, 
    { id: "9910", text: "9910" }, 
    { id: "9920", text: "9920" }, 
    { id: "9921", text: "9921" }, 
    { id: "9930", text: "9930" }, 
    { id: "9931", text: "9931" }, 
    { id: "9932", text: "9932" }, 
    { id: "9940", text: "9940" }, 
    { id: "9950", text: "9950" }, 
    { id: "9960", text: "9960" }, 
    { id: "9961", text: "9961" }, 
    { id: "9968", text: "9968" }, 
    { id: "9970", text: "9970" }, 
    { id: "9971", text: "9971" }, 
    { id: "9980", text: "9980" }, 
    { id: "9981", text: "9981" }, 
    { id: "9982", text: "9982" }, 
    { id: "9988", text: "9988" }, 
    { id: "9990", text: "9990" }, 
    { id: "9991", text: "9991" }, 
    { id: "9992", text: "9992" }
]

export const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      partialVisibilityGutter: 40,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 786 },
      items: 1,
      partialVisibilityGutter: 20,
    },
    mobile: {
      breakpoint: { max: 786, min: 0 },
      items: 1,
    },
  };
 export  const carusalData = [
    {
      id: 1,
      name: "Michel P",
      title: "Achat d'une maison",
      descreption:
        "Lors de l'achat de ma maison, j'ai été agréablement surpris du suivis complet de toute l'équipe durant tout le procéssus et ce même après l'acte. Un grand merci à vous!",
    },
    {
      id: 2,
      name: "Eloïse D",
      title: "Vendre une villa",
      descreption:
        "Nous avons fait appel à Monsieur Ricci et son équipe pour la vente de notre belle villa. Service très complet et professionel! Un suivis très agréable jusqu'à l'acte. Encore un grand merci pour tout!",
    },
    {
      id: 3,
      name: "Andrea B",
      title: "Louer un appartement",
      descreption:
        "Service incroyable dans l'ensemble. J'ai acheté et loué un appartement via eux, je ne pouvais pas demander plus ! Hautement recommandé.",
    },
    {
      id: 4,
      name: "Patricia F",
      title:
        "Demande de conseils immobilier (acheter louer et service gestion locative)",
      descreption:
        "J'ai pris contact avec cette agence qui m'avait été recommandée afin d'être accompagnée et conseillée pour l'achat de mon premier bien d'investissement. Conseil et suivis parfait! Ils gèrent dès à présent la location et la gestion de mon bel appartement.",
    },
  ];