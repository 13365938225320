import React from "react";
import { Divider, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./card.scss";

const CardComponent = (props) => {
  const navigate = useNavigate();
  const { data } = props;

  const getCategory = id => {
    switch (id) {
      case 1: 
        return 'Maison';
      case 2: 
        return 'Appartement';
      case 3: 
        return 'Terrain';
      case 4: 
        return 'Bureau';
      case 5: 
        return 'Commerce';
      case 6: 
        return 'Immeuble de rapport';
      case 7:  
        return 'Garage';
      default: 
        return '';
    }
  }

  const getPurpose = id => {
    switch(id) {
      case 1: 
        return 'à vendre';
      case 2: 
        return 'à louer';
      case 3: 
        return 'vendu';
      case 4: 
        return 'loué';
      case 5: 
        return 'option v.';
      case 6: 
        return 'option l.';
      case 7: 
        return 'hors vente';
      case 8: 
        return 'hors location';
      case 9: 
        return 'vente suspendue';
      case 10: 
        return 'location suspendue';
      case 11: 
        return 'option propriétaire v.';
      case 12: 
        return 'option propriétaire l.';
      case 13: 
        return 'Vendu sous compromis';
      case 14: 
        return 'à vendre';
      case 15: 
        return 'sous offre v.';
      case 16: 
        return 'Vendu sous compromis';
      case 17: 
        return 'prospection';
      case 18: 
        return 'préparation';
      case 19: 
        return 'reservé';
      case 20: 
        return 'brouillon de contrat';
      case 21: 
        return 'prospection l.';
      case 22: 
        return 'estimation v.';
      case 23: 
        return 'estimation l.';
      case 24: 
        return 'estimation l.a.';
      default: 
        return 'à vendre';
    }
  }

  const isNew = () => {
    const createdDatetime = data?.createDateTime || "";
    let date = createdDatetime.split("T");
    date = date[0];
    date = date.split("-")
    const createdDatetimeObject = new Date(date[0], parseInt(date[1]) - 1, date[2], 12);
    const currentDatetime = new Date();
    if((createdDatetimeObject.getTime()/1000) >= ((currentDatetime.getTime()/1000) - 1209600)) {
      return true;
    }
    else {
      return false;
    }
  }

  return (
    <div
      class="h-full w-full bg-white rounded-[10px] card-shadow border-gray-700 cursor-pointer"
      onClick={() => {
        // navigate("/detail-page");
        navigate(`/detail-page/${data.id}`);
      }}
    >
      <div
        className="image-div h-56"
        style={{ backgroundImage: data?.pictures?.[0]?.urlLarge && `url(${data?.pictures?.[0]?.urlLarge})` }}
      >
        <div className="h-full">
          <div className="image-content-main">
            <div className = "flex">
              {isNew() && (
                <div className="w-[84px] h-[32px] flex justify-center items-center rounded-[5px] font-bold top-badge mt-[20px] mr-[20px]">
                  <p className = "text-[14px] font-[OpenSans] text-white">{'Nouveau'}</p>
                </div>
              )}
            </div>
            <div className="bg-white text-white text-[16px] px-[36px] py-[12px] font-bold font-[OpenSans] rounded-tl-[12px] top-badge">
              {getPurpose(data?.purposeStatus?.id || 1)}
            </div>
          </div>
        </div>
      </div>
      <div class="p-5 bg-white rounded-b-[10px]">
        <div className="flex justify-between font-[OpenSans] items-center">
          <h1 className="text-[16px] font-bold h-auto" style = {{width: '50%'}}>{data?.name}</h1>
          <h1 className="text-[#DFBBA2] md:text-[26px]  font-bold ">
            {data?.price?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + " " + data?.currency}
          </h1>
        </div>
        <div className="flex justify-between  font-[OpenSans] font-normal text-[14px] pt-[10px]">
          <p>{data?.city + " " + data?.zip}</p>
          <p className=" text-[#949AA4]">Ref. {data?.referenceNumber}</p>
        </div>
        <div className="flex justify-between text-[#949AA4] text-[14px] font-[OpenSans] pt-[10px] items-center">
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
          >
            <h1>{getCategory(data?.category?.id)}</h1>
            <h1>{data?.rooms || 0} ch.</h1>
            <h1>{data?.area} m<sup>2</sup></h1>
          </Stack>
          <div>
            <img className="h-[36px]" src={data?.footer?.image} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardComponent;
