import React, { useEffect, useState, useCallback } from "react";
import { Container, Grid, Snackbar } from "@mui/material";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Carousel as MobileCarousel } from "react-responsive-carousel";
import BreakpointController from "../breakpoint_controller/breakpoint_controller";
import { FacebookShareButton, FacebookIcon, EmailIcon } from "react-share";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@mui/material/Card";
import Badge from "../detail-page/Badge";
import Gallery from "react-photo-gallery";

// image imports here
import first from "../../assets/images/general/optimised/detail-page-image_small.jpg";
import virtual from "../../assets/icons/virtual.svg";
import share from "../../assets/icons/share.svg";
import location from "../../assets/icons/location.svg";
import bed from "../../assets/icons/bed.svg";
import water from "../../assets/icons/water.svg";
import terrain from "../../assets/icons/terrain.svg";
import terrain2 from "../../assets/icons/terrain_2.svg";
import Copy from "../../assets/icons/social/copy.png";

import "./detailPage.scss";

const eStateIds = [
  { id: 1, name: "nouveau" },
  { id: 2, name: "très bon état" },
  { id: 3, name: "rénové" },
  { id: 4, name: "bon état" },
  { id: 5, name: "à rafraîchir" },
  { id: 6, name: "à rénover" },
  { id: 7, name: "à reconstruire" },
  { id: 8, name: "à démolir" },
  { id: 9, name: "inconnue" },
  { id: 10, name: "domaine vert" },
  { id: 11, name: "à construire" },
  { id: 12, name: "travail principal " },
  { id: 13, name: "nouveau développement" },
  { id: 14, name: "petits travaux" },
  { id: 15, name: "rénové" },
  { id: 16, name: "concept" },
  { id: 17, name: "développé" },
  { id: 18, name: "prêt à commencer les travaux" },
  { id: 19, name: "murs fermés" },
  { id: 20, name: "construction achevée" },
  { id: 21, name: "livré" },
  { id: 22, name: "platré" },
  { id: 23, name: "terrain en préparation" },
  { id: 24, name: "terrain prêt à construire" },
];

const estateAvailabilty = [
  { id: 1, name: "au contrat " },
  { id: 2, name: "à discuter avec le propriétaire" },
  { id: 3, name: "à discuter avec le locataire " },
  { id: 4, name: "pas disponible " },
  { id: 5, name: "immédiatement" },
  { id: 6, name: "à la livraison" },
  { id: 7, name: "à convenir " },
];

const getLabelHandler = (data, key) => {
  let val = false;
  data.filter((item) => {
    if (item.label == key && item.value != null) {
      return (val = item.value);
    }
  });
  return val;
};

const getDetailsHandler = (data, key) => {
  let val = false;
  if (data[`${key}`]) {
    return (val = data[`${key}`]);
  }
  return val;
};

export default function FirstSection(props) {
  const [filterDataState, setFilterDataState] = useState([]);
  const [shareModal, setShareModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const { DataState, estateId, setShow, setRefState } = props;
  const refProp = filterDataState[0]?.referenceNumber;

  useEffect(() => {
    setFilterDataState(DataState.filter((obj) => obj.id == estateId));
  }, [DataState]);

  let images = [];
  let firstSkippedImages = [];
  let secondSkippedImages  = [];
  let thirdSkippedImages  = [];
  let forthSkippedImages  = [];
  if (filterDataState.length > 0) {
    filterDataState[0].pictures.forEach(function (value, index) {
      let imageObj = {
        src: `${filterDataState[0].pictures[index]["urlLarge"]}`,
        thumbnail: `${filterDataState[0].pictures[index]["urlLarge"]}`,
      };
      images.push(imageObj);
    });
  }

  for (var i = 1; i < images.length; i++) {
    if (filterDataState.length > 0) {
      const skipedImageObj = {
        src: `${filterDataState[0].pictures[i]["urlLarge"]}`,
        thumbnail: `${filterDataState[0].pictures[i]["urlLarge"]}`,
      };
      firstSkippedImages.push(skipedImageObj);
      if(i > 1){
        secondSkippedImages.push(skipedImageObj)
      }
      if(i > 2){
        thirdSkippedImages.push(skipedImageObj)
      }
      if(i > 3){
        forthSkippedImages.push(skipedImageObj)
      }
    }
  }


  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }));

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const openLightbox1 = () => {
    setCurrentImage(1);
    setViewerIsOpen(true);
  };

  const openLightbox2 = useCallback((event, { photo, index }) => {
    setCurrentImage(2);
    setViewerIsOpen(true);
  }, []);

  const openLightbox3 = useCallback((event, { photo, index }) => {
    setCurrentImage(3);
    setViewerIsOpen(true);
  }, []);

  const openLightbox4 = useCallback((event, { photo, index }) => {
    setCurrentImage(4);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  // fucntion for copy confirmation url
  const handleClick = () => {
    setConfirmationModal(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setConfirmationModal(false);
  };
  // function to copy url
  function copy(e) {
    const el = document.createElement("textarea");
    el.value = `https://frontend.develop.ricci.flykube.agifly.cloud/detail-page/${estateId}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    handleClick();
    setShareModal(false);
  }
  return (
    <Container>
      <Snackbar
        open={confirmationModal}
        autoHideDuration={2000}
        onClose={handleClose}
        message="Copied"
      />
      {/* First Section */}
      <BreakpointController breakpoint="sm" direction="down">
        <div class="grid lg:col-12 md:col-12 xs:col-12 sm:col-12 sm:gap-4 mt-16">
          <div class="grid grid-cols-4  gap-4 h-96">
            <div className="image-full-screen col-span-2 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer">
              <div>
                <Gallery photos={images} onClick={openLightbox} />
                <ModalGateway>
                  {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                      <Carousel
                        currentIndex={currentImage}
                        views={images.map((x) => ({
                          ...x,
                          srcset: x.srcSet,
                        }))}
                      />
                    </Modal>
                  ) : null}
                </ModalGateway>
              </div>
            </div>

            <div
                className={`relative rounded-md sm:rounded-xl overflow-hidden block`}
              >
                {firstSkippedImages && firstSkippedImages.length > 0 ? 
                <Gallery photos={firstSkippedImages} onClick={openLightbox1} />:false}
              </div>
              <div
                className={`relative rounded-md sm:rounded-xl overflow-hidden block`}
              >
                {secondSkippedImages && secondSkippedImages.length > 0 ? 
                <Gallery photos={secondSkippedImages} columns={5} onClick={openLightbox2} />:false}
              </div>
              <div
                className={`relative rounded-md sm:rounded-xl overflow-hidden block`}
              >
                {thirdSkippedImages && thirdSkippedImages.length > 0 ? 
                <Gallery photos={thirdSkippedImages} onClick={openLightbox3} />:false}
              </div>
              <div
                className={`relative rounded-md sm:rounded-xl overflow-hidden block`}
              >
                {forthSkippedImages && forthSkippedImages.length > 0 ? 
                <Gallery photos={forthSkippedImages} onClick={openLightbox4} />:false}
              </div>


            {/* {PHOTOS.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                  index >= 2 ? "block" : ""
                }`}
              >
                
                <Gallery photos={images} onClick={openLightbox} />
              </div>
            ))} */}
          </div>
        </div>
      </BreakpointController>
      {/* for mobile */}
      <BreakpointController breakpoint="sm" direction="up">
        <Grid container>
          <div className="mt-[60px]" />
        </Grid>
        <MobileCarousel photos={images} onClick={openLightbox}>
          {images &&
            images.length > 0 &&
            images.map((image) => (
              <div>
                <img src={image?.src} alt="photo" />
              </div>
            ))}
        </MobileCarousel>
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={images.map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </BreakpointController>
      {/* Second Section */}
      <div class="grid lg:col-12 md:lg:col-12 xs:lg:col-12 pt-8 sm:gap-4">
        <div class="grid lg:col-start-1 lg:col-span-7 md:col-start-1 md:col-span-7 sm:col-start-1 sm:col-span-7 xs:col-start-1 xs:col-span-7 gap-4">
          <Card className="shadow radius p-[20px] space-y-4">
            <Grid>
              <div className="flex justify-between items-center">
                <Badge name="Nouveau" />
                <div>
                  <div className="flow-root">
                    <div className="flex text-neutral-700 text-neutral-300 text-sm -mx-3 -my-1.5">
                      {/* {filterDataState.length > 0 && filterDataState[0].virtual?
                        (<span className="py-1.5 px-3 flex rounded-lg  hover:bg-neutral-800 cursor-pointer">
                          <img src={virtual} className="h-6" />
                          <span className="hidden sm:block ml-2.5">
                            Visite virtuelle
                          </span>
                        </span>)
                       : null} */}
                      <span
                        className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-800 cursor-pointer"
                        onClick={() => {
                          shareModal
                            ? setShareModal(false)
                            : setShareModal(true);
                        }}
                      >
                        <img src={share} className="h-6" />
                        <span className="hidden sm:block ml-2.5">Partager</span>
                      </span>
                      {/*  */}
                      {shareModal && (
                        <Card className="shadow-xl border bg-white absolute mt-10 rounded p-[20px] space-x-4 space-y-2 p-4">
                          <div className="flex flex-col">
                            <div
                              className="flex items-center cursor-pointer"
                              onClick={() => setShareModal(false)}
                            >
                              <a
                                className="flex"
                                href={`mailto:hello@riccirealestate.be?subject=J'aimerais plus d'informations pour le bien ref ${
                                  filterDataState.length > 0
                                    ? filterDataState[0].referenceNumber
                                    : ""
                                }.&body=`}
                              >
                                <EmailIcon className="h-6 w-6" round={true} />
                                <span className="px-2 font-[OpenSans] text-[#9E9E9E] text-[14px] ">
                                  Partager par mail
                                </span>
                              </a>
                            </div>
                            <div
                              className="py-3 flex items-center cursor-pointer"
                              onClick={() => setShareModal(false)}
                            >
                              <FacebookShareButton
                                url={`https://frontend.develop.ricci.flykube.agifly.cloud/detail-page/${estateId}`}
                                quotes={"quotes"} //"Your Quotes"
                                hashtag={"#"} // #hashTag
                                className="flex"
                              >
                                <FacebookIcon
                                  className="h-6 w-6"
                                  round={true}
                                />
                                <span className="px-2 font-[OpenSans] text-[#9E9E9E] text-[14px] ">
                                  Partager sur Facebook
                                </span>
                              </FacebookShareButton>
                              {/* <img src={Facebook} alt="" className="h-5" /> */}
                            </div>
                            <button
                              className="flex items-center cursor-pointer"
                              onClick={(e) => copy(e)}
                            >
                              <img src={Copy} alt="" className="h-6 w-6" />
                              <span className="px-2 font-[OpenSans] text-[#9E9E9E] text-[14px] ">
                                Copier le lien
                              </span>
                            </button>
                          </div>
                        </Card>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid className="flex justify-between">
              <p className="font-[Poppins] font-bold text-2xl lg:text-[40px]  text-left">
                {filterDataState.length > 0 && filterDataState[0].name}
              </p>
              <p className="hidden lg:block md:block sm:block heading-linear-price font-[Poppins] textcolor text-right  font-semibold text-2xl lg:text-[40px] ">
                {filterDataState.length > 0 &&
                  filterDataState[0]?.price
                    ?.toString()
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
                €
              </p>
            </Grid>
            <Grid className="">
              <div className="flex items-center space-x-4 justify-between">
              {filterDataState.length > 0 && filterDataState[0].displayAddress == true?
                (<span className=" flex rounded-lg ">
                  <img src={location} className="h-6" alt="" />
                  <span className=" sm:block ml-2.5 text-[#666666]">
                     {filterDataState[0].address} {filterDataState[0].number}, {filterDataState[0].zip} {filterDataState[0].city}
                  </span>
                </span>)
                :(<span className="invisible flex rounded-lg ">
                <img src={location} className="h-6" alt="" />
                <span className="hidden">
                </span>
              </span>)}
                <span className=" flex rounded-lg text-right">
                  <span className="text-[#666666]">
                    Ref.{" "}
                    {filterDataState.length > 0 &&
                      filterDataState[0].referenceNumber}
                  </span>
                </span>
              </div>
            </Grid>
           
            <Grid>
              {" "}
              <hr></hr>
            </Grid>
            <Grid>
              <div className="flex items-center xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 text-neutral-300">
                <div className="flex items-center space-x-3 ">
                  <img src={bed} className="h-6" alt="" />
                  <span>
                    {filterDataState.length > 0 && filterDataState[0].rooms}
                    <span className="lg:contents md:hidden sm:hidden hidden sm:inline-block">
                      &nbsp;chambres
                    </span>
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <img src={water} className="h-6" alt="" />
                  <span>
                    {filterDataState.length > 0 && filterDataState[0].bathRooms}
                    <span className="lg:contents md:hidden sm:hidden hidden sm:inline-block">
                      &nbsp;salles de bains
                    </span>
                  </span>
                </div>
                <div className="flex items-center space-x-3">
                  <img src={terrain2} className="h-6" alt="" />
                  <span>
                    {filterDataState.length > 0 && filterDataState[0].area} m²
                    <span className="lg:contents md:hidden sm:hidden hidden sm:inline-block">
                      &nbsp;habitables
                    </span>
                  </span>
                </div>
                <div className="flex items-center space-x-3">
                  <img src={terrain} className="h-6" alt="" />
                  <span>
                    {filterDataState.length > 0 &&
                      filterDataState[0].groundArea}{" "}
                    m²
                    <span className="lg:contents md:hidden sm:hidden hidden sm:inline-block">
                      &nbsp;terrain
                    </span>
                  </span>
                </div>
              </div>
            </Grid>
          </Card>
        </div>
        <Grid className="h-[70px] block lg:hidden md:hidden sm:hidden fixed w-full bottom-0 left-0 bg-white border-t border-t border-t z-20 px-4">
          <div className="flex justify-between items-center h-full">
            <p className="font-[Poppins] font-semibold text-2xl ">
              {filterDataState.length > 0 &&
                filterDataState[0]?.price
                  ?.toString()
                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
              ,00 €
            </p>
            <button className="bg-[#4f46e5] font-[Poppins] font-medium px-4 py-3 rounded-full text-neutral-50 text-sm"
            onClick={() => {
              setShow(true);
              setRefState(refProp);
            }}
            >
              Contacter
            </button>
          </div>
        </Grid>
        <div class="grid h-full lg:col-start-8 pt-[10px] lg:pt-[0px] md:pt-[0px] sm:pt-[0px] lg:col-span-4 md:col-start-8 md:col-span-4 hidden lg:block sm:block md:block">
          <Card className="shadow radius p-[20px] space-y-4 h-full">
            <Grid>
              <p className="font-[Poppins] text-[26px] font-semibold font text-left ">
                Je suis intéressé par <br /> ce bien
              </p>
            </Grid>
            {/* <Grid>
              <div className="flex items-center">
                <div>
                  <img
                    src={first}
                    alt="Avatar"
                    class="text-left h-[70px] w-[70px] rounded-full"
                  ></img>
                </div>
                <div className="pl-[10px]">
                  <p className="font-[OpenSans] text-[#9E9E9E] text-[14px] ">
                    Adriano Ricci
                  </p>
                </div>
              </div>
            </Grid> */}
            <Grid className="">
              <button
                class="mt-[10px] bg-[#003030] font-[OpenSans] text-[14px] text-white font-bold py-2 px-4 btnWidth rounded"
                onClick={() => {
                  setShow(true);
                  setRefState(refProp);
                }}
              >
                contacter
              </button>
            </Grid>
          </Card>
        </div>
      </div>
      <div class="grid lg:col-12 md:lg:col-12 xs:lg:col-12 pt-8">
        <div class="grid ">
          <Card className="shadow radius p-[20px]  ">
            <Grid>
              <div className="w-full space-y-8 lg:space-y-10 lg:pr-10 ">
                <div className="listingSection__wrap space-y-4">
                  <div>
                    <h2 className="text-[26px] font-[Poppins] font-semibold">
                      Description
                    </h2>

                    <div className="w-14 border-b-2 color"></div>
                  </div>
                  <div className=" lg:text-[16px] text-[#666666]">
                    <span>
                      {filterDataState.length > 0 &&
                        filterDataState[0].sms[0]["content"]}
                      
                    </span>
                  </div>
                  <div>
                    {/* <p className="text-[16#4px] font-[Poppins] text-[#9E9E9E] text-center">
                      Lire plus
                    </p> */}
                  </div>
                </div>
              </div>
            </Grid>
          </Card>
        </div>
      </div>

      {/* thrid Section */}

      <div class="grid lg:col-12 md:col-12 sm:col-12 xs:col-12 pt-8 lg:gap-4 md:gap-4 sm:gap-0 xs:gap-0">
        <div class="grid lg:col-start-1 lg:col-span-6 md:col-start-1 md:col-span-10 ">
          <Card className="shadow radius h-fit p-[20px]  space-y-4">
            <Grid>
              <p className="font-[Poppins] font-semibold text-[26px] text-left ">
                Bâtiment
              </p>

              <div className="w-14 border-b-2 color"></div>
            </Grid>
            <Grid>
              <div className="">
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px] ">
                    Surface habitable
                  </p>
                  {filterDataState.length > 0 && filterDataState[0].area ?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState[0].area} {" "}
                    m²
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                 
                </div>
                <hr></hr>
                {filterDataState.length > 0 && filterDataState[0].fronts && (
                  <>
                    <div className="flex items-center my-1 px-[20px] justify-between">
                      <p className="font-[OpenSans] font-bold text-[16px] ">
                        Nombre de façades
                      </p>
                      <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                        {filterDataState.length > 0 &&
                          filterDataState[0].fronts}{" "}
                        façades
                      </p>
                    </div>
                    <hr></hr>
                  </>
                )}
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Année de construction
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details, "Année de construction")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details, "Année de construction")} {" "}
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Année de rénovation
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details, "Année de renovation")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details, "Année de renovation")} {" "}
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>
                <hr></hr>

                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Type de bien
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                  {filterDataState.length > 0 &&
                          filterDataState[0].name}{" "}
                  </p>
                </div>
                <hr></hr>

                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">État</p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 &&
                      eStateIds[filterDataState[0].state["id"]].name}
                  </p>
                </div>
                <hr></hr>

                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Surface nette
                  </p>
                  {filterDataState.length > 0 && 
                    getDetailsHandler(filterDataState[0].details,"area")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getDetailsHandler(filterDataState[0].details,"area")} {" "}
                    m²
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Surface brut
                  </p>
                  {filterDataState.length > 0 && filterDataState[0].maxArea ?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState[0].maxArea} {" "}
                    m²
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                 
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Étages (position)
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 && filterDataState[0].floor}
                  </p>
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Nombre d’étages
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 &&
                      getLabelHandler(
                        filterDataState[0].details,
                        "Nombre d'étages"
                      )}
                  </p>
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Immeuble de rapport
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getDetailsHandler(
                      filterDataState.length > 0 && filterDataState[0],
                      "investmentEstate"
                    ) === true
                      ? "oui"
                      : "non"}
                  </p>
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Type de toit
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Orientation façade avant
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details, "orientation of the front")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details, "orientation of the front")} {" "}
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>
              </div>
            </Grid>
          </Card>
        </div>
        <div class="grid lg:col-start-7 lg:col-span-6 md:col-start-1 md:col-span-10 lg:mt-0 md:mt-0 sm:mt-8 mt-8">
          <Card className="shadow radius h-fit p-[20px]  space-y-4">
            <Grid>
              <p className="font-[Poppins] font-semibold text-[26px] text-left ">
                Confort
              </p>
              <div className="w-14 border-b-2 color"></div>
            </Grid>
            <Grid>
              <div className="">
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Meublé
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 &&
                    filterDataState[0].furnished === 1
                      ? "oui"
                      : "non"}
                  </p>
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Accessible aux personnes à mobilités réduites
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                  {filterDataState.length > 0 &&
                    getLabelHandler(
                      filterDataState[0].details,
                      "accès handicapés"
                    ) === "1"
                      ? "oui"
                      : "non"}
                  </p>
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Cuisine (type)
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                  {filterDataState.length > 0 &&
                    getLabelHandler(
                      filterDataState[0].details,
                      "type de cuisine"
                    )}
                  </p>
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Air conditionné
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 &&
                    getLabelHandler(
                      filterDataState[0].details,
                      "air conditioning"
                    ) === "1"
                      ? "oui"
                      : "non"}
                  </p>
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Ascenseur
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 &&
                    getLabelHandler(filterDataState[0].details, "elevator") ===
                      "1"
                      ? "oui"
                      : "non"}
                  </p>
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Châssis
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                  {filterDataState.length > 0 &&
                    getLabelHandler(
                      filterDataState[0].details,
                      "châssis"
                    )}
                  </p>
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    parlophone
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 &&
                    getLabelHandler(filterDataState[0].details, "Intercom") ===
                      "1"
                      ? "oui"
                      : "non"}
                  </p>
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Vidéophone
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 &&
                    getLabelHandler(filterDataState[0].details, "videophone") ==
                      "1"
                      ? "oui"
                      : "non"}
                  </p>
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Panneaux solaires
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 &&
                    getLabelHandler(filterDataState[0].details, "panel") === "1"
                      ? "oui"
                      : "non"}
                  </p>
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Domotique
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 &&
                    getLabelHandler(filterDataState[0].details, "Domotique") ===
                      "1"
                      ? "oui"
                      : "non"}
                  </p>
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Feu ouvert
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 &&
                    getLabelHandler(filterDataState[0].details, "ouvert") ===
                      "1"
                      ? "oui"
                      : "non"}
                  </p>
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Piscine
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 &&
                    getLabelHandler(filterDataState[0].details, "pool") === "1"
                      ? "oui"
                      : "non"}
                  </p>
                </div>
              </div>
            </Grid>
          </Card>
        </div>
        <div class="grid lg:col-start-1 lg:col-span-6 md:col-start-1 lg:mt-2 md:col-span-10 md:mt-0 sm:mt-8 mt-8">
          <Card className="shadow radius h-fit p-[20px]  space-y-4">
            <Grid>
              <p className="font-[Poppins] font-semibold text-[26px] text-left ">
                Aménagement
              </p>
              <div className="w-14 border-b-2 color"></div>
            </Grid>
            <Grid>
              <div className="">
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Salle de séjour
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details," Salle de séjour")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details," Salle de séjour")} {" "}
                    m²
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Salle à manger
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details,"Salle à manger")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details,"Salle à manger")} {" "}
                    m²
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                  
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Cuisine
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details,"Cuisine")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details,"Cuisine")} {" "}
                    m²
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Salle de bain
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 && filterDataState[0].bathRooms}
                  </p>
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Salle de douche
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details,"sdb")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details,"sdb")} {" "}
                    m²
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Surface nette
                  </p>
                  {filterDataState.length > 0 && 
                    getDetailsHandler(filterDataState[0],"area")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getDetailsHandler(filterDataState[0],"area")} {" "}
                    m²
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Nombre de toilette séparée
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 &&
                      getLabelHandler(
                        filterDataState[0].details,
                        "Nbre de toilette(s)"
                      )}
                  </p>
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Chambre 1
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details,"chambre 1")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details,"chambre 1")} {" "}
                    m²
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Chambre 2
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details,"chambre 2")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details,"chambre 2")} {" "}
                    m²
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Chambre 3
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details,"chambre 3")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details,"chambre 3")} {" "}
                    m²
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Chambre 4
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details,"chambre 4")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details,"chambre 4")} {" "}
                    m²
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Chambre 5
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details,"chambre 5")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details,"chambre 5")} {" "}
                    m²
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>
              </div>
            </Grid>
          </Card>
        </div>
        <div class="grid lg:col-start-7 lg:col-span-6 md:col-start-1 lg:mt-2 md:col-span-10  md:mt-0 sm:mt-8 mt-8">
          <Card className="shadow radius h-fit p-[20px]  space-y-4">
            <Grid>
              <p className="font-[Poppins] font-semibold text-[26px] text-left">
                Finances
              </p>
              <div className="w-14 border-b-2 color"></div>
            </Grid>
            <Grid>
              <div>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">Prix</p>
                  {filterDataState.length > 0 && filterDataState[0].price?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    €{" "}{filterDataState[0]?.price
                    ?.toString()
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Disponibilité
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 &&
                      estateAvailabilty[filterDataState[0]?.availability?.id]
                        ?.name}
                  </p>
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Dans le système de tva
                  </p>
                  {filterDataState.length > 0 && getLabelHandler(
                      filterDataState[0].details,
                      "Précompte immobilier"
                    )?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    €{" "}{filterDataState.length > 0 &&
                    getLabelHandler(
                      filterDataState[0].details,
                      "Précompte immobilier"
                    )?.toString()
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")},00
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Précompte immobilier
                  </p>
                  {filterDataState.length > 0 && getLabelHandler(
                      filterDataState[0].details,
                      "land tax"
                    )?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    €{" "}{filterDataState.length > 0 &&
                    getLabelHandler(
                      filterDataState[0].details,
                      "land tax"
                    )?.toString()
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")},00
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Revenu cadastral
                  </p>
                  {filterDataState.length > 0 && getLabelHandler(
                      filterDataState[0].details,
                      "Revenu cadastral (€)"
                    )?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    €{" "}{filterDataState.length > 0 &&
                    getLabelHandler(
                      filterDataState[0].details,
                      "Revenu cadastral (€)"
                    )?.toString()
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")},00
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                  
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Charges occupant
                  </p>
                  {filterDataState.length > 0 && getLabelHandler(
                      filterDataState[0].details,
                      "charges locataire"
                    )?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    €{" "}{filterDataState.length > 0 &&
                    getLabelHandler(
                      filterDataState[0].details,
                      "charges locataire"
                    )?.toString()
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")},00
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Charges propriétaire
                  </p>
                  {filterDataState.length > 0 && getLabelHandler(
                      filterDataState[0].details,
                      "charges tenant"
                    )?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    €{" "}{filterDataState.length > 0 &&
                    getLabelHandler(
                      filterDataState[0].details,
                      "charges tenant"
                    )?.toString()
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")},00
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Part dans les parties commune (/10.000)
                  </p>
                  {filterDataState.length > 0 && getLabelHandler(
                      filterDataState[0].details,
                      "commune"
                    )?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    €{" "}{filterDataState.length > 0 &&
                    getLabelHandler(
                      filterDataState[0].details,
                      "commune"
                    )?.toString()
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")},00
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Bien d'investissement
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 &&
                    getLabelHandler(
                      filterDataState[0].details,
                      "investmentEstate"
                    ) == true
                      ? "oui"
                      : "non"}
                  </p>
                </div>
              </div>
            </Grid>
          </Card>
        </div>
        <div class="grid lg:col-start-1 lg:col-span-6 md:col-start-1 lg:mt-[12px] md:col-span-10 lg:mt-4 md:mt-0 sm:mt-8 mt-8">
          <Card className="shadow radius h-fit p-[20px]  space-y-4">
            <Grid>
              <p className="font-[Poppins] font-semibold text-[26px] text-left">
                Garages / Parking
              </p>
              <div className="w-14 border-b-2 color"></div>
            </Grid>
            <Grid>
              <div className="">
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Nombre de parking
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 && filterDataState[0].parking }
                  </p>
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Nombre de garage
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 && filterDataState[0].garage }
                  </p>
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Parking intérieur
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 &&
                    getLabelHandler(
                      filterDataState[0].details,
                      "parking intérieur"
                    ) == "1"
                      ? "oui"
                      : "non"}
                  </p>
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Parking extérieur
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 &&
                    getLabelHandler(
                      filterDataState[0].details,
                      "parking extérieur"
                    ) == "1"
                      ? "oui"
                      : "non"}
                  </p>
                </div>
              </div>
            </Grid>
          </Card>
        </div>
        <div class="grid lg:col-start-7 lg:col-span-6 md:col-start-1 md:col-span-10 lg:-mt-[90px] md:mt-0 sm:mt-8 mt-8">
          <Card className="shadow radius h-fit p-[20px]  space-y-4">
            <Grid>
              <p className="font-[Poppins] font-semibold text-[26px] text-left">
                Terrain escape extérieur
              </p>
              <div className="w-14 border-b-2 color"></div>
            </Grid>
            <Grid>
              <div className=" ">
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Surface bâtie
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details,"built surface")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details,"built surface")} {" "}
                    m²
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                 
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Largeur de façade
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details,"width of front width")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details,"width of front width")} {" "}
                    m²
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                  
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Balcon(s)
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">Cour</p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details,"cour")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details,"cour")} {" "}
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Terrasse(s)
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details,"Nbre de terrasse(s)")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details,"Nbre de terrasse(s)")} {" "}
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                  
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Orientation terrasse
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details, "orientation de la terrasse 1")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details, "orientation de la terrasse 1")} {" "}
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                  
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Jardin
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 &&
                    getDetailsHandler(filterDataState[0], "garden") == "1"
                      ? "oui"
                      : "non"}
                  </p>
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Orientation du Jardin
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                </div>
              </div>
            </Grid>
          </Card>
        </div>
        <div class="grid lg:col-start-1 lg:col-span-6 md:col-start-1 lg:-mt-4  md:col-span-10 lg:-mt-2 md:mt-0 sm:mt-8 mt-8">
          <Card className="shadow radius h-fit p-[20px]  space-y-4">
            <Grid>
              <p className="font-[Poppins] font-semibold text-[26px] text-left">
                Urbanisme
              </p>
              <div className="w-14 border-b-2 color"></div>
            </Grid>
            <Grid>
              <div className="">
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Permis de construire
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details,"building permission")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details,"building permission")} {" "}
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                  
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Type d’environnement
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details,"Type d'environnement")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details,"Type d'environnement")} {" "}
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                  
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Type d’environnement
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details,"Type d'environnement 2")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details,"Type d'environnement 2")} {" "}
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                 
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Risque d’inondation
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details,"risque d'inondation")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details,"risque d'inondation")} {" "}
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Type de zone inondable
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details,"risque d'inondation")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details,"risque d'inondation")} {" "}
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                 
                </div>
              </div>
            </Grid>
          </Card>
        </div>
        <div class="grid lg:col-start-7 lg:col-span-6 md:col-start-1 lg:mt-[12px] md:col-span-10 lg:mt-2 md:mt-0 sm:mt-8 mt-8">
          <Card className="shadow radius h-fit p-[20px]  space-y-4">
            <Grid>
              <p className="font-[Poppins] font-semibold text-[26px] text-left">
                Technique
              </p>
              <div className="w-14 border-b-2 color"></div>
            </Grid>
            <Grid>
              <div className="">
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Type de zone inondable
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details,"risque d'inondation")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details,"risque d'inondation")} {" "}
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>

                <hr></hr>

                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Chauffage (type - Ind/coll)
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details,"chauffage (ind/coll)")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details,"chauffage (ind/coll)")} {" "}
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                 
                </div>

                <hr></hr>

                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Chauffage
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details,"chauffage")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details,"chauffage")} {" "}
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>

                <hr></hr>

                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Raccordement électrique
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 &&
                    getLabelHandler(
                      filterDataState[0].details,
                      "type of heating"
                    ) == "1"
                      ? "oui"
                      : "non"}
                  </p>
                </div>

                <hr></hr>

                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Raccordement gaz
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 &&
                    getLabelHandler(filterDataState[0].details, "gas") == "1"
                      ? "oui"
                      : "non"}
                  </p>
                </div>

                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Raccordement eau
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 &&
                    getLabelHandler(filterDataState[0].details, "water") == "1"
                      ? "oui"
                      : "non"}
                  </p>
                </div>

                <hr></hr>

                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Raccordement égouts
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 &&
                    getLabelHandler(filterDataState[0].details, "sewage") == "1"
                      ? "oui"
                      : "non"}
                  </p>
                </div>
              </div>
            </Grid>
          </Card>
        </div>
        <div class="grid lg:col-start-1 lg:col-span-6 md:col-start-1 lg:-mt-[80px] md:col-span-10 lg:-mt-16 mb-4 mb-0 md:mt-4 mb-8 sm:mt-8 mb-8 mt-8">
          <Card className="shadow radius h-fit p-[20px]  space-y-4">
            <Grid>
              <p className="font-[Poppins] font-semibold text-[26px] text-left">
                Energie
              </p>
              <div className="w-14 border-b-2 color"></div>
            </Grid>
            <Grid>
              <div className="">
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Label PEB
                  </p>
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {filterDataState.length > 0 &&
                      getLabelHandler(
                        filterDataState[0].details,
                        "label PEB"
                      )}
                  </p>
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    PEB (E-SPEC) (kwh/m²/an)
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details,"PEB (E-SPEC)")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details,"PEB (E-SPEC)")} {" "}
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Emission CO2
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details,"Emission CO2")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details,"Emission CO2")} {" "}
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    PEB code unique
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details,"En. cert. unique code")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details,"En. cert. unique code")} {" "}
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                </div>
                <hr></hr>
                <div className="flex items-center my-1 px-[20px] justify-between">
                  <p className="font-[OpenSans] font-bold text-[16px]">
                    Contrôle électrique
                  </p>
                  {filterDataState.length > 0 && 
                    getLabelHandler(filterDataState[0].details,"certificat d'électricité")?
                    (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    {getLabelHandler(filterDataState[0].details,"certificat d'électricité")} {" "}
                  </p>
                  ):
                  (
                  <p className="font-[OpenSans] text-[16px] font-normal text-[#666666] text-right">
                    -
                  </p>
                  )}
                  
                </div>
              </div>
            </Grid>
          </Card>
        </div>
      </div>
    </Container>
  );
}
