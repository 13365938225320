import React from "react";
import { Container, Grid } from "@mui/material";
// import "./access.scss";

const LicenceSection = () => {
  return (
    <Container>
      <Grid container justifyContent="center" className="pt-[40px]">
        <Grid container xs={12} lg={10} sm={12}>
          <h1 className="font-[Poppins] font-semibold text-[18px] lg:text-[26px] xs:text-[26px] pb-[10px]">
            Licence{" "}
          </h1>
          <Grid className="text-[#666666] text-[14px] font-[OpenSans] space-y-4">
            <p>
              L’utilisateur reçoit uniquement le droit de consulter le Site Web
              et son contenu à titre personnel. A ce titre, l’utilisateur
              bénéficie d’une licence personnelle d’utilisation du Site Web et
              de son contenu, incessible, non transférable, et exclusivement
              limitée à une utilisation à des fins personnelles. La durée de la
              licence d’utilisation est limitée à la durée de l’accès de
              l’utilisateur au Site Web.
            </p>
            <p>
              Toute utilisation commerciale du Site Web est strictement
              interdite. Le terme « utilisation commerciale » fait référence,
              sans que cette énumération ne soit limitative, à toute vente ou
              toute location des diverses fonctionnalités du Site Web, des
              enregistrements de tout ou partie du contenu disponible sur le
              Site Web, ou à toute utilisation du Site Web et de ses composants
              avec pour but unique de générer des revenus.
            </p>
            <p>
              Il est en outre strictement interdit à l’utilisateur, qui ne peut
              par ailleurs accorder l’autorisation à autrui, de :
            </p>
            <ul className="list-style-for-term">
              <li>
                modifier, reproduire, copier, emprunter, distribuer tout ou
                partie du Site Web ou de son contenu ;
              </li>
              <li>
                créer des œuvres dérivées basées en tout ou en partie sur les
                éléments présents sur le Site Web ;
              </li>
              <li>
                inverser la conception ou l’assemblage ou de toute autre manière
                tenter de trouver le code source de tout ou partie du Site Web ;
              </li>
              <li>
                créer un lien hypertexte vers ou depuis le Site Web, sans
                l’accord préalable et expresse du prestataire ;
              </li>
              <li>
                sous-licencier ou transférer de quelque manière que ce soit tout
                droit afférent au Site Web et/ou à son contenu, y compris mais
                sans s’y limiter, tout droit relatif au logiciel.
              </li>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LicenceSection;
